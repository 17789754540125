import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { AvianForm } from '../../components/forms/AvianForm';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureMain } from '../../components/PictureMain';
import { Seo } from '../../components/seo';
import { Title } from '../../components/Title';

const AvianQuestionnaire: Page = () => (
    <DefaultLayout path="new-clients/" prevName="New Clients" name="Avian Questionnaire">
        <Seo
            title="Avian Questionnaire Country Club Animal Clinic"
            description="Avian Questionnaire Country Club Animal Clinic"
        />
        <Title>
            <h1>
                <span className="orange">Avian Questionnaire </span>At Country Club Animal Clinic
            </h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../../images/avian.jpeg"
                alt="green hummingbird"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../../images/avian.jpeg"
                alt="green hummingbird"
            />
        </PictureMain>
        <AvianForm />
    </DefaultLayout>
);

export default AvianQuestionnaire;
