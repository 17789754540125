import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Title } from '../components/Title';
import { PictureMain } from '../components/PictureMain';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUs } from '../components/ContactUs';
import { Seo } from '../components/seo';

const ContactUsPage: Page = () => (
    <DefaultLayout contactPage name="Contact Us">
        <Seo
            title="Contact Country Club Animal Clinic"
            description="contact country club animal clinic in El Paso Texas"
        />
        <Title>
            <h1>Contact Us</h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../images/candy-dog.jpeg"
                alt="blurred out christmas themed pug"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../images/candy-dog.jpeg"
                alt="christmas themed pug"
            />
        </PictureMain>
        <ContactUs page />
    </DefaultLayout>
);

export default ContactUsPage;
