import * as React from 'react';
import { useState } from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Title } from '../../components/Title';
import { Carousel } from '../../components/Carousel';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../../components/seo';

const VirtualTour: Page = () => {
    const [index, setIndex] = useState(0);

    const onSelect = (i: React.SetStateAction<number>) => {
        setIndex(i);
    };

    const onRightArrowPush = () => {
        if (index <= 8) {
            setIndex(index => index + 1);
        } else {
            return;
        }
    };

    const onLeftArrowPush = () => {
        if (index >= 1) {
            setIndex(index => index - 1);
        } else {
            return;
        }
    };
    return (
        <DefaultLayout path="about-us" prevName="About Us" name="Virtual Tour">
            <Seo
                title="Country Club Animal Clinic Virtual Tour in El Paso Texas"
                description="Country club animal clinic virtual tour at your local El Paso Texas facility"
            />
            <Title>
                <h1>
                    Country Club Aninal Clinic <span>Virtual Tour</span>
                </h1>
            </Title>
            <Carousel>
                <div className="selection-display">
                    {index >= 1 ? (
                        <StaticImage
                            onClick={() => onLeftArrowPush()}
                            className="arrow"
                            alt="light grey arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/left-dark-arrow.svg"
                        />
                    ) : (
                        <StaticImage
                            onClick={() => onLeftArrowPush()}
                            className="no-cursor arrow"
                            alt="light grey arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/left-light-arrow.svg"
                        />
                    )}
                    <div className="image-container">
                        {index === 0 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour1.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour1.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 1 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour2.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour2.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 2 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour3.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour3.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 3 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour4.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour4.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 4 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour5.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour5.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 5 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour6.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour6.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 6 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour7.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour7.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 7 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour8.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour8.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 8 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour9.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour9.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 9 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour10.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/virtual-tour/virtual-tour10.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}
                    </div>
                    {index >= 9 ? (
                        <StaticImage
                            onClick={() => onRightArrowPush()}
                            alt="dark grey arrow"
                            className="arrow no-cursor"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/right-arrow-light.svg"
                        />
                    ) : (
                        <StaticImage
                            onClick={() => onRightArrowPush()}
                            alt="dark grey arrow"
                            className="arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/right-dark-arrow.svg"
                        />
                    )}
                </div>
                <div className="pictures-container">
                    <div className="scroll">
                        <StaticImage
                            onClick={() => onSelect(0)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour1.jpeg"
                            alt="front of veterinary building"
                        />
                        <StaticImage
                            onClick={() => onSelect(1)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour2.jpeg"
                            alt="inside of building"
                        />
                        <StaticImage
                            onClick={() => onSelect(2)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour3.jpeg"
                            alt="around corner from main room"
                        />
                        <StaticImage
                            onClick={() => onSelect(3)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour4.jpeg"
                            alt="pink and blue animal patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(4)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour5.jpeg"
                            alt="medical room"
                        />
                        <StaticImage
                            onClick={() => onSelect(5)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour6.jpeg"
                            alt="patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(6)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour7.jpeg"
                            alt="cages"
                        />
                        <StaticImage
                            onClick={() => onSelect(7)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour8.jpeg"
                            alt="medical research room"
                        />
                        <StaticImage
                            onClick={() => onSelect(8)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour9.jpeg"
                            alt="medical bed"
                        />
                        <StaticImage
                            onClick={() => onSelect(9)}
                            placeholder="none"
                            quality={100}
                            src="../../images/virtual-tour/virtual-tour10.jpeg"
                            alt="medical room"
                        />
                    </div>
                </div>
            </Carousel>
            <h3 style={{ margin: '1em 0em', textAlign: 'center' }}>Spa & Resort</h3>
            <Carousel>
                <div className="selection-display">
                    {index >= 1 ? (
                        <StaticImage
                            onClick={() => onLeftArrowPush()}
                            className="arrow"
                            alt="light grey arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/left-dark-arrow.svg"
                        />
                    ) : (
                        <StaticImage
                            onClick={() => onLeftArrowPush()}
                            className="no-cursor arrow"
                            alt="light grey arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/left-light-arrow.svg"
                        />
                    )}
                    <div className="image-container">
                        {index === 0 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us1.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us1.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 1 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us2.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us2.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 2 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us3.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us3.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 3 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us4.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us4.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 4 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us5.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us5.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 5 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us6.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us6.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 6 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us7.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us7.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 7 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us8.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us8.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 8 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us9.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us9.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 9 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../../images/about-us/about-us10.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../../images/about-us/about-us10.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}
                    </div>
                    {index >= 9 ? (
                        <StaticImage
                            onClick={() => onRightArrowPush()}
                            alt="dark grey arrow"
                            className="arrow no-cursor"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/right-arrow-light.svg"
                        />
                    ) : (
                        <StaticImage
                            onClick={() => onRightArrowPush()}
                            alt="dark grey arrow"
                            className="arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../../images/svgs/right-dark-arrow.svg"
                        />
                    )}
                </div>
                <div className="pictures-container">
                    <div className="scroll">
                        <StaticImage
                            onClick={() => onSelect(0)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us1.jpeg"
                            alt="front of veterinary building"
                        />
                        <StaticImage
                            onClick={() => onSelect(1)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us2.jpeg"
                            alt="inside of building"
                        />
                        <StaticImage
                            onClick={() => onSelect(2)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us3.jpeg"
                            alt="around corner from main room"
                        />
                        <StaticImage
                            onClick={() => onSelect(3)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us4.jpeg"
                            alt="pink and blue animal patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(4)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us5.jpeg"
                            alt="medical room"
                        />
                        <StaticImage
                            onClick={() => onSelect(5)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us6.jpeg"
                            alt="patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(6)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us7.jpeg"
                            alt="cages"
                        />
                        <StaticImage
                            onClick={() => onSelect(7)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us8.jpeg"
                            alt="medical research room"
                        />
                        <StaticImage
                            onClick={() => onSelect(8)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us9.jpeg"
                            alt="medical bed"
                        />
                        <StaticImage
                            onClick={() => onSelect(9)}
                            placeholder="none"
                            quality={100}
                            src="../../images/about-us/about-us10.jpeg"
                            alt="medical room"
                        />
                    </div>
                </div>
            </Carousel>
        </DefaultLayout>
    );
};

export default VirtualTour;
