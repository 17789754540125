import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHomePanel = styled.div`
    padding: 4em 1em;
    h2 {
        margin-top: 1em;
        color: ${colors.orange};
    }
    p {
        margin: 1em 0em 3em;
    }
    span {
        color: ${colors.tealDark};
    }
    ul {
        margin-left: 1.5em;
        li {
            margin: 0.5em 0em;
        }
    }
    .image-container {
        position: relative;
        margin-top: 1em;
        img,
        .overlay {
            height: 200px;
            width: 90vw;

            border-radius: 10px;
        }
        .overlay {
            position: absolute;
            top: 0.75em;
            z-index: 0;
            left: 0.75em;
            background: ${colors.tealLight};
        }
        img {
            z-index: 1;
        }
    }
    .card-one {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card-two {
        display: flex;
        flex-direction: column;
        align-items: center;
        img,
        .overlay {
            width: 80vw;
            height: 375px;
            margin: 0 auto;
        }
    }
    @media ${device.tablet} {
        max-width: 720px;
        margin: 0 auto;
    }
    @media ${device.laptop} {
        padding-bottom: 8em;
        max-width: 1220px;
    }
    @media ${device.laptopL} {
        max-width: 1280px;
        .card {
            .text {
                max-width: 500px;
            }
        }
        .card-one {
            flex-direction: row;
            justify-content: space-around;
            .image-container {
                position: relative;
                margin-top: 1em;
                img,
                .overlay {
                    height: 420px;
                    width: 640px;
                    border-radius: 10px;
                }
            }
        }
        .card-two {
            margin-top: 4em;
            flex-direction: row-reverse;
            justify-content: space-around;
            .image-container {
                position: relative;
                margin-top: 1em;
                img,
                .overlay {
                    height: 620px;
                    width: 520px;
                    border-radius: 10px;
                }
            }
        }
    }
    @media ${device.desktop} {
        max-width: 1380px;
    }
    @media ${device.desktopL} {
        max-width: 1580px;
    }
`;

export const HomePanel: React.FC = () => {
    return (
        <StyledHomePanel>
            <div className="card card-one">
                <div className="image-container">
                    <StaticImage src="../images/dog-operation.jpeg" alt="dog getting an exam" />
                    <div className="overlay"></div>
                </div>
                <div className="text">
                    <h2>
                        <span>About</span> Country Club Animal Clinic
                    </h2>
                    <p>
                        Since we opened in 1995, we have always taken pride in providing a safe,
                        friendly environment for our employees to work in and provide modern
                        equipment, as well as opportunities to learn as much as possible regarding
                        veterinary medicine.
                    </p>
                </div>
            </div>
            <div className="card card-two">
                <div className="image-container">
                    <StaticImage src="../images/dog-temp.jpeg" alt="dog getting an exam" />
                    <div className="overlay"></div>
                </div>
                <div className="text">
                    <h2>
                        <span>“Why Choose</span> Country Club Animal Clinic <span>?”</span>
                    </h2>
                    <ul>
                        <li>Voted Next Door Favorite in 2018, 2019, 2020 and 2021</li>
                        <li>Received ‘Best of Best Award’ in 2014 and 2015</li>
                        <li>El Paso Times ‘Best of the Border’ in 2015</li>
                        <li>In Business 27 years</li>
                        <li>Veterinarian for El Paso Police Department for over 20 years</li>
                        <li>Veterinarian for Almost Home Dog Rescue</li>
                        <li>We are a ‘Fear Free Clinic’</li>
                        <li>We see exotics</li>
                        <li>We do Saturday Exams</li>
                        <li>We do Dentals for Stages 1-4</li>
                        <li>Offer Ultrasounds, focusing in abdominal and cardiac</li>
                        <li>Offer In House Lab Testing</li>
                        <li>Strong Team</li>
                        <li>
                            Intimate Setting and Every Client is treated like family by Dr. Harvey &
                            Staff, just as if every pet was their own
                        </li>
                        <li>We offer Grooming, Boarding, and Doggy Daycare</li>
                    </ul>
                </div>
            </div>
        </StyledHomePanel>
    );
};
