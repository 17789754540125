import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHomeHighlights = styled.div`
    background: ${colors.tealLight};
    color: ${colors.white};
    padding: 2em 1em;
    position: relative;
    height: 165vh;
    .doctor {
        position: absolute;
        bottom: 0em;
    }
    ul {
        list-style: none;
        li {
            margin: 2em 0em;
        }
    }
    .title {
        margin: 1em 0em;
        text-align: center;
    }
    .doctor-two {
        display: none;
    }
    @media ${device.tablet} {
        height: 500px;
        .lists {
            display: flex;
            width: 700px;
            justify-content: space-between;
            margin: 0 auto;
            li {
                margin: 3em 0em;
            }
        }
        .title {
            display: flex;
            width: 600px;
            justify-content: space-between;
            margin: 0 auto;
        }
        .left-list {
            text-align: left;
        }
        .right-list {
            display: none;
            text-align: right;
        }
        h2:nth-child(2) {
            display: none;
        }
        .doctor {
            display: none;
        }
        .doctor-two {
            display: block;
            position: relative;
            bottom: 10.8em;
            margin: 0 auto;
            max-width: 480px;
        }
    }
    @media ${device.laptopL} {
        height: 540px;
        .lists {
            display: flex;
            width: 1200px;
            justify-content: space-between;
            margin: 0 auto;
        }
        h2:nth-child(2) {
            display: block;
        }
        .title {
            display: flex;
            width: 800px;
            justify-content: space-between;
            margin: 0 auto;
        }
        .left-list {
            text-align: left;
            li {
                margin: 4em 0em;
            }
        }
        .right-list {
            display: block;
            text-align: right;
            li {
                margin: 5em 0em;
            }
        }
        .doctor-two {
            min-width: 540px;
            bottom: 13.4em;
        }
        .doctor {
            margin: 0 auto;
        }
    }
`;

export const HomeHighlights: React.FC = () => {
    return (
        <StyledHomeHighlights>
            <div className="title">
                <h2>Dr. Harvey</h2>
                <h2>Highlights</h2>
            </div>
            <div className="lists">
                <div className="left-list">
                    <ul>
                        <li>Accredited USDA Veterinarian for health certificates</li>
                        <li>Licensed in the state of TX and NM</li>
                        <li>Association of Avian Vets</li>
                        <li>Donor and Active with Lee & Beulah Moore Home</li>
                    </ul>
                </div>
                <StaticImage
                    className="doctor-two"
                    placeholder="blurred"
                    src="../images/doctor.png"
                    alt="doctor on green outfit"
                />
                <div className="right-list">
                    <ul>
                        <li>Worked at El Paso Emergency Center for 12.5 years</li>
                        <li>El Paso Women’s Coalition</li>
                        <li>Was the Vet for El Paso Zoo from 1989 - 1992</li>
                    </ul>
                </div>
            </div>
            <StaticImage
                className="doctor"
                placeholder="blurred"
                src="../images/doctor.png"
                alt="doctor on green outfit"
            />
        </StyledHomeHighlights>
    );
};
