import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../layout/GlobalStyles';
import { Button } from '../layout/StyledComponents';

const StyledForm = styled.div`
    padding: 1em 1em 0em;
    fieldset {
        border: none;
        display: flex;
        padding: 0;
    }
    .desktop-flex {
        display: block;
    }

    .mid {
        width: 197px;
    }
    .checker {
        margin: 0.35em 0em;
        width: 20px;
        color: ${colors.black};
        height: 20px;
        border: none;
    }

    form {
        color: ${colors.black};
        display: flex;
        flex-direction: column;
        button {
            margin: 2em auto 4em;
            width: 90vw;
        }
    }

    .small {
        width: 107px;
    }
    h3 {
        padding-bottom: 1em;
        border-bottom: 1.5px solid #dee4e4;
        margin: 2.5em 0em 1em;
    }
    input {
        color: ${colors.black};
        text-indent: 0.35em;
        background: #ffffff;
        border: 1.5px solid #dee4e4;
        box-sizing: border-box;
        padding: 0.75em;
        border-radius: 5px;
    }

    select {
        background: linear-gradient(45deg, transparent 50%, ${colors.black} 50%),
            linear-gradient(135deg, ${colors.black} 50%, white 50%),
            linear-gradient(to right, white, white);
        background-position: calc(100% - 16px) calc(1em + 2px), calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        text-indent: 0.25em;
        padding: 0.75em;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
    }
    textarea {
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
        box-sizing: border-box;
    }
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    p,
    label {
        font-weight: 500;
    }
    h3 {
        font-size: 24px;
        color: ${colors.tealLight};
    }
    .section {
        margin: 0.75em 0em;
    }
    .item {
        align-items: center;
        display: flex;
        margin-right: 1em;
        label {
            padding-left: 0.3em;
        }
        input:nth-of-type(2) {
            margin-left: 1em;
        }
    }
    .row {
        display: flex;
    }
    textarea {
        min-height: 150px;
    }
    .section {
        display: flex;
        flex-direction: column;
        input,
        select,
        textarea {
            max-width: 500px;
        }
    }
    @media ${device.tablet} {
        .pet {
            min-width: 350px;
            display: flex;
            justify-content: space-between;
        }
        .full {
            min-width: 550px;
        }
        .bubbles {
            display: flex;
            min-width: 450px;
            justify-content: space-between;
        }
    }

    @media ${device.laptop} {
        .bubbles {
            min-width: 550px;
        }
        .full {
            min-width: 850px;
        }
        .payments {
            display: flex;
        }
        .lists {
            justify-content: space-between;
            width: 1000px;
            display: flex;
        }
        button {
            max-width: 415px;
        }
        .third {
            width: 360px;
        }
        .second {
            min-width: 540px;
        }
        .small {
            width: 147px;
        }
        .city {
            margin-right: 4em;
        }
        .mid {
            min-width: 156px;
        }
        .desktop-flex {
            display: flex;
            justify-content: space-between;
        }
    }
`;

interface Props {
    reptile?: boolean;
}

export const ReptileMammalForm: React.FC<Props> = ({ reptile }) => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledForm className="container">
            <form onSubmit={onSubmit}>
                <h3>Please enter your pet’s information</h3>
                <div className="section">
                    <p>
                        Owner's Name <span style={{ color: '#D63434' }}>*</span>{' '}
                    </p>
                    <input className="second" required id="name" type="text" name="name" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Name of Pet <span style={{ color: '#D63434' }}>*</span>{' '}
                        </p>
                        <input
                            className="second"
                            id="petname"
                            name="petname"
                            type="text"
                            required
                        />
                    </div>
                    <div className="section">
                        <p>
                            Species <span style={{ color: '#D63434' }}>*</span>
                        </p>
                        <input
                            className="second"
                            id="Species"
                            name="Species"
                            type="text"
                            required
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Age</p>
                        <input style={{ width: '360px' }} id="age" name="age" type="text" />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',

                            justifyContent: 'space-between',
                        }}
                    >
                        <p>
                            Sex <span style={{ marginRight: '1em', color: '#D63434' }}>*</span>
                        </p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input type="radio" id="male" name="sex" value="male" />
                                    <label htmlFor="male">Male</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="neuter" name="sex" value="female" />
                                    <label htmlFor="neuter">Female</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="neuter" name="sex" value="neutered" />
                                    <label htmlFor="neuter">Unknown</label>
                                    <input type="text" id="neuter" name="sex" placeholder="" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Where did you get your pet from?</p>
                        <div className="bubbles full">
                            <fieldset>
                                <div className="item">
                                    <input type="radio" id="store" name="wherePetFrom" />
                                    <label htmlFor="store">Store</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="private" name="wherePetFrom" />
                                    <label htmlFor="private">Private Individual</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="othersource" name="wherePetFrom" />
                                    <label htmlFor="othersource">Other</label>
                                    <input
                                        type="text"
                                        id="othersourcetext"
                                        name="othersourcetext"
                                        placeholder=""
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>
                        When did you get your pet?{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>(mm/dd/yyyy)</span>
                    </p>
                    <input className="small" type="date" name="petDob" />
                </div>
                <div className="section">
                    <p>
                        Is your pet kept in a cage, aquarium, or free in the house?
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Please describe)
                        </span>
                    </p>
                    <input className="second" id="petDob" type="text" name="petDob" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Is it inside/outside the house?</p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input type="radio" id="inside" name="insideOusideHouse" />
                                    <label htmlFor="inside">Inside</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="outside" name="insideOusideHouse" />
                                    <label htmlFor="outside">Outside</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="othersource" name="insideOusideHouse" />
                                    <label htmlFor="othersource">Other</label>
                                    <input
                                        type="text"
                                        id="othersourcetext"
                                        name="othersourcetext"
                                        placeholder=""
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>Is your pet with other pets, if so how many and what kind</p>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Number Of Pets</p>
                        <input className="second" id="petNumbers" name="petNumbers" type="text" />
                    </div>
                    <div className="section">
                        <p>Kinds of Pets</p>
                        <input className="second" id="petKinds" name="petKinds" type="text" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Are there any sick pets?</p>
                        <input className="second" id="sickbirds" name="sickbirds" type="text" />
                    </div>
                    <div className="section">
                        <p>Have any pets already died?</p>
                        <input className="second" id="deadbirds" name="deadbirds" type="email" />
                    </div>
                </div>
                <div className="section">
                    <p>
                        What signs have you noticed regarding this pet, this incident?{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Check all that apply)
                        </span>
                    </p>
                    <div className="lists">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="diarrhea"
                                    name="diarrhea"
                                    value="diarrhea"
                                />
                                <label htmlFor="diarrhea">Diarrhea</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="constipation"
                                    name="constipation"
                                    value="constipation"
                                />
                                <label htmlFor="constipation">Constipation</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="fainting"
                                    name="fainting"
                                    value="fainting"
                                />
                                <label htmlFor="fainting">Fainting</label>
                            </div>

                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="lameness"
                                    name="lameness"
                                    value="lameness"
                                />
                                <label htmlFor="lameness">Lameness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="itching"
                                    name="itching"
                                    value="itching"
                                />
                                <label htmlFor="itching">Itching</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="tailBobbing"
                                    name="tailBobbing"
                                    value="tailBobbing"
                                />
                                <label htmlFor="tailBobbing">Tail Bobbing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="fluffedFeathers"
                                    name="fluffedFeathers"
                                    value="fluffedFeathers"
                                />
                                <label htmlFor="fluffedFeathers">Fluffed Feathers</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="coughing"
                                    name="coughing"
                                    value="coughing"
                                />
                                <label htmlFor="coughing">Coughing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="otherSymptoms"
                                    name="otherSymptoms"
                                    value="otherSymptoms"
                                />
                                <label htmlFor="otherSymptoms">other</label>
                                <input name="otherSymptoms" type="text" placeholder="none" />
                            </div>
                        </div>{' '}
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="romaine"
                                    name="romaine"
                                    value="romaine"
                                />
                                <label htmlFor="blindness">Blindness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="breathing"
                                    name="breathing"
                                    value="breathing"
                                />
                                <label htmlFor="breathing">Breathing Difficulty</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="skinBleeding"
                                    name="skinBleeding"
                                    value="skinBleeding"
                                />
                                <label htmlFor="skinBleeding">Skin Bleeding</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="vomitting"
                                    name="vomitting"
                                    value="vomitting"
                                />
                                <label htmlFor="vomitting">Vomiting</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="perching"
                                    name="perching"
                                    value="perching"
                                />
                                <label htmlFor="perching">Perching Difficulty</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="featherloss"
                                    name="featherloss"
                                    value="featherloss"
                                />
                                <label htmlFor="featherloss">Feather Picking or Feather Loss</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="hoarseness"
                                    name="hoarseness"
                                    value="hoarseness"
                                />
                                <label htmlFor="hoarseness">Hoarseness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="personalityChange"
                                    name="personalityChange"
                                    value="personalityChange"
                                />
                                <label htmlFor="personalityChange">Change in Personality</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="limbBleeding"
                                    name="limbBleeding"
                                    value="limbBleeding"
                                />
                                <label htmlFor="limbBleeding">Eye/Nostril/Ear/Leg bleeding</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="bite"
                                    name="bite"
                                    value="bite"
                                />
                                <label htmlFor="weakness">
                                    Injury/Bitten by another Bird or Pet
                                </label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="breathing"
                                    name="breathing"
                                    value="breathing problems"
                                />
                                <label htmlFor="breathing">Breathing Problems</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="drooping"
                                    name="drooping"
                                    value="drooping"
                                />
                                <label htmlFor="drooping">Drooping or injured Wings or Legs</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="stool"
                                    name="stool"
                                    value="stool"
                                />
                                <label htmlFor="stool">Change in stool Consistency</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="appetite"
                                    name="appetite"
                                    value="appetite"
                                />
                                <label htmlFor="stool">Change in Appetite</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="waterconsumption"
                                    name="waterconsumption"
                                    value="waterconsumption"
                                />
                                <label htmlFor="waterconsumption">
                                    Excessive Water Consumption
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>Has your pet received any treatment for this problem?</p>
                    <input id="previousTreatment" type="text" name="previousTreatment" />
                </div>
                {reptile && (
                    <>
                        <div className="section">
                            <p>Are you using a thermometer and a humidity gauge?</p>
                            <input id="thermostat" type="text" name="thermostat" />
                        </div>
                        <div className="section">
                            <p>
                                What's the average temperature of your cage/aquarium during the day?
                            </p>
                            <input id="averageTempDay" type="text" name="averageTempDay" />
                        </div>
                        <div className="section">
                            <p>{`What's the average temperature of your cage/aquarium at night?`}</p>
                            <input
                                id="AverageTempCageNight"
                                type="text"
                                name="AverageTempCageNight"
                            />
                        </div>
                        <div className="section">
                            <p>{`What's the average temperature of your cage/aquarium in the basking area? `}</p>
                            <input id="baskingAreaTemp" type="text" name="baskingAreaTemp" />
                        </div>
                        <div className="section">
                            <p>Do you have a UVB light?</p>
                            <input className="second" id="uvbLight" type="text" name="uvbLight" />
                        </div>
                        <div className="section">
                            <p>
                                Do you have a way to keep your pet warm at night?{' '}
                                <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                                    (Please describe)
                                </span>
                            </p>
                            <input
                                className="second"
                                id="warmOutside"
                                type="text"
                                name="warmOutside"
                            />
                        </div>
                    </>
                )}
                <div className="section">
                    <p>Does your pet ever go outside?</p>
                    <input id="petGoOutside" type="text" name="petGoOutside" />
                </div>
                <div className="section">
                    <p>How often do you change out the paper or litter of the cage?</p>
                    <input id="litterChange" type="text" name="litterChange" />
                </div>
                <div className="section">
                    <p>
                        {`How often do you thoroughly clean your pet's cage/aquarium and what do you
                        use to clean it?`}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Please describe)
                        </span>
                    </p>
                    <textarea />
                </div>
                <div className="section">
                    <p>
                        {`How do you provide your pet water and how often is the water changed?`}{' '}
                    </p>
                    <input id="waterChange" type="text" name="waterChange" />
                </div>
                <div className="section">
                    <p>{`How often do you clean your pet's dishes?`} </p>
                    <input id="dishCleaning" type="text" name="dishCleaning" />
                </div>
                <div className="section">
                    <p>
                        {`Does you pet eat pellets, fruits, vegetables, or insects?`}{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Please describe)
                        </span>
                    </p>
                    <textarea id="footType" />
                </div>
                <div className="section">
                    <p>Do you give any supplements and if so what kind?</p>
                    <input id="supplements" type="text" name="supplements" />
                </div>
                <div className="section">
                    <p>How much food do you feed your pet?</p>
                    <input id="foodAmountGiven" type="text" name="foodAmountGiven" />
                </div>
                <div className="section">
                    <p>How often do you offer fresh food?</p>
                    <input id="freshFoodFrequency" type="text" name="freshFoodFrequency" />
                </div>
                <div className="section">
                    <p>Has your pet had any recent diet changes?</p>
                    <input id="dietChanges" type="text" name="dietChanges" />
                </div>
                <div className="section">
                    <p>
                        {`Have there been any recent changes in your pet's cage/environment?`}{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Please describe)
                        </span>
                    </p>
                    <textarea id="environmentChanges" name="environmentChanges" />
                </div>
                {submitState === 'submitted' ? (
                    <>
                        <h3>
                            Appointment request sent! Somebody from our office will be in touch
                            shortly.
                        </h3>
                    </>
                ) : (
                    <Button disabled={submitState === 'submitting'} color="orange" type="submit">
                        {submitState === 'submitting'
                            ? 'Submitting appointment...'
                            : 'Submit Your Appointment'}
                    </Button>
                )}
            </form>
        </StyledForm>
    );
};
