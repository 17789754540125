import React from 'react';
import { device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHours = styled.div<Props>`
    padding: ${({ home }) => (home ? '4em 1em' : '6em 1em')};
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        max-width: 35px;
        height: 35px;
    }
    iframe {
        margin-top: 2em;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        border-radius: 40px;
        height: 430px;
        width: 95vw;
    }
    h2 {
        position: relative;
        text-align: center;
    }
    ul {
        margin-bottom: 2em;
        li {
            font-weight: 500;
        }
        list-style: none;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 1em;
    }
    .lists {
        display: flex;
        justify-content: space-between;
    }
    .left-column {
        .mobile {
            border-bottom: 1px solid black;
        }
        .column-section {
            img {
                width: 27px;
                height: auto;
            }
            margin: 2em 0em;
            p {
                margin: 1em 0em;
                font-weight: 600;
            }
        }
    }
    @media ${device.tablet} {
        padding: ${({ home }) => (home ? '2em 1em' : '2em 1em 12em')};
        li {
            margin: 1em 0em;
        }
        iframe {
            height: 600px;
            width: 506px;
        }

        flex-direction: row;
        justify-content: space-between;
    }
    @media ${device.laptopL} {
        iframe {
            height: 632px;
            width: 542px;
        }
    }
`;

interface Props {
    home?: boolean;
}

export const Hours: React.FC<Props> = ({ home }) => {
    return (
        <div className="container">
            <h2 className="special-case" style={{ margin: '1.5em .5em -1em' }}>
                Address/Hours
            </h2>
            <StyledHours home={home}>
                <div className="left-column">
                    <div className="column-section">
                        <a
                            rel="noreferrer"
                            href="https://www.google.com/maps/place/Country+Club+Animal+Clinic/@31.8576926,-106.5880203,17z/data=!3m2!4b1!5s0x86ddf91c39a84161:0xfc6b4247f5450b1d!4m5!3m4!1s0x86ddf91c39da8e7f:0xbb95bb033c28473!8m2!3d31.8576926!4d-106.5858316"
                            target="_blank"
                        >
                            <StaticImage
                                placeholder="none"
                                src="../images/svgs/map-marker.svg"
                                alt="map marker icon"
                            />
                            <p>5470 Doniphan Dr # A2, El Paso, TX 79932</p>
                        </a>
                    </div>
                    <div className="mobile column-section">
                        <a rel="noreferrer" href="tel:9158330645">
                            <StaticImage
                                placeholder="none"
                                src="../images/svgs/blue-mobile.svg"
                                alt="blue mobile icon"
                            />
                            <p>(915) 833-0645</p>
                        </a>
                    </div>
                    <h3>Hours</h3>
                    <div className="lists">
                        <ul>
                            <li>Monday to Friday</li>
                            <li>Saturday</li>
                            <li style={{ opacity: '.4' }}>Sunday</li>
                        </ul>
                        <ul style={{ textAlign: 'right' }}>
                            <li>8:00 AM to 6:00 PM</li>
                            <li>8:00 AM to 2:00 PM</li>
                            <li style={{ opacity: '.4' }}>Closed</li>
                        </ul>
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <StaticImage
                            imgStyle={{ height: 'auto', width: '30px' }}
                            src="../images/svgs/mark.svg"
                            alt="ribbon award icon"
                        />
                        <p style={{ lineHeight: '24px', marginLeft: '.5em', color: '#1EAD60' }}>
                            We are taking all precautions against COVID-19
                        </p>
                    </div>
                </div>
                <div className="right-column">
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3388.798532220921!2d-106.58802028503719!3d31.85769713759516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ddf91c39da8e7f%3A0xbb95bb033c28473!2sCountry%20Club%20Animal%20Clinic!5e0!3m2!1sen!2sus!4v1640307434502!5m2!1sen!2sus"
                            width="100%"
                            loading="lazy"
                            title="Animal Country"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                            aria-hidden="false"
                        />
                    </div>
                </div>
            </StyledHours>
        </div>
    );
};
