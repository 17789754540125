import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import bg from '../images/bg-icons.png';

const StyledOurServices = styled.div`
    padding: 3em 0em;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${bg});
    background-size: 290%;
    h2 {
        margin-bottom: 1em;
    }
    .card {
        margin: 2em 0em;
        padding: 2em 0em;
        background: #f3fdfe;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
            transform: translateY(-3%);
        }
        img {
            width: 128px;
            height: auto;
        }
        p {
            font-size: 24px;
            min-width: 300px;
            text-align: center;
            color: ${colors.tealLight};
            margin-top: 1em;
        }
    }
    @media ${device.tablet} {
        background-size: 80%;
        h2 {
            margin-top: 1em;
            text-align: center;
        }
        .cards {
            display: flex;
            margin-bottom: 3em;
            .card {
                img {
                    width: 79px;
                    height: auto;
                }
                padding: 2em 3em;
                justify-content: center;
                height: 200px;
                width: 203px;
                margin: 0em 1em;
            }
        }
    }
    @media ${device.laptopL} {
        background-size: 90%;
        h2 {
            margin-top: 1em;
            text-align: center;
        }

        .cards {
            display: flex;
            margin-bottom: 3em;
            justify-content: center;
            .card {
                padding: 2em 4em;

                height: 300px;
                width: 273px;
                margin: 0em 1.5em;
            }
        }
    }
    @media ${device.desktop} {
        .cards {
            .card {
                img {
                    width: 95px;
                    margin-bottom: 1em;
                }
                padding: 2em 4em;
                justify-content: center;
                height: 340px;
                width: 333px;
                margin: 0em 1em;
            }
        }
    }
    @media ${device.desktopL} {
        background-size: 100%;
    }
`;

export const OurServices: React.FC = () => {
    return (
        <StyledOurServices>
            <div className="bg">
                <h2>Our Services</h2>
                <div className="cards container">
                    <Link to="/veterinary-services/doggy-daycare/boarding/">
                        <div className="card">
                            <StaticImage
                                placeholder="none"
                                src="../images/svgs/animals-heart.svg"
                                alt="two animals and a heart"
                            />
                            <p>Pet Boarding</p>
                        </div>
                    </Link>
                    <Link to="/veterinary-services/doggy-daycare/">
                        <div className="card">
                            <StaticImage
                                placeholder="none"
                                src="../images/svgs/doggy-device.svg"
                                alt="dog contraption"
                            />
                            <p>Doggy Day Care</p>
                        </div>
                    </Link>
                    <Link to="/grooming/">
                        <div className="card">
                            <StaticImage
                                src="../images/svgs/paws-hand.svg"
                                alt="hand holding up a paw"
                            />
                            <p>Pet Grooming</p>
                        </div>
                    </Link>
                </div>
            </div>
        </StyledOurServices>
    );
};
