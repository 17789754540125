import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
    description: string;
    title: string;
    lang?: string;
    meta?: { name: string; content: string }[];
    keywords?: string;
}

export const Seo: React.FC<Props> = ({ description, lang, meta, title, keywords }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        keywords
                        author
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle =
        (title && title.replace(/^\w/, c => c.toUpperCase())) || site.siteMetadata?.title;
    const defaultKeywords = keywords || site.siteMetadata.keywords;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={defaultTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: defaultKeywords,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            <script type="application/ld+json">
                {`
{
"@context": "https://schema.org/",
"@type": "MedicalBusiness",
"name": "Country Club Animal Clinic",
"image": "",
"@id": "",
"url": "Veterinarian Clinic El Paso, TX",
"telephone": "(915) 833-0645",
"address": {
"@type": "PostalAddress",
"streetAddress": "5470 Doniphan Dr # A2,",
"addressLocality": "El Paso",
"addressRegion": "TX",
"postalCode": "79932",
"addressCountry": "US"
},
"geo": {
"@type": "GeoCoordinates",
"latitude": 31.760110,
"longitude": -106.492290
},
"openingHoursSpecification": {
"@type": "OpeningHoursSpecification",
"dayOfWeek": [
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday",
"Saturday"
],
"opens": "08:00",
"closes": "06:00"
},
"sameAs": "Country Club Animal Clinic"
}`}
</script>

        </Helmet>
    );
};

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};
