import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledTraining = styled.div`
    @media ${device.laptop} {
        .image-container {
            margin: 4em 20em;
        }
    }
`;

export const Training: React.FC = ({ children }) => {
    return (
        <StyledTraining>
            <div className="image-container">
                <StaticImage
                    quality={100}
                    src="../images/march-training-one.jpg"
                    placeholder="none"
                    alt="teeth getting examined"
                />
                <StaticImage
                    quality={100}
                    src="../images/march-training-two.jpg"
                    placeholder="none"
                    alt="fake teeth getting examined"
                />
            </div>
        </StyledTraining>
    );
};
