/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Title } from '../components/Title';
import { Blurb } from '../components/Blurb';
import { Seo } from '../components/seo';
import { PictureMain } from '../components/PictureMain';
import styled from '@emotion/styled';
import { colors, device } from '../components/layout/GlobalStyles';

const Numbers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3em 2em;
    .number {
        color: ${colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        padding: 0.65em;
        height: 25px;
        background: ${colors.orange};
        border-radius: 5px;
    }
    div {
        cursor: pointer;
    }
    .arrow {
        max-width: 14px;
        height: auto;
    }
    @media ${device.tablet} {
    }
`;

const Blogs: Page = ({ data }) => {
    const [index, setIndex] = useState(0);
    const { allMdx } = data;
    return (
        <DefaultLayout name="Blogs">
            <Seo
                title="Contact Country Club Animal Clinic"
                description="contact country club animal clinic in El Paso Texas"
            />
            <Title>
                <h1>Blogs</h1>
            </Title>
            <PictureMain>
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../images/read-more.jpeg"
                    alt="blurred out christmas themed pug"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../images/read-more.jpeg"
                    alt="christmas themed pug"
                />
            </PictureMain>
            {/* <Numbers>
                <StaticImage
                    quality={100}
                    className="arrow"
                    placeholder="none"
                    src="../images/svgs/left-light-arrow.svg"
                    alt="grey arrow left"
                />
                <StaticImage
                    quality={100}
                    className="arrow"
                    placeholder="none"
                    src="../images/svgs/left-dark-arrow.svg"
                    alt="grey arrow left"
                />
                <div onClick={() => setIndex(0)} className="number">
                    1
                </div>
                <div onClick={() => setIndex(1)} className="number">
                    2
                </div>
                <div onClick={() => setIndex(2)} className="number">
                    3
                </div>
                <div onClick={() => setIndex(3)} className="number">
                    4
                </div>
                <div onClick={() => setIndex(4)} className="number">
                    5
                </div>
                <StaticImage
                    className="arrow"
                    quality={100}
                    placeholder="none"
                    src="../images/svgs/right-arrow-light.svg"
                    alt="grey arrow left"
                />
                <StaticImage
                    className="arrow"
                    quality={100}
                    placeholder="none"
                    src="../images/svgs/right-dark-arrow.svg"
                    alt="grey arrow left"
                />
            </Numbers> */}
            {allMdx.nodes.map((item, index: number) => {
                return (
                    <div key={item.frontmatter.title}>
                        {index % 2 === 0 ? (
                            <Blurb key={item.ok} picture="left">
                                <div className="image-container">
                                    <GatsbyImage
                                        alt={item.frontmatter.images[0].name}
                                        image={
                                            item.frontmatter.images[0].childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                </div>
                                <div className="text">
                                    <h2>{item.frontmatter.title}</h2>
                                    <p>{item.frontmatter.blurb}</p>
                                    <Link to={`/${item.frontmatter.slug}`}>
                                        <span
                                            className="blue"
                                            style={{
                                                display: 'block',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            Read More
                                        </span>
                                    </Link>
                                </div>
                            </Blurb>
                        ) : (
                            <Blurb key={item.ok} picture="right">
                                <div className="image-container">
                                    <GatsbyImage
                                        alt={item.frontmatter.images[0].name}
                                        image={
                                            item.frontmatter.images[0].childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                </div>
                                <div className="text">
                                    <h2>{item.frontmatter.title}</h2>
                                    <p>{item.frontmatter.blurb}</p>
                                    <Link to={`/${item.frontmatter.slug}`}>
                                        <span
                                            className="blue"
                                            style={{
                                                display: 'block',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            Read More
                                        </span>
                                    </Link>
                                </div>
                            </Blurb>
                        )}
                    </div>
                );
            })}
        </DefaultLayout>
    );
};

export const query = graphql`
    {
        allMdx(filter: { fields: { sourceInstanceName: { eq: "blog" } } }) {
            nodes {
                frontmatter {
                    title
                    slug
                    breadcrumbTitle
                    blurb
                    images {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE)
                        }
                    }
                }
                body
            }
        }
    }
`;

export default Blogs;
