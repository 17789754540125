import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { StaticImage } from 'gatsby-plugin-image';
import { Title } from '../components/Title';
import { Blurb } from '../components/Blurb';
import { PictureSlide } from '../components/PictureSlide';
import { PictureGrid } from '../components/PictureGrid';
import { Seo } from '../components/seo';

const VetServices: Page = () => (
    <DefaultLayout name="Services">
        <Seo
            title="Veterinary Services in El Paso, TX - CountryClubAnimalClinic"
            description="Offering wide variety of veterinary services in El Paso Texas with highest quality care for your dogs and cats among other animals"
        />
        <Title>
            <h1>
                Veterinary <span>Services</span> in El Paso Texas
            </h1>
        </Title>
        <Blurb picture="left">
            <div className="image-container">
                <StaticImage
                    placeholder="none"
                    className="underlayer"
                    src="../images/cat-lying-down.png"
                    alt="cat lying down"
                />
                <StaticImage
                    quality={100}
                    placeholder="none"
                    src="../images/cat-lying-down.png"
                    alt="cat lying down"
                />
            </div>
            <div className="text">
                <p>
                    <span>Country Club Animal Clinic</span> is proud to serve El Paso, TX and the
                    surrounding communities. We are very excited about all of the services that we
                    offer to our clients and their pets. We strive to give our patients the very
                    best veterinary care and work. We work hard to stay up-to-date with the latest
                    medical advances, so we can serve our patients the best that we can.
                </p>
            </div>
        </Blurb>

        <Blurb picture="right">
            <div className="image-container">
                <StaticImage
                    placeholder="none"
                    className="underlayer"
                    src="../images/pug.png"
                    alt="cat lying down"
                />
                <StaticImage
                    quality={100}
                    placeholder="none"
                    src="../images/pug.png"
                    alt="cat lying down"
                />
            </div>
            <div className="text">
                <p>
                    “<span>Our goal</span> is to make sure that your pet stays as healthy as
                    possible. We do this by providing routine examinations, vaccinations, and much
                    more. However, we are also here to help in times of trouble and want to care for
                    your pet whenever he or she is not feeling well.
                </p>
            </div>
        </Blurb>
        <PictureSlide />
        <PictureGrid>
            <h3>
                We Care for <span>All Animals</span>, not just Cats & Dogs
            </h3>
            <div className="panel-flex">
                <div className="text">
                    <p>
                        Country Club Animal Clinic is really proud of our staff who is there for you
                        every step of the way. We are all animal lovers and are dedicated to helping
                        you and your pet live a happy and healthy life. We treat you and your pets
                        with respect while doing everything that we can to help. We aim to give your
                        pets the best possible veterinary care that they deserve.
                    </p>
                </div>
                <div className="image-container">
                    <StaticImage
                        className="image image-1"
                        placeholder="none"
                        src="../images/picturegrid/chicklet.jpeg"
                        alt="little chiclet"
                    />
                    <StaticImage
                        className="image image-2"
                        placeholder="none"
                        src="../images/picturegrid/duck.jpeg"
                        alt="little duck"
                    />
                    <StaticImage
                        className="image image-3"
                        placeholder="none"
                        src="../images/picturegrid/parrot.jpeg"
                        alt="green parrot"
                    />

                    <StaticImage
                        className="image image-4"
                        placeholder="none"
                        src="../images/picturegrid/snake.jpeg"
                        alt="little snake"
                    />
                    <StaticImage
                        className="image image-5"
                        placeholder="none"
                        src="../images/picturegrid/cow.jpeg"
                        alt="cow"
                    />
                    <StaticImage
                        className="image image-6"
                        placeholder="none"
                        src="../images/picturegrid/frog.jpeg"
                        alt="green duck"
                    />
                    <StaticImage
                        className="image image-7"
                        placeholder="none"
                        src="../images/picturegrid/rabbit.jpeg"
                        alt="grey rabit"
                    />
                    <StaticImage
                        className="image image-8"
                        placeholder="none"
                        src="../images/picturegrid/sheep.jpeg"
                        alt="fluffy sheep"
                    />
                    <StaticImage
                        className="image image-9"
                        placeholder="none"
                        src="../images/picturegrid/horse.jpeg"
                        alt="brown horse"
                    />
                    <StaticImage
                        className="image image-10"
                        placeholder="none"
                        src="../images/picturegrid/gecko.jpeg"
                        alt="brown lizard"
                    />
                </div>
            </div>
        </PictureGrid>
    </DefaultLayout>
);

export default VetServices;
