import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../layout/GlobalStyles';
import { Button } from '../layout/StyledComponents';

const StyledForm = styled.div`
    padding: 1em 1em 0em;
    fieldset {
        border: none;
        display: flex;
        padding: 0;
    }
    fieldset {
        border: none;
        display: flex;
        padding: 0;
    }
    .how-did {
        width: 340px;
    }
    .last-section {
        margin-top: 64px;
        padding-top: 32px;
        border-top: 2px solid #d0dcdd;
    }
    .section {
        p {
            margin-bottom: 6px;
        }
    }
    .desktop-flex {
        display: block;
    }

    .mid {
        width: 197px;
    }
    .checker {
        margin: 0.35em 0em;
        width: 20px;
        color: ${colors.black};
        height: 20px;
        border: none;
    }

    form {
        color: ${colors.black};
        display: flex;
        flex-direction: column;
        button {
            margin: 2em auto 4em;
            width: 90vw;
        }
    }

    .small {
        width: 107px;
    }
    h3 {
        padding-bottom: 1em;
        border-bottom: 1.5px solid #dee4e4;
        margin: 2.5em 0em 1em;
    }
    input {
        color: ${colors.black};
        text-indent: 0.35em;
        background: #ffffff;
        border: 1.5px solid #dee4e4;
        box-sizing: border-box;
        padding: 0.75em;
        border-radius: 5px;
    }

    select {
        background: linear-gradient(45deg, transparent 50%, ${colors.black} 50%),
            linear-gradient(135deg, ${colors.black} 50%, white 50%),
            linear-gradient(to right, white, white);
        background-position: calc(100% - 16px) calc(1em + 2px), calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        text-indent: 0.25em;
        padding: 0.75em;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
    }
    textarea {
        padding: 10px 0 0 10px;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
        box-sizing: border-box;
    }
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    label {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    p,
    label {
        font-weight: 500;
    }
    h3 {
        font-size: 24px;
        color: ${colors.tealLight};
    }
    .section {
        margin: 0.75em 0em;
    }
    .item {
        align-items: center;
        display: flex;
        margin-right: 1em;
        label {
            padding-left: 0.3em;
        }
        input:nth-of-type(2) {
            margin-left: 1em;
        }
    }
    .row {
        display: flex;
    }
    textarea {
        min-height: 150px;
    }
    .section {
        display: flex;
        flex-direction: column;
        input,
        select,
        textarea {
            max-width: 500px;
        }
    }
    @media ${device.tablet} {
        .pet {
            min-width: 350px;
            display: flex;
            justify-content: space-between;
        }
        .full {
            min-width: 550px;
        }
        .small-bubbles {
            display: flex;
            min-width: 70px;
            max-width: 170px;
            justify-content: space-between;
        }
        .bubbles {
            display: flex;
            min-width: 450px;
            justify-content: space-between;
        }
    }

    @media ${device.laptop} {
        .game-input {
            min-width: 410px;
        }
        .nu-game-input {
            min-width: 470px;
        }
        .push-right {
            margin-left: 62px;
            label {
                margin-right: 32px;
            }
            input {
                width: 290px;
            }
        }
        .household {
            .inp {
                width: 300px;
                margin-top: 15px;
            }
        }
        .opt {
            label {
            }
            input {
                margin-top: 35px;
            }
        }
        .how-did {
            min-width: 1135px;
        }

        .bubbles {
            min-width: 550px;
        }
        .full {
            min-width: 850px;
        }
        .payments {
            display: flex;
        }
        .lists {
            justify-content: space-between;
            width: 1000px;
            display: flex;
        }
        button {
            max-width: 415px;
        }
        .third {
            width: 360px;
        }
        .second {
            min-width: 540px;
        }
        .small {
            width: 147px;
        }
        .city {
            margin-right: 4em;
        }
        .mid {
            min-width: 156px;
        }
        .small-desktop-flex {
            width: 1000px;
            display: flex;
            justify-content: space-between;
        }
        .desktop-flex {
            display: flex;
            justify-content: space-between;
        }
    }
`;

export const PetPersonalityForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledForm className="container">
            <form onSubmit={onSubmit}>
                <h3>Please enter your pet’s information</h3>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Dog's Name <span style={{ color: '#D63434' }}>*</span>{' '}
                        </p>
                        <input
                            className="second"
                            required
                            id="petname"
                            name="petname"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>
                            Owner's Last Name <span style={{ color: '#D63434' }}>*</span>
                        </p>
                        <input
                            className="second"
                            required
                            id="last-name"
                            name="last-name"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>How did you hear about CCAC Spa & Resort?</p>
                        <input
                            className="how-did"
                            id="how-did-you-hear-about-us"
                            name="how-did-you-hear-about-us"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Date you acquired your dog (mm/dd/yyy)</p>
                        <input
                            className="second"
                            id="date-acquired-dog"
                            name="date-acquired-dog"
                            type="date"
                        />
                    </div>
                    <div className="section">
                        <p>From Where?</p>
                        <input className="second" id="from-where" name="from-where" type="text" />
                    </div>
                </div>
                <label htmlFor="knowledge-of-dog-history">
                    What knowledge do you have of your dog’s history?
                </label>
                <textarea id="knowledge-of-dog-history"></textarea>
                {/* ///// */}
                <div className="section">
                    <p>
                        Is your dog spayed/neutered? <span style={{ color: '#D63434' }}>*</span>
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <fieldset>
                            <div className="item">
                                <input id="yes" value="yes" type="radio" name="neutered" />
                                <label htmlFor="neuteredYes">Yes</label>
                            </div>
                            <div className="item">
                                <input type="radio" id="neuteredNo" name="neutered" value="no" />
                                <label htmlFor="neuteredNo">No</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <p style={{ marginTop: '16px' }}>
                    What other animals are currently in the household?
                </p>
                <div className="household small-desktop-flex">
                    <div className="section">
                        <p>Type</p>
                        <input
                            className="inp"
                            id="other-animal-type"
                            name="other-animal-type"
                            type="text"
                        />
                        <input
                            className="inp"
                            id="other-animal-type"
                            name="other-animal-type"
                            type="text"
                        />
                        <input
                            className="inp"
                            id="other-animal-type"
                            name="other-animal-type"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Age</p>
                        <input
                            className="inp"
                            id="other-animal-type"
                            name="other-animal-type"
                            type="text"
                        />
                        <input
                            className="inp"
                            id="other-animal-type"
                            name="other-animal-type"
                            type="text"
                        />
                        <input
                            className="inp"
                            id="other-animal-type"
                            name="other-animal-type"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Sex</p>
                        <fieldset>
                            <div className="opt">
                                <input
                                    type="radio"
                                    value="male"
                                    id="other-animal-sex-male"
                                    name="sexOne"
                                />
                                <label htmlFor="other-animal-sex"> Male/ </label>

                                <input
                                    value="female"
                                    type="radio"
                                    id="other-animal-sex-female"
                                    name="sexOne"
                                />
                                <label htmlFor="other-animal-sex">{'    '}Female</label>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className="opt">
                                <input type="radio" value="male" id="sexTwo" name="sexTwo" />
                                <label htmlFor="other-animal-sex"> Male/ </label>

                                <input value="female" type="radio" id="sexTwo" name="sexTwo" />
                                <label htmlFor="other-animal-sex">{'    '}Female</label>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className="opt">
                                <input type="radio" value="male" id="sexThree" name="sexThree" />
                                <label htmlFor="other-animal-sex"> Male/ </label>

                                <input value="female" type="radio" id="sexThree" name="sexThree" />
                                <label htmlFor="other-animal-sex">{'    '}Female</label>
                            </div>
                        </fieldset>
                    </div>
                    <div style={{ marginTop: '51px' }} className="section">
                        <fieldset>
                            <div className="opt">
                                <input
                                    value="spayed"
                                    type="radio"
                                    id="spayed"
                                    name="spayedOrNeutered"
                                />
                                <label htmlFor="spayed"> Spayed/ </label>
                                <input
                                    value="neutered"
                                    type="radio"
                                    id="neutered"
                                    name="spayedOrNeutered"
                                />
                                <label htmlFor="neutered"> Neutered</label>
                            </div>
                        </fieldset>
                        <div className="opt">
                            <input
                                value="spayed"
                                type="radio"
                                id="spayedTwo"
                                name="spayedOrNeuteredTwo"
                            />
                            <label htmlFor="spayed"> Spayed/ </label>
                            <input
                                value="neutered"
                                type="radio"
                                id="neuteredTwo"
                                name="spayedOrNeuteredTwo"
                            />
                            <label htmlFor="neutered"> Neutered</label>
                        </div>
                        <div className="opt">
                            <input
                                value="spayed"
                                type="radio"
                                id="spayedThree"
                                name="spayedOrNeuteredThree"
                            />
                            <label htmlFor="spayed"> Spayed/ </label>
                            <input
                                value="neutered"
                                type="radio"
                                id="neuteredThree"
                                name="spayedOrNeuteredThree"
                            />
                            <label htmlFor="neutered"> Neutered</label>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '32px' }} className="section">
                    <p>
                        Please check any problems that you have noticed about your pet:
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {` `}(Check all that apply)
                        </span>
                    </p>
                    <div className="lists">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="waterAgressive"
                                    name="waterAgressive"
                                    value="waterAgressive"
                                />
                                <label htmlFor="waterAgressive">Water Aggressive</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="mouthy"
                                    name="mouthy"
                                    value="mouthy"
                                />
                                <label htmlFor="mouthy">Mouthy</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="vocalPlay"
                                    name="vocalPlay"
                                    value="vocalPlay"
                                />
                                <label htmlFor="vocalPlay">Vocal when Playing</label>
                            </div>

                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="lameness"
                                    name="lameness"
                                    value="lameness"
                                />
                                <label htmlFor="lameness">Lameness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="anxiety"
                                    name="anxiety"
                                    value="anxiety"
                                />
                                <label htmlFor="anxiety">Anxiety</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="smallDogFriendly"
                                    name="smallDogFriendly"
                                    value="smallDogFriendly"
                                />
                                <label htmlFor="smallDogFriendly">
                                    Doesn’t do well with small dogs
                                </label>
                            </div>
                        </div>{' '}
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="foodAggressive"
                                    name="foodAggressive"
                                    value="foodAggressive"
                                />
                                <label htmlFor="foodAggressive">Food Aggressive</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="barking"
                                    name="barking"
                                    value="barking"
                                />
                                <label htmlFor="barking">Barking</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="overExcitedPlay"
                                    name="overExcitedPlay"
                                    value="overExcitedPlay"
                                />
                                <label htmlFor="overExcitedPlay">Overly Excited when Playing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="fearOfPeople"
                                    name="fearOfPeople"
                                    value="fearOfPeople"
                                />
                                <label htmlFor="fearOfPeople">Fear of People</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="unfriendlyWithLargeDogs"
                                    name="unfriendlyWithLargeDogs"
                                    value="unfriendlyWithLargeDogs"
                                />
                                <label htmlFor="unfriendlyWithLargeDogs">
                                    Doesn’t do well with large dogs
                                </label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="featherloss"
                                    name="featherloss"
                                    value="featherloss"
                                />
                                <label htmlFor="featherloss">Feather Picking or Feather Loss</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="toyPossessive"
                                    name="toyPossessive"
                                    value="toyPossessive"
                                />
                                <label htmlFor="toyPossessive">Toy Possessive</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="jumping"
                                    name="jumping"
                                    value="jumping"
                                />
                                <label htmlFor="jumping">Jumping</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="agressivePlay"
                                    name="agressivePlay"
                                    value="agressivePlay"
                                />
                                <label htmlFor="agressivePlay">Aggressive when Playing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="loudNoiseFear"
                                    name="loudNoiseFear"
                                    value="loudNoiseFear"
                                />
                                <label htmlFor="loudNoiseFear">Fear of Loud Noises</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="notHouseBroken"
                                    name="notHouseBroken"
                                    value="notHouseBroken"
                                />
                                <label htmlFor="notHouseBroken">Not Housebroken</label>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="additionComments">
                            Any additional comments or further explanation:
                        </label>
                    </div>
                    <textarea />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Does your dog play with toys?</p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input type="radio" id="playWithToysYes" name="playWithToys" />
                                    <label htmlFor="playWithToysYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="playWithToysNo" name="playWithToys" />
                                    <label htmlFor="playWithToysNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="gamesEnjoyed">
                                    If yes, what toys/games does your dog enjoy?
                                </label>
                                <input
                                    type="text"
                                    id="gamesEnjoyed"
                                    name="gamesEnjoyed"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p>Has your dog ever shared toys with other animals?</p>
                        <div className="small-bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        id="otherAnimalPlayYes"
                                        name="otherAnimalPlay"
                                    />
                                    <label htmlFor="otherAnimalPlayYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        type="radio"
                                        id="otherAnimalPlayNo"
                                        name="otherAnimalPlay"
                                    />
                                    <label htmlFor="otherAnimalPlayNo">No</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>
                            Has your dog ever growled or snapped at any person or other dog who has
                            attempted to take a toy away?
                        </p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="yes"
                                        id="dogSnapAtAnotherDogYes"
                                        name="dogSnapAtAnotherDog"
                                    />
                                    <label htmlFor="dogSnapAtAnotherDogYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="dogSnapAtAnotherDogNo"
                                        name="dogSnapAtAnotherDog"
                                    />
                                    <label htmlFor="dogSnapAtAnotherDogNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="gamesEnjoyed">If so, explain:</label>
                                <input
                                    className="game-input"
                                    type="text"
                                    id="gamesEnjoyed"
                                    name="gamesEnjoyed"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>
                            Has your dog ever played with other dogs?
                        </p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="dogPlayWithAnotherDogYes"
                                        name="dogPlayWithAnotherDog"
                                    />
                                    <label htmlFor="dogPlayWithAnotherDogYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="dogPlayWithAnotherDogNo"
                                        name="dogPlayWithAnotherDog"
                                    />
                                    <label htmlFor="dogPlayWithAnotherDogNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If so, how regularly?
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p>And if so, describe the play style</p>
                        <input className="second" id="playStyle" name="playStyle" type="text" />
                    </div>
                    <div className="section">
                        <p>Any known issues with other dogs?</p>
                        <input
                            className="second"
                            id="knownIssuesWithOtherDogs"
                            name="knownIssuesWithOtherDogs"
                            type="tel"
                        />
                    </div>
                </div>
                <div className="section">
                    <p>How does your dog react to strangers coming into your home or yard?</p>
                    <input id="reactToStrangers" type="text" name="reactToStrangers" />
                </div>
                <div className="section">
                    <p>How does your dog react to anyone passing outside your home or yard?</p>
                    <input
                        id="reactToPassingStrangerOutsideHome"
                        type="text"
                        name="reactToPassingStrangerOutsideHome"
                    />
                </div>
                <div className="section">
                    <p>
                        Are there any kinds of people that your dog automatically fears or dislikes?
                    </p>
                    <input
                        id="kindsOfPeopleDogDislikes"
                        type="text"
                        name="kindsOfPeopleDogDislikes"
                    />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>
                            Has your dog ever growled at someone?
                        </p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="dogGrowledAtSomeoneYes"
                                        name="dogGrowledAtSomeone"
                                    />
                                    <label htmlFor="dogGrowledAtSomeone">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="dogGrowledAtSomeoneNp"
                                        name="dogGrowledAtSomeone"
                                    />
                                    <label htmlFor="dogPlayWithAnotherDogNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If so, please explain:
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>Does your dog walk on a leash?</p>
                        <div className="bubbles">
                            <div className="item">
                                <input
                                    value="yes"
                                    type="radio"
                                    id="dogWalkOnLeashYes"
                                    name="dogWalkOnLeash"
                                />
                                <label htmlFor="dogWalkOnLeashYes">Yes</label>
                            </div>
                            <div className="item">
                                <input
                                    value="no"
                                    type="radio"
                                    id="dogWalkOnLeashNo"
                                    name="dogWalkOnLeash"
                                />
                                <label htmlFor="dogWalkOnLeashNo">No</label>
                            </div>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If so, describe your dog’s leash manners:
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p>How does your dog react to other people when on leash?</p>
                        <input
                            className="second"
                            id="rectWhenOnLeash"
                            name="rectWhenOnLeash"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>How does your dog react to other dogs when on leash?</p>
                        <input
                            className="second"
                            id="reactToOtherDogsWhenOnLeash"
                            name="reactToOtherDogsWhenOnLeash"
                            type="tel"
                        />
                    </div>
                </div>
                <div className="section">
                    <p>How does your dog react to strangers coming into your home or yard?</p>
                    <input
                        id="strangersInYardReaction"
                        type="text"
                        name="strangersInYardReaction"
                    />
                </div>
                <div className="section">
                    <p>How does your dog react to strangers coming into your home or yard?</p>
                    <input
                        id="strangersInHomeOrYardReaction"
                        type="text"
                        name="strangersInHomeOrYardReaction"
                    />
                </div>
                <div className="section">
                    <p>
                        Are there any kinds of people that your dog automatically fears or dislikes?
                    </p>
                    <input id="typesOfPeopleDogFears" type="text" name="typesOfPeopleDogFears" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>
                            Has your dog ever growled at someone?
                        </p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="growledYes"
                                        name="growledAtSomeone"
                                    />
                                    <label htmlFor="growledYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="growledNo"
                                        name="growledAtSomeone"
                                    />
                                    <label htmlFor="growledNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If so, please explain:
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>Has your dog ever bitten someone?</p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="bittenSomeoneYes"
                                        name="bittenSomeone"
                                    />
                                    <label htmlFor="bittenSomeoneYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="bittenSomeoneNo"
                                        name="bittenSomeone"
                                    />
                                    <label htmlFor="bittenSomeoneNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If so, please explain:
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>
                            Has your dog ever had formal training?
                        </p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="formalTrainingYes"
                                        name="formalTraining"
                                    />
                                    <label htmlFor="formalTrainingYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="formalTrainingNo"
                                        name="formalTraining"
                                    />
                                    <label htmlFor="formalTrainingNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If yes, when and where?
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>What commands does your dog know?</p>
                    <input id="commandsKnown" type="text" name="commandsKnown" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>Is your dog comfortable in a crate?</p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="comfortableCrateYes"
                                        name="comfortableInCrate"
                                    />
                                    <label htmlFor="comfortableCrateYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="comfortableCrateNo"
                                        name="comfortableInCrate"
                                    />
                                    <label htmlFor="comfortableCrateNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If not, how does your dog react?
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p style={{ marginBottom: '15px' }}>Does your dog have hip dysplasia?</p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        id="hipDysplasiaYes"
                                        name="hipDysplasia"
                                        value="yes"
                                    />
                                    <label htmlFor="hipDysplasiaYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="hipDysplasiaNo"
                                        name="hipDysplasia"
                                    />
                                    <label htmlFor="hipDysplasiaNo">No</label>
                                </div>
                            </fieldset>
                            <div className="push-right item">
                                <label htmlFor="howRegularPlayWithDogs">
                                    If yes, what restrictions need to be placed on your dog’s
                                    activities or movements?
                                </label>
                                <input
                                    className="nu-game-input"
                                    type="text"
                                    id="howRegularPlayWithDogs"
                                    name="howRegularPlayWithDogs"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>Does your dog have any sensitive areas on its body?</p>
                    <input id="sensitiveAreasbody" type="text" name="sensitiveAreasbody" />
                </div>
                <div className="section">
                    <p>Where is your dog’s favorite petting spots?</p>
                    <input id="favoritePettingSpots" type="text" name="favoritePettingSpots" />
                </div>
                <div className="section">
                    <p>
                        Any other comments or special accommodation requests for your dog which you
                        feel may be helpful
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Please describe)
                        </span>
                    </p>
                    <textarea name="specialAccomodationNotes" id="specialAccomodationNotes" />
                </div>
                <div className="last-section">
                    <p>
                        This information is accurate to the best of my knowledge. I understand that
                        Country Club Spa &amp; Resort will make decisions that affect the care and
                        safety of my dog, other dogs, and the staff based on this information, and
                        that it is my responsibility to update the information as I am aware of
                        changes.
                    </p>
                    <div className="last-section-flex">
                        <div className="desktop-flex">
                            <div className="section">
                                <p>
                                    Owner’s Signature <span style={{ color: '#D63434' }}>*</span>{' '}
                                </p>
                                <input
                                    className="second"
                                    id="rectWhenOnLeash"
                                    name="rectWhenOnLeash"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="section">
                                <p>
                                    Date <span style={{ color: '#D63434' }}>*</span>{' '}
                                </p>
                                <input
                                    required
                                    className="second"
                                    id="reactToOtherDogsWhenOnLeash"
                                    name="reactToOtherDogsWhenOnLeash"
                                    type="date"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {submitState === 'submitted' ? (
                    <>
                        <h3>
                            Appointment request sent! Somebody from our office will be in touch
                            shortly.
                        </h3>
                    </>
                ) : (
                    <Button disabled={submitState === 'submitting'} color="orange" type="submit">
                        {submitState === 'submitting'
                            ? 'Submitting appointment...'
                            : 'Submit Your Appointment'}
                    </Button>
                )}
            </form>
        </StyledForm>
    );
};
