import React from 'react';
import { device, colors } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledSection = styled.div`
    padding: 0em 1em 2em;
    .groom-pic {
        width: 300px;
        height: auto;
        margin: 0 auto;
    }
    h4 {
        margin-top: 2em;
        line-height: 30px;
        font-size: 30px;
    }
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1em 0em;
    }
    .image {
        margin: 1em 0em;
    }
    span {
        color: ${colors.black};
    }
    h5 {
        color: ${colors.orange};
        font-weight: 700;
    }
    @media ${device.tablet} {
        .spec {
            h5 {
                font-size: 32px;
            }
            p {
                font-size: 19px;
            }
            padding-left: 8em;
        }
        padding: 0em;
        .section {
            flex-direction: row;
            justify-content: space-between;
            width: 700px;
        }
        .item-text {
            width: 440px;
        }
        .reverse {
            width: 700px;
            justify-content: space-between;
            flex-direction: row-reverse;
        }
        margin: 0 auto;
        max-width: 700px;
    }
    @media ${device.laptopL} {
        margin: 0 auto;
        max-width: 1065px;
        h5 {
            font-size: 16px;
        }
        .section,
        .reverse {
            width: 1065px;
        }
        .item-text {
            width: 740px;
        }
    }
    @media ${device.desktopL} {
        h5 {
            font-size: 28px;
        }
        margin: 0 auto;
        max-width: 1265px;
        .item-text {
            width: 840px;
        }
        .section,
        .reverse {
            width: 1165px;
        }

        h4 {
            font-size: 34px;
        }
        p {
            line-height: 32px;
            font-size: 1.2em;
        }
    }
`;

export const Shampoos: React.FC = () => {
    return (
        <StyledSection>
            {/* <h4>Grooming Specials</h4> */}

            {/* <div className="section">
                <StaticImage
                    className="groom-pic"
                    alt="march daycare specials"
                    src="../images/march-groom.jpg"
                    placeholder="none"
                />
                <div className="spec item-text">
                    <h5>St. Patrick's Day Grooming Special</h5>
                    <p>
                        One of the many specials we will be offering this year on our Veterinarian-
                        approved Grooming products. See our trusted brands below!
                    </p>
                </div>
            </div> */}

            <h4>Grooming Products</h4>
            <div className="reverse section">
                <StaticImage
                    className="image"
                    placeholder="none"
                    quality={90}
                    src="../images/grooming/oatmeal-shampoo.png"
                    alt="bottle of oatmeal soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Hydro Surge Oatmeal Soothing Shampoo <span>-</span>
                    </h5>
                    <p>
                        HydroSurge® Apple Oatmeal Shampoo is infused with vitamins and natural
                        moisturizers, and has a pleasant apple scent. Can be used alone or with the
                        HydroSurge® bathing system.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/hydro-conditioner.png"
                    alt="bottle of conditioner"
                />
                <div className="item-text">
                    <h5>
                        Hydro Surge Dermasilk Conditioner <span>-</span>
                    </h5>
                    <p>
                        HydroSurge® Dermasilk Conditioner adds softness and shine to coats. Natural
                        ingredients help to strengthen the hair shaft. Can be used alone or with the
                        Hydrosurge Bathing System.
                    </p>
                </div>
            </div>

            <h4>Hypo Allergenic</h4>
            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/quadruped-shampoo.png"
                    alt="bottle of quadruper grooming solution"
                />
                <div className="item-text">
                    <h5>
                        Quadruped Tearless Hypo-Allergenic Shampoo <span>-</span>
                    </h5>
                    <p>
                        The Quadruped Hypo-Allergenic Tearless Shampoo (Gallon) is a gentle shampoo
                        that offers superior cleaning while being free of perfume and dyes. Perfect
                        for allergy prone people and pets.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/chlorhexidine-shampoo.png"
                    alt="bottle of chlorhexidine-shampoo"
                />
                <div className="item-text">
                    <h5>
                        Chlorhexidine Shampoo <span>-</span>
                    </h5>
                    <p>
                        Helps fight the microorganisms that cause skin problems with the Davis
                        Chlorhexidine Dog & Cat Shampoo. Designed to help combat moderate conditions
                        associated with bacteria and a wide range of microorganisms, this shampoo is
                        formulated with 2% chlorhexidine gluconate for soothing relief. It’s proven
                        safe, working to provide a therapeutic bath that helps promote healthy skin,
                        with special conditioners that clean and deodorize with a pleasant scent.
                        Effective and mild, this shampoo can even be used for routine shampooing for
                        all dogs, puppies, cats and kittens.
                    </p>
                </div>
            </div>

            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/douxo-shampoo.png"
                    alt="bottle of douxo-shampoo"
                />
                <div className="item-text">
                    <h5>
                        Douxo Calm Shampoo <span>-</span>
                    </h5>
                    <p>
                        DOUXO ®  S3 CALM helps reduce inflammation, soothe irritated and itchy
                        skin.  It also helps repair the skin’s ecosystem. DOUXO ®  S3 CALM shampoo
                        and mousse protocol has been clinically proven to help decrease the signs of
                        canine atopic dermatitis in as little as 7 days, with best results seen
                        after 21 days. DOUXO ®  S3 CALM shampoo and mousse has been clinically
                        tested in acute conditions with a rigorous protocol to validate safety in
                        dogs and cats.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/jeffers-shampoo.png"
                    alt="bottle of jeffers-shampoo"
                />
                <div className="item-text">
                    <h5>
                        Jeffers Show-Shine <span>-</span>
                    </h5>
                    <p>
                        Nourishes coat by brightening and softening and gives coat lustrous show
                        ring shine by replacing natural oils. Restores moisture to coat and skin.
                    </p>
                </div>
            </div>

            <h4>Luxury</h4>
            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/hydra-shampoo.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Hydra Moisturizing Shampoo <span>-</span>
                    </h5>
                    <p>
                        Hydra Moist Shampoo (169 oz.) is a perfect choice dogs and cats of all
                        breeds and coat types! Its concentrated formula (10:1) contains oatmeal
                        extract which moisturizes and soothes for an ultra-soft finish.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/hydra-conditioner.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Hydra Moisturizing Conditioner <span>-</span>
                    </h5>
                    <p>Hydra Moist Conditioner (169 oz.) provides shine and softness.</p>
                </div>
            </div>

            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/south-shampoo.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        South Bark’s Blueberry Clove Shampoo <span>-</span>
                    </h5>
                    <p>
                        The Blueberry Facial cleans and brightens your pet’s face. South Bark’s “3
                        in 1” Blueberry Facial can be used as a face wash, a full body shampoo, and
                        a color brightener. It’s relaxing, calming and cleansing.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/south-conditioner.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        South Bark’s Blueberry Clove Conditioner <span>-</span>
                    </h5>
                    <p>
                        Spice up your client’s fur with the help of Blueberry and Clove. Stimulate
                        and revitalize your client’s coat with our naturally aromatic conditioning
                        product.
                    </p>
                </div>
            </div>

            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/chris-shampoo.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Chris Christians Whitening Treatment Shampoo <span>-</span>
                    </h5>
                    <p>
                        Chris Christensen White on White Shampoo gets the yellow out! Unlike other
                        whitening shampoos, White on White can be used on all coat colors! This
                        product is an optic intensifying treatment within a shampoo, removing all
                        yellow stains with a lustrous sheen on darker colors.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/chris-black-shampoo.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Chris Christian’s Black on Black Shampoo <span>-</span>
                    </h5>
                    <p>
                        Chris Christensen Black on Black Shampoo gets the red out! Black on Black is
                        an optic intensifying, high sheen color-revitalizing treatment within a
                        shampoo. Formulated specifically for use on black coated dogs, it restores
                        the intense black color of coats exposed to the bleaching effects of the
                        sun.
                    </p>
                </div>
            </div>

            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/skunk-shampoo.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Quadruped Skunk Deodorizer & Odor Eliminator Shampoo <span>-</span>
                    </h5>
                    <p>
                        Eliminates skunk and pet odors 100% by neutralizing, not masking. This deep
                        penetrating shampoo cuts through skunk spray oil while eradicating the foul
                        odor. Botanicals help soothe and moisturize skin while conditioning coats,
                        making this an ideal general purpose shampoo that leaves coats fresh and
                        clean smelling. Shampoo should be used within 48 hours of pet being sprayed
                        by skunk to be effective.
                    </p>
                </div>
            </div>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/hydrosurge-shampoo.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Hydrosurge Shed Control Shampoo <span>-</span>
                    </h5>
                    <p>
                        With a balanced combination of cleansers and conditioning emulsifiers,
                        HydroSurge® Shed Control Shampoo strengthens, nourishes, and protects
                        shedding coats. This coconut verbena scented shampoo can be used alone or
                        with the HydroSurge® bathing system.
                    </p>
                </div>
            </div>

            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/furminator-conditioner.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Furminator Deshedding Ultra Premium Conditioner <span>-</span>
                    </h5>
                    <p>
                        Dramatically reduce shedding by 60%-80%. Premium FURminator®! deShedding
                        Conditioner loosens undercoat before brushing to make deshedding easier.
                        Promotes healthier skin and coats.
                    </p>
                </div>
            </div>

            <h4>Dental</h4>

            <div className="section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/vetzlife-gel.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Vetzlife Oral Care Gel <span>-</span>
                    </h5>
                    <p>
                        Vetzlife Oral Care Gel is a professional strength formula with 100% natural
                        ingredients that help maintain healthy gums and teeth. A powerful
                        combination of herbal extracts and essential oils that provide defense
                        against bacteria that cause gingivitis, plaque and tartar. Eliminates bad
                        breath.
                    </p>
                </div>
            </div>

            <div className="reverse section">
                <StaticImage
                    placeholder="none"
                    className="image"
                    quality={90}
                    src="../images/grooming/oratene-freshener.png"
                    alt="hydra-shampoo soothing shampoo"
                />
                <div className="item-text">
                    <h5>
                        Oratene® Breath Freshener <span>-</span>
                    </h5>
                    <p>
                        Delivers a gentle mist to freshen any pet’s breath. Features two complete
                        enzyme systems to eliminate odor-causing bacteria PLUS the enzyme system to
                        inhibit plaque formation.
                    </p>
                </div>
            </div>
        </StyledSection>
    );
};
