import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { ReptileMammalForm } from '../../components/forms/ReptileMammalForm';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureMain } from '../../components/PictureMain';
import { Seo } from '../../components/seo';
import { Title } from '../../components/Title';

const MammalQuestionnaire: Page = () => (
    <DefaultLayout path="new-clients/" prevName="New Clients" name="Small Mammal Questionnaire">
        <Seo
            title="Small Mammal Questionnaire Country Club Animal Clinic"
            description="Small Mammal Questionnaire Country Club Animal Clinic"
        />
        <Title>
            <h1>
                <span className="orange">Small Mammal Questionnaire </span>At Country Club Animal
                Clinic
            </h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../../images/small-mammal.jpeg"
                alt="dog lying over a magazing showing a photo of a very similar dog"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../../images/small-mammal.jpeg"
                alt="dog lying over a magazing showing a photo of a very similar dog"
            />
        </PictureMain>
        <ReptileMammalForm />
    </DefaultLayout>
);

export default MammalQuestionnaire;
