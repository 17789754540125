import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Title } from '../../components/Title';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureText } from '../../components/PictureText';
import { Seo } from '../../components/seo';

const PetOfMonth: Page = () => (
    <DefaultLayout path="about-us" prevName="About Us" name="Pet of the Month">
        <Seo
            title="Country Club Animal Clinic Pet of the Month"
            description="country club animal pet of the month at your local El Paso Texas clinic"
        />
        <Title>
            <h1>
                Country Club Aninal Clinic <span>Pet of the Month</span>
            </h1>
        </Title>
        <PictureText>
            <div className="image-container">
                <StaticImage
                    className="picture"
                    quality={90}
                    placeholder="blurred"
                    src="../../images/white-cat.jpg"
                    alt="doctor on green outfit"
                />
            </div>
            <div className="text">
                <h2>Fruitcake Rutledge</h2>
                <p>
                    Fruitcake is one of our longtime patients, he’s been fighting heart disease for
                    a few months, He is a super sweet and awesome kitty and he loves to ride in a
                    pouch. Dry food is his favorite, especially from his neighbors. Another one of
                    his favorite activities is to scare away other kitties from his home.
                </p>
                <p>Fruitcake has the superpower of peeing on command!!</p>
            </div>
        </PictureText>
    </DefaultLayout>
);

export default PetOfMonth;
