import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';

const StyledCarousel = styled.div`
    .blue-border {
        border: 2px solid ${colors.blue};
    }

    .underlayer {
        opacity: 0.7;
        filter: blur(41px);
        border-radius: 20px;
        position: absolute;
        top: 2em;
        left: 2em;
        width: 100%;
        height: 226px;
        border-radius: 20px;
    }
    .selection-display {
        .arrow {
            display: none;
            cursor: pointer;
        }
    }
    .image-container {
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 1em;
        width: 95vw;
        img {
            height: 400px;
        }
    }
    .pictures-container {
        overflow-x: scroll;
        .scroll {
            width: 800vw;
            display: flex;
            padding: 2em 0em;
            overflow-y: hidden;
        }
        img {
            padding: 0.5em;
            border-radius: 20px;
            width: 305px;
            height: auto;
        }
    }

    @media ${device.tablet} {
        .selection-display {
            padding: 0em 5em;
            display: flex;
            align-items: center;
            .arrow {
                display: block;
            }
        }
        .pictures-container {
            .scroll {
                width: 400vw;
                height: 280px;
            }
            img {
                padding: 0.5em;
                border-radius: 20px;
                width: 305px;
                height: 180px;
            }
        }
    }
    @media ${device.laptopL} {
        .underlayer {
            left: 1em;
            width: 100%;
            height: 500px;
        }
        .image-container {
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 0.5em;
            width: 1000px;
            img {
                width: 1000px;
                height: 500px;
            }
        }
        .pictures-container {
            .scroll {
                padding-left: 2em;
                width: 212vw;
                height: 280px;
            }
        }
    }

    @media ${device.desktop} {
        .underlayer {
            height: 600px;
            width: 1000px;
        }
        .image-container {
            height: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 1em;
            width: 1000px;
            img {
                width: 1000px;
                height: 600px;
            }
        }
        .pictures-container {
            .scroll {
                width: 212vw;
                height: 280px;
            }
        }
    }
`;

export const Carousel: React.FC = ({ children }) => {
    return <StyledCarousel>{children}</StyledCarousel>;
};
