/* eslint-disable mdx/no-unused-expressions */
import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { graphql } from 'gatsby';
import { PictureMain } from '../components/PictureMain';
import { Title } from '../components/Title';
import { Faq } from '../components/FAQ';
import { device, colors } from '../components/layout/GlobalStyles';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Seo } from '../components/seo';

const NewBlurb = styled.div`
    overflow-y: hidden;

    h2 {
        font-size: 40px;
        letter-spacing: -0.05em;
        color: ${colors.orange};
    }
    h3 {
        color: ${colors.blue};
        font-size: 1rem;
        line-height: 30px;
        margin: 0em 0em 1.5em;
    }
    .text {
        padding: 2em 0.75em;
        font-size: 16px;
    }
    p {
        line-height: 30px;
        margin: 0.5em 0em;
    }
    span {
        font-size: 22px;
        color: ${colors.blue};
        font-weight: 600;
    }
    img {
        width: 438px;
        height: auto;
        left: ${({ picture }) => (picture === 'left' ? '-5em' : '1em')};
        margin-bottom: 5em;
    }
    @media ${device.tablet} {
        padding: 2em 0em 0em;
        width: 1065px;

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-height: 300px;
            padding: 0em;
        }
        img {
            left: 0em;
        }
    }

    @media ${device.laptop} {
        .image-container {
            max-height: 400px;
        }
        margin: 0em auto;
        display: flex;

        flex-direction: ${({ picture }) => (picture === 'left' ? 'row' : 'row-reverse')};
        justify-content: space-between;
        img {
            width: 530px;
        }
        .text {
            width: 35vw;
        }
    }
`;

const StyledTextMain = styled.div`
    .special-title {
        text-align: center;
        font-size: 36px;
    }
    .special-container {
        div {
            margin: 2em 1em;
        }
    }
    padding: 2em 1em;
    a {
        font-weight: 600;
        color: ${colors.orange};
    }
    .blue {
        font-weight: 600;
        color: ${colors.blue};
    }
    .orange {
        font-weight: 600;
        color: ${colors.orange};
    }
    p {
        font-size: 1em;
        line-height: 30px;
        margin-top: 1em;
    }
    h3 {
        font-size: 24px;
        margin-top: 1.25em;
        line-height: 30px;
        font-weight: 700;
    }
    ul {
        color: ${colors.black};
        margin: 1.5em 0em 1.5em 1em;
    }
    h4 {
        font-size: 18px;
        line-height: 30px;
        margin-top: 1.5em;
        font-weight: 700;
    }
    h5 {
        font-size: 24px;
        margin-top: 2em;
        line-height: 30px;
        font-weight: 700;
    }
    .button-flex {
        button {
            margin: 3em 0em;
        }
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        .try {
            margin-top: 2em;
        }
        margin: 0 auto;
        max-width: 700px;

        .button-flex {
            button {
                margin: 2em 0em;
            }
        }
    }

    @media ${device.laptop} {
        .special-container {
            margin: 1em 10em;
            img {
            }
        }
    }
    @media ${device.laptopL} {
        button {
            margin: 2em 0em;
        }

        margin: 0 auto;
        max-width: 1065px;
        h5 {
            margin: 2em 0em 1.4em;
        }
    }
    @media ${device.desktopL} {
        margin: 0 auto;
        max-width: 1265px;
        h4 {
            font-size: 34px;
        }
        button {
            margin: 2.5em 0em;
        }
        h5 {
            padding: 0em 1.6em;
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
        }
        p {
            padding: 0em 2em;
            line-height: 32px;
            font-size: 1.2em;
        }
    }
`;

const Other = ({ data }) => {
    const { mdx } = data;
    let blueText = '';
    let remainingText = '';
    const wordSplit = quote => {
        let first = [];
        let second = [];
        const words = quote.split(' ');
        first.push(words.splice(0, 2).join(' '));

        blueText = first;
        remainingText = words.join(' ');
    };
    {
        mdx.frontmatter.quote && wordSplit(mdx.frontmatter.quote);
    }

    return (
        <DefaultLayout
            path="new-clients/"
            prevName="New Clients"
            name={mdx.frontmatter.breadcrumbTitle}
        >
            <Seo
                title={mdx.frontmatter.meta.title}
                description={mdx.frontmatter.meta.description}
            />
            <Title>
                <h1>{mdx.frontmatter.title}</h1>
            </Title>
            <PictureMain>
                {mdx.frontmatter.images && (
                    <>
                        <GatsbyImage
                            alt={mdx.frontmatter.images.name}
                            className="underlayer"
                            quality={90}
                            placeholder="none"
                            imgStyle={{ borderRadius: '20px' }}
                            image={mdx.frontmatter.images[0].childImageSharp.gatsbyImageData}
                        />
                        <GatsbyImage
                            alt={mdx.frontmatter.images.name}
                            placeholder="none"
                            imgStyle={{ borderRadius: '20px' }}
                            quality={90}
                            image={mdx.frontmatter.images[0].childImageSharp.gatsbyImageData}
                        />
                    </>
                )}
            </PictureMain>
            {mdx.frontmatter.quote && (
                <>
                    <p
                        style={{
                            maxWidth: '800px',
                            textAlign: 'center',
                            margin: '6em auto 0em',
                        }}
                    >
                        {mdx.frontmatter.blurbText}
                    </p>
                    <NewBlurb picture="right">
                        <div className="image-container">
                            <GatsbyImage
                                alt={mdx.frontmatter.images.name}
                                imgStyle={{ borderRadius: '20px' }}
                                quality={90}
                                image={mdx.frontmatter.images[1].childImageSharp.gatsbyImageData}
                            />
                        </div>
                        <div className="text">
                            <p>
                                <span style={{ fontSize: '30px', color: `${colors.orange}` }}>
                                    “
                                </span>
                                <span style={{ fontSize: '23px' }} className="blue">
                                    {blueText}{' '}
                                </span>
                                {remainingText}
                            </p>
                        </div>
                    </NewBlurb>
                </>
            )}
            {mdx.frontmatter.quote ? (
                <StyledTextMain>
                    {' '}
                    <MDXRenderer images={mdx.frontmatter.images}>{mdx.body}</MDXRenderer>
                </StyledTextMain>
            ) : (
                <StyledTextMain>
                    {' '}
                    <div className="try">
                        <MDXRenderer images={mdx.frontmatter.images}>{mdx.body}</MDXRenderer>
                    </div>
                </StyledTextMain>
            )}
            {mdx.exports.questions && <Faq questions={mdx.exports.questions} />}
        </DefaultLayout>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                breadcrumbTitle
                textTitle
                blurbText
                quote
                meta {
                    title
                    description
                }
                images {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
            exports {
                questions {
                    answer
                    question
                }
            }
            body
        }
    }
`;

export default Other;
