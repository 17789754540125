import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Title } from '../components/Title';
import { PictureMain } from '../components/PictureMain';
import { TextMain } from '../components/TextMain';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '../components/layout/StyledComponents';
import { Seo } from '../components/seo';

const Applications: Page = () => (
    <DefaultLayout name="employment">
        <Seo
            title="Employment at Country Club Animal Clinic"
            description="Employment at Country Club Animal Clinic"
        />
        <Title>
            <h1>Employment at Country Club Animal Clinic</h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../images/hands.jpeg"
                alt="group of hands on a wooden table"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../images/hands.jpeg"
                alt="group of hands on a wooden table"
            />
        </PictureMain>
        <TextMain>
            <h5>Intership Program</h5>
            <p>
                The Intern Program at Country Club Animal Clinic provides individuals seeking a
                career in veterinary medicine an opportunity to gain experience and knowledge into
                the field. Under the supervision of our senior technical staff, interns are able to
                receive hands on training, the ability to observe surgical procedures and the
                education to inform clients on the different aspects of veterinary medicine. Our
                program is available to pre vet students and individuals interested in a veterinary
                technician career. An application can be found below:
            </p>
            <div className="button-flex">
                <Button color="orange">Download Internship Application</Button>
            </div>
            <h5>Employment Opportunities</h5>
            <p>
                If you’re interested in applying for a job at Country Club Animal Clinic then please
                fill out our employment application by clicking the link below:
            </p>
            <div style={{ paddingBottom: '2em' }} className="button-flex">
                <Button color="orange">Download Employment Application</Button>
            </div>
        </TextMain>
    </DefaultLayout>
);

export default Applications;
