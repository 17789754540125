import React from 'react';
import { device } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledSection = styled.div`
    padding: 2em 1em;
    p {
        font-size: 1em;
        line-height: 30px;
        margin-top: 1em;
    }
    h4 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    h5 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }
    .button-flex {
        button {
            margin: 3em 0em;
        }
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        margin: 0 auto;
        max-width: 700px;
        .button-flex {
            button {
                margin: 2em 0em;
            }
        }
    }
    @media ${device.laptopL} {
        button {
            margin: 2em 0em;
        }
        padding: 3em 0em;
        margin: 0 auto;
        max-width: 1065px;
        h5 {
            margin: 2em 0em 1.4em;
        }
    }
    @media ${device.desktopL} {
        margin: 0 auto;
        max-width: 1265px;
        h4 {
            font-size: 34px;
        }
        button {
            margin: 2.5em 0em;
        }
        h5 {
            padding: 0em 1.6em;
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
        }
        p {
            padding: 0em 2em;
            line-height: 32px;
            font-size: 1.2em;
        }
    }
`;

export const TextMain: React.FC = ({ children }) => {
    return <StyledSection>{children}</StyledSection>;
};
