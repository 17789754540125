import React, { useState } from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import bg from '../images/bg-icons.png';
import person from '../images/svgs/person.svg';
import email from '../images/svgs/email.svg';
import mobile from '../images/svgs/mobile.svg';

const StyledContactUs = styled.div`
    background: url(${bg});
    background-size: 290%;
    padding: 2em 1em;
    input[type='date']::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        position: relative;
        left: -275px;
        top: 3px;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        border-width: thin;
    }
    h2 {
        text-align: center;
        margin: 0.25em 0em 1em;
    }
    h3 {
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #393d3e;
        margin: 1em 0em;
    }

    p {
        text-align: center;
    }
    textarea {
        padding: 1em;
        height: 100px;
        margin: 1em 0em 2em;
    }
    form {
        padding: 0em 1.5em;
        img {
            height: 26px;
            width: 26px;
            position: absolute;
            top: 1.55em;
            left: 0.75em;
        }
        .input-container {
            position: relative;
        }
        input {
            text-indent: 35px;
            padding-left: 10px;
            width: 100%;
            margin: 1em 0em;
            background: #ffffff;
            border: 1.5px solid #dee4e4;
            box-sizing: border-box;
            padding: 1em;
            border-radius: 15px;
        }
        textarea {
            margin-bottom: 2.5em;
            outline: 1.5px solid #dee4e4;
            border: none;
            box-sizing: border-box;
            border-radius: 15px;
        }
        button {
            margin-bottom: 2em;
        }

        display: flex;
        flex-direction: column;
    }
    .appointment-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em;
        margin-top: 2em;
        background: #ffffff;
        box-shadow: 0px 18px 59px rgba(70, 160, 155, 0.19);
        border-radius: 10px;
        img {
            width: 90%;
            margin: 0 auto;
            border-radius: 10px;
        }
        button {
            margin: 2em 0em 0.5em;
            width: 95%;
        }
    }
    @media ${device.tablet} {
        .container {
            div {
                width: 42vw;
            }
        }
        textarea {
            width: 325px;
        }
        .appointment-card {
            width: 481px;
            height: 494.01px;

            margin-top: 6.5em;
            margin-bottom: 2em;
        }
        background-size: 80%;
    }
    @media ${device.laptop} {
        input[type='date']::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            position: relative;
            left: -340px;
        }
    }
    @media ${device.laptopL} {
        h2 {
            margin-right: 3.5em;
        }
        .container {
            display: flex;
            justify-content: space-around;
            div {
                max-width: 521px;
            }
        }
        form,
        input {
            max-width: 400px;
            button {
                margin-top: -0.25em;
                width: 400px;
            }
        }
        textarea {
            width: 400px;
        }
        .appointment-card {
            button {
                width: 75%;
                margin-top: 2em;
                margin: 3em auto;
            }
            img {
                width: 457px;
                height: 256.01px;
            }
            min-width: 521px;
            margin-top: 7.5em;

            max-height: 544.01px;
        }
        background-size: 90%;
    }
    @media ${device.desktop} {
        .container {
            display: flex;
            justify-content: space-around;
            div {
                max-width: 621px;
            }
        }
        .appointment-card {
            margin-top: 7.5em;
            height: 530px;
            img {
                width: 537px;
                height: 256.01px;
            }
        }
        background-size: 100%;
    }
`;

interface Props {
    page?: boolean;
}

export const ContactUs: React.FC<Props> = ({ page }) => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const data = Object.fromEntries(new FormData(e.target));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };

    return (
        <StyledContactUs>
            <div className="container">
                <div className="form-container">
                    {!page ? <h2>Contact Us</h2> : <div className="margin"></div>}
                    <form onSubmit={onSubmit}>
                        <div className="input-container">
                            <img placeholder="none" src={person} alt="person icon" />
                            <input type="text" name="name" placeholder="Your Name" required />
                        </div>
                        <div className="input-container">
                            <img placeholder="none" src={email} alt="email icon" />
                            <input type="email" name="email" placeholder="Email *" required />
                        </div>
                        <div className="input-container">
                            <img placeholder="none" src={mobile} alt="mobile icon" />
                            <input type="tel" name="phone" placeholder="Phone *" required />
                        </div>
                        <div className="input-container">
                            <input
                                type="date"
                                name="date"
                                placeholder="select Date & Time *"
                                required
                            />
                        </div>
                        <textarea
                            name="message"
                            placeholder="Add any comments for our staff or doctors"
                        />
                        {submitState === 'submitted' ? (
                            <>
                                <h3>
                                    Message sent! Somebody from our office will be in touch shortly.
                                </h3>
                            </>
                        ) : (
                            <Button
                                disabled={submitState === 'submitting'}
                                color="blue"
                                type="submit"
                            >
                                {submitState === 'submitting'
                                    ? 'Sending message...'
                                    : 'Send Message'}
                            </Button>
                        )}
                    </form>
                </div>

                <div className="appointment-card">
                    <StaticImage
                        placeholder="none"
                        src="../images/cat.jpg"
                        quality={100}
                        alt="cat in darkness"
                    />
                    <h3>Want an Appointment Quickly?</h3>
                    <p>Get an appointment in just a call.</p>

                    <Button color="orange">
                        {' '}
                        <a
                            style={{ color: `${colors.white}` }}
                            rel="noreferrer"
                            href="tel:9158330645"
                        >
                            Call Us at (915) 833-0645{' '}
                        </a>
                    </Button>
                </div>
            </div>
        </StyledContactUs>
    );
};
