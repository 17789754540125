import { cloneElement } from 'react';

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
    return element;
};

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
    if (element.type.Layout === false) {
        return cloneElement(element, props);
    }
    return element;
};
