import React from 'react';
import { device, colors } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const StyledPictureSlide = styled.div`
    padding-top: 2em;
    width: 100%;
    .row {
        overflow-x: scroll;
    }
    h3 {
        color: ${colors.tealDark};
        text-align: center;
        margin-bottom: 1em;
    }
    p {
        cursor: pointer;
        font-weight: 800;
        &:hover {
            color: ${colors.blue};
        }
        text-align: center;
        margin: 0.5em 0em;
        font-size: 16px;
    }
    h4 {
        margin: 0.5em;
        color: ${colors.blue};
        font-size: 28px;
        text-align: center;
        letter-spacing: -0.05em;
    }
    span {
        color: ${colors.orange};
    }
    .row {
        margin-bottom: 1em;
        display: flex;
        display: none;
        justify-content: center;
        div {
            margin: 0em 1.5em;
            img {
                min-width: 217px;
                height: 217px;
                border-radius: 14px;
            }
        }
    }
    @media ${device.tablet} {
        h3 {
            color: ${colors.tealDark};
            text-align: center;
            margin-bottom: -0.75em;
        }
        max-width: 740px;
        margin: 0 auto;
        padding-left: 3em;
        h4 {
            text-align: left;
            margin: 1.5em 0.5em 1em;
        }
        .row {
            display: flex;
            flex-wrap: wrap;
        }
    }
    @media ${device.laptop} {
        max-width: 1000px;
        margin: 0 auto;
    }
    @media ${device.laptopL} {
        max-width: 1280px;
        margin: 0 auto;
    }
    @media ${device.desktop} {
        max-width: 1480px;
        margin: 0 auto;

        p {
            font-size: 18px;
        }
        .row {
            div {
                img {
                    min-width: 247px;
                    height: 247px;
                }
            }
        }
    }
    @media ${device.desktopL} {
        max-width: 1580px;
        margin: 0 auto;
        p {
            font-size: 20px;
        }
        .row {
            div {
                img {
                    min-width: 267px;
                    height: 267px;
                }
            }
        }
    }
`;

const services = [
    { name: 'Surgery', path: 'veterinary-services/surgery/' },
    { name: 'Vaccinations', path: 'veterinary-services/vaccinations/' },
    { name: 'Spay & Neuter', path: 'veterinary-services/spayneuter/' },
    { name: 'Blood & Eye Pressure', path: 'veterinary-services/blood-eye-pressure-test/' },
    { name: 'Ultrasound', path: 'veterinary-services/ultrasound/' },
    { name: 'Allergies', path: 'veterinary-services/allergies/' },
    { name: 'Digital Imaging', path: 'veterinary-services/digital-thermal-imaging/' },
    { name: 'EKG', path: 'veterinary-services/ekg/' },
    { name: 'Examinations', path: 'veterinary-services/examinations/' },
    { name: 'Dentistry', path: 'veterinary-services/dentistry/' },
    { name: 'In-House Laboratory', path: 'veterinary-services/in-house-laboratory/' },
    { name: 'Biopsy', path: 'veterinary-services/biopsy-tumor-removal/' },
    { name: 'Cryosurgery', path: 'veterinary-services/cryosurgery/' },
    {
        name: 'Soft Tissue & Orthopedic Surgery',
        path: 'veterinary-services/soft-tissue-orthopedic-surgery/',
    },
];

export const PictureSlide: React.FC = () => {
    return (
        <StyledPictureSlide>
            <h3>
                We’re Proud to <span>Offer</span>
            </h3>
            <h4 style={{ textAlign: 'center' }}>Veterinary Services of all Types</h4>
            <div className="row row-1">
                {services.map(service => (
                    <div key={service.name} className="item">
                        {/* <StaticImage
                            placeholder="none"
                            quality={90}
                            src="../images/march-groom.jpg"
                            alt="picture of a cat on exam room table"
                        /> */}
                        <Link to={`/${service.path}`}>
                            <p>{service.name}</p>
                        </Link>
                    </div>
                ))}
            </div>
            {/* <h4>General Medicine and Surgery</h4>
            <div className="row row-2">
                {services.map(service => (
                    <div key={service.name} className="item">
                        <StaticImage
                            placeholder="none"
                            quality={90}
                            src="../images/pictureslide/card1.jpeg"
                            alt="picture of a cat on exam room table"
                        />
                        <p>{service.title}</p>
                    </div>
                ))}
            </div>
            <h4>Special Services</h4>
            <div className="row row-3">
                {services.map(service => (
                    <div key={service.name} className="item">
                        <StaticImage
                            placeholder="none"
                            quality={90}
                            src="../images/pictureslide/card1.jpeg"
                            alt="picture of a cat on exam room table"
                        />
                        <p>{service.title}</p>
                    </div>
                ))}
            </div> */}
        </StyledPictureSlide>
    );
};
