import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { HomeHero } from '../components/HomeHero';
import { OurServices } from '../components/OurServices';
import { HomePanel } from '../components/HomePanel';
import { HomeHighlights } from '../components/HomeHighlights';
import { Reviews } from '../components/Reviews';
import { Seo } from '../components/seo';
import { Training } from '../components/Training';
import { HomeImages } from '../components/HomeImages';

const Home: Page = () => (
    <DefaultLayout home name="home">
        <Seo
            title="Veterinarian Clinic El Paso, TX"
            description="Country Club Veterinarian clinic in El Paso Texas offering wide range of services for different animals including dogs and cats since 1995"
        />
        <HomeHero />
        <OurServices />
        {/* <HomeImages /> */}
        <HomePanel />
        <HomeHighlights />
        <Training />
        <Reviews />
    </DefaultLayout>
);

export default Home;
