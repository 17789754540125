import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../layout/GlobalStyles';
import { Button } from '../layout/StyledComponents';

const StyledForm = styled.div`
    padding: 1em 1em 0em;
    fieldset {
        border: none;
        display: flex;
        padding: 0;
    }
    .desktop-flex {
        display: block;
    }

    .mid {
        width: 197px;
    }
    .checker {
        margin: 0.35em 0em;
        width: 20px;
        color: ${colors.black};
        height: 20px;
        border: none;
    }

    form {
        color: ${colors.black};
        display: flex;
        flex-direction: column;
        button {
            margin: 2em auto 4em;
            width: 90vw;
        }
    }

    .small {
        width: 107px;
    }
    h3 {
        padding-bottom: 1em;
        border-bottom: 1.5px solid #dee4e4;
        margin: 2.5em 0em 1em;
    }
    input {
        color: ${colors.black};
        text-indent: 0.35em;
        background: #ffffff;
        border: 1.5px solid #dee4e4;
        box-sizing: border-box;
        padding: 0.75em;
        border-radius: 5px;
    }

    select {
        background: linear-gradient(45deg, transparent 50%, ${colors.black} 50%),
            linear-gradient(135deg, ${colors.black} 50%, white 50%),
            linear-gradient(to right, white, white);
        background-position: calc(100% - 16px) calc(1em + 2px), calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        text-indent: 0.25em;
        padding: 0.75em;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
    }
    textarea {
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
        box-sizing: border-box;
    }
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    p,
    label {
        font-weight: 500;
    }
    h3 {
        font-size: 24px;
        color: ${colors.tealLight};
    }
    .section {
        margin: 0.75em 0em;
    }
    .item {
        align-items: center;
        display: flex;
        margin-right: 1em;
        label {
            padding-left: 0.3em;
        }
        input:nth-of-type(2) {
            margin-left: 1em;
        }
    }
    .row {
        display: flex;
    }
    textarea {
        min-height: 150px;
    }
    .section {
        display: flex;
        flex-direction: column;
        input,
        select,
        textarea {
            max-width: 500px;
        }
    }
    @media ${device.tablet} {
        .kieza {
            margin-right: 105px;
        }
        .small-bubbles {
            display: flex;

            width: 300px;
            justify-content: space-between;
        }
        .pet {
            min-width: 350px;
            display: flex;
            justify-content: space-between;
        }
        .full {
            min-width: 550px;
        }
        .bubbles {
            display: flex;
            min-width: 450px;
            justify-content: space-between;
        }
    }

    @media ${device.laptop} {
        .zoink {
            margin-bottom: 72px;
            position: absolute;
        }
        .sex {
            margin-left: 190px;
            position: relative;
            left: 15px;
        }
        .sex-p {
            position: relative;
            left: 205px;
        }
        .small-desktop-flex {
            width: 1000px;
            display: flex;
            justify-content: space-between;
        }
        .bubbles {
            min-width: 550px;
        }
        .full {
            min-width: 850px;
        }
        .payments {
            display: flex;
        }
        .lists {
            justify-content: space-between;
            width: 1000px;
            display: flex;
        }
        .lists-small {
            justify-content: start;
            width: 600px;
            display: flex;
        }
        button {
            max-width: 415px;
        }
        .third {
            width: 360px;
        }
        .second {
            min-width: 540px;
        }
        .small {
            width: 147px;
        }
        .city {
            margin-right: 4em;
        }
        .mid {
            min-width: 156px;
        }
        .desktop-flex {
            display: flex;
            justify-content: space-between;
        }
    }
`;

export const AvianForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledForm className="container">
            <form onSubmit={onSubmit}>
                <h3>Please enter your pet’s information</h3>
                <div className="section">
                    <p>
                        Owner's Name <span style={{ color: '#D63434' }}>*</span>{' '}
                    </p>
                    <input className="second" required id="name" type="text" name="name" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Name of Pet <span style={{ color: '#D63434' }}>*</span>{' '}
                        </p>
                        <input
                            className="second"
                            id="petname"
                            name="petname"
                            type="text"
                            required
                        />
                    </div>
                    <div className="section">
                        <p>
                            Species <span style={{ color: '#D63434' }}>*</span>
                        </p>
                        <input
                            className="second"
                            id="species"
                            name="species"
                            type="text"
                            required
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Age</p>
                        <input style={{ width: '360px' }} id="age" name="age" type="text" />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',

                            justifyContent: 'space-between',
                        }}
                    >
                        <p className="sex-p">
                            Sex <span style={{ marginRight: '1em', color: '#D63434' }}>*</span>
                        </p>
                        <div className="bubbles">
                            <fieldset className="sex">
                                <div className="item">
                                    <input type="radio" id="male" name="sex" value="male" />
                                    <label htmlFor="male">Male</label>
                                </div>
                                <div className="item">
                                    <input type="radio" value="female" id="female" name="sex" />
                                    <label htmlFor="female">Female</label>
                                </div>
                                <div className="item">
                                    <input type="radio" value="unknown" id="unknown" name="sex" />
                                    <label htmlFor="unknown">Unknown</label>
                                    <input type="text" id="neuter" name="sex" placeholder="" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>How was the Sex determined?</p>
                        <div className="bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="surgical"
                                        id="surgical"
                                        name="sexDetermined"
                                    />
                                    <label htmlFor="male">Surgically</label>
                                </div>
                                <div className="item">
                                    <input type="radio" value="dna" id="dna" name="sexDetermined" />
                                    <label htmlFor="dna">DNA</label>
                                </div>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="other"
                                        id="other"
                                        name="sexDetermined"
                                    />
                                    <label htmlFor="other">Other</label>
                                    <input type="text" id="otherinput" name="sex" placeholder="" />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '2em' }}>
                    <p>Identification</p>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Tattoo #</p>
                        <input className="third" id="tattoo" name="tattoo" type="text" />
                    </div>
                    <div className="section">
                        <p>Microchip #</p>
                        <input className="third" id="microchip" name="microchip" type="text" />
                    </div>
                    <div className="section">
                        <p>Band #</p>
                        <input className="third" id="band" name="band" type="text" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Is this bird a pet or is it a breeder?</p>
                        <div className="pet">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="petbreed"
                                        id="petbreed"
                                        name="petOrBreeder"
                                    />
                                    <label htmlFor="petbreed">Pet</label>
                                </div>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="breeder"
                                        id="breeder"
                                        name="petOrBreeder"
                                    />
                                    <label htmlFor="breeder">Breeder</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>
                        Has this bird produced young or eggs?{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Please describe)
                        </span>
                    </p>

                    <input id="employer" name="employer" type="text" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Where did you get your bird from?</p>
                        <div className="bubbles full">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        id="store"
                                        value="store"
                                        name="whereIsBirdFrom"
                                    />
                                    <label htmlFor="store">Store</label>
                                </div>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="private"
                                        id="private"
                                        name="whereIsBirdFrom"
                                    />
                                    <label htmlFor="private">Private Individual</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="other"
                                        type="radio"
                                        id="other"
                                        name="whereIsBirdFrom"
                                    />
                                    <label htmlFor="other">Other</label>
                                    <input
                                        type="text"
                                        id="othersourcetext"
                                        name="othersourcetext"
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>
                        When did you get your bird?{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>(mm/dd/yyyy)</span>
                    </p>
                    <input className="small" type="date" name="petDob" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Where is your bird kept?</p>
                        <div className="small-bubbles">
                            <fieldset>
                                <div className="item">
                                    <input type="radio" id="cage" name="birdKeptWhere" />
                                    <label htmlFor="cage">In a cage</label>
                                </div>
                                <div className="item">
                                    <input type="radio" id="housefree" name="birdKeptWhere" />
                                    <label htmlFor="housefree">Free in the house</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p>Is your bird an outside aviary or in the house?</p>
                        <div className="small-bubbles">
                            <fieldset>
                                <div className="item">
                                    <input type="radio" id="outside" name="outsideOrHouse" />
                                    <label htmlFor="outside">Outside</label>
                                </div>
                                <div className="kieza item">
                                    <input type="radio" id="inside" name="outsideOrHouse" />
                                    <label htmlFor="inside">Inside</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="section">
                    <p>Are there other birds in the same room? Or somewhere else in the house?</p>

                    <input id="otherbirds" name="otherbirds" type="text" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Are there any sick birds?</p>
                        <input className="second" id="sickbirds" name="sickbirds" type="text" />
                    </div>
                    <div className="section">
                        <p>Have any birds already died?</p>
                        <input className="second" id="deadbirds" name="deadbirds" type="email" />
                    </div>
                </div>
                <div className="section">
                    <p>
                        What signs have you noticed regarding this bird, this incident?{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Check all that apply)
                        </span>
                    </p>
                    <div className="lists">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="diarrhea"
                                    name="diarrhea"
                                    value="diarrhea"
                                />
                                <label htmlFor="diarrhea">Diarrhea</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="constipation"
                                    name="constipation"
                                    value="constipation"
                                />
                                <label htmlFor="constipation">Constipation</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="fainting"
                                    name="fainting"
                                    value="fainting"
                                />
                                <label htmlFor="fainting">Fainting</label>
                            </div>

                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="lameness"
                                    name="lameness"
                                    value="lameness"
                                />
                                <label htmlFor="lameness">Lameness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="itching"
                                    name="itching"
                                    value="itching"
                                />
                                <label htmlFor="itching">Itching</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="tailBobbing"
                                    name="tailBobbing"
                                    value="tailBobbing"
                                />
                                <label htmlFor="tailBobbing">Tail Bobbing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="fluffedFeathers"
                                    name="fluffedFeathers"
                                    value="fluffedFeathers"
                                />
                                <label htmlFor="fluffedFeathers">Fluffed Feathers</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="coughing"
                                    name="coughing"
                                    value="coughing"
                                />
                                <label htmlFor="coughing">Coughing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="otherSymptoms"
                                    name="otherSymptoms"
                                    value="otherSymptoms"
                                />
                                <label htmlFor="otherSymptoms">other</label>
                                <input name="otherSymptoms" type="text" placeholder="none" />
                            </div>
                        </div>{' '}
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="romaine"
                                    name="romaine"
                                    value="romaine"
                                />
                                <label htmlFor="blindness">Blindness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="breathing"
                                    name="breathing"
                                    value="breathing"
                                />
                                <label htmlFor="breathing">Breathing Difficulty</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="skinBleeding"
                                    name="skinBleeding"
                                    value="skinBleeding"
                                />
                                <label htmlFor="skinBleeding">Skin Bleeding</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="vomitting"
                                    name="vomitting"
                                    value="vomitting"
                                />
                                <label htmlFor="vomitting">Vomiting</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="perching"
                                    name="perching"
                                    value="perching"
                                />
                                <label htmlFor="perching">Perching Difficulty</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="featherloss"
                                    name="featherloss"
                                    value="featherloss"
                                />
                                <label htmlFor="featherloss">Feather Picking or Feather Loss</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="hoarseness"
                                    name="hoarseness"
                                    value="hoarseness"
                                />
                                <label htmlFor="hoarseness">Hoarseness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="personalityChange"
                                    name="personalityChange"
                                    value="personalityChange"
                                />
                                <label htmlFor="personalityChange">Change in Personality</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="limbBleeding"
                                    name="limbBleeding"
                                    value="limbBleeding"
                                />
                                <label htmlFor="limbBleeding">Eye/Nostril/Ear/Leg bleeding</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="bite"
                                    name="bite"
                                    value="bite"
                                />
                                <label htmlFor="weakness">
                                    Injury/Bitten by another Bird or Pet
                                </label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="breathing"
                                    name="breathing"
                                    value="breathing problems"
                                />
                                <label htmlFor="breathing">Breathing Problems</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="drooping"
                                    name="drooping"
                                    value="drooping"
                                />
                                <label htmlFor="drooping">Drooping or injured Wings or Legs</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="vocalizations"
                                    name="vocalizations"
                                    value="vocalizations"
                                />
                                <label htmlFor="drooping">Change in Vocalizations</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="stool"
                                    name="stool"
                                    value="stool"
                                />
                                <label htmlFor="stool">Change in stool Consistency</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="appetite"
                                    name="appetite"
                                    value="appetite"
                                />
                                <label htmlFor="stool">Change in Appetite</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="waterconsumption"
                                    name="waterconsumption"
                                    value="waterconsumption"
                                />
                                <label htmlFor="waterconsumption">
                                    Excessive Water Consumption
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>Has your bird received any treatment for this problem?</p>
                    <input id="previousTreatment" type="text" name="previousTreatment" />
                </div>
                <div className="section">
                    <p>
                        Has your bird ever been tested for parrot fever, beak and feather disease,
                        polyomavirus?
                    </p>
                    <input id="positiveTest" type="text" name="positiveTest" />
                </div>
                <div className="section">
                    <p>What toys are available to your bird?</p>
                    <input id="availableToys" type="text" name="availableToys" />
                </div>
                <div className="section">
                    <p>What do you use on the bottom of your cage?</p>
                    <input id="cageBottom" type="text" name="cageBottom" />
                </div>
                <div className="section">
                    <p>Do you use sand paper in your cage?</p>
                    <input id="sandpaperCage" type="text" name="sandpaperCage" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Do you use a bird protector?</p>
                        <input
                            className="second"
                            id="birdProtector"
                            type="text"
                            name="birdProtector"
                        />
                    </div>
                    <div className="section">
                        <p>Does your bird ever go outside?</p>
                        <input
                            className="second"
                            id="birdGoOutside"
                            type="text"
                            name="birdGoOutside"
                        />
                    </div>
                </div>
                <div className="section">
                    <p>What room of the house is your bird in?</p>
                    <input id="roomBirdInside" type="text" name="sandpaperCage" />
                </div>
                <div className="section">
                    <p>
                        How often do you change out the paper or litter at the bottom of the cage?
                    </p>
                    <input id="paperChange" type="text" name="sandpaperCage" />
                </div>
                <div className="section">
                    <p>
                        {`How often do you thoroughly clean your bird's cage and what method do yo use
                        to clean it?`}{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Please describe)
                        </span>
                    </p>
                    <textarea id="cageClean" />
                </div>
                <div className="section">
                    <p>How often do you clean your birds dishes and what do you clean them with?</p>
                    <input id="dishClean" type="text" name="dishClean" />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>How often do you offer fresh, clean water?</p>
                        <input className="second" id="cleanWater" type="text" name="cleanWater" />
                    </div>
                    <div className="section">
                        <p>Is it offered in a cup or a tube?</p>
                        <input className="second" id="drinkingCup" type="text" name="drinkingCup" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>How many hours of darkness does the bird have each day?</p>
                        <input
                            className="second"
                            id="hoursOfDarkness"
                            type="text"
                            name="hoursOfDarkness"
                        />
                    </div>
                    <div className="section">
                        <p>How often do you offer fresh food?</p>
                        <input className="second" id="freshFood" type="text" name="freshFood" />
                    </div>
                </div>
                <div className="section">
                    <p>
                        Does your bird eat pellets, seeds, or table foods?
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Please describe)
                        </span>
                    </p>
                    <textarea id="cageClean" />
                </div>
                <div className="section">
                    <p>
                        Does you bird eat the following foods often?
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Check all that apply)
                        </span>
                    </p>
                    <div className="lists-small">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="spinach"
                                    name="spinach"
                                    value="spinach"
                                />
                                <label htmlFor="spinach">Spinach</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="collards"
                                    name="collards"
                                    value="collards"
                                />
                                <label htmlFor="collards">Collards</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="turnipGreens"
                                    name="turnipGreens"
                                    value="turnipGreens"
                                />
                                <label htmlFor="turnipGreens">Turnip Greens</label>
                            </div>
                        </div>
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="romaine"
                                    name="romaine"
                                    value="romaine"
                                />
                                <label htmlFor="romaine">Romaine</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="fennel"
                                    name="fennel"
                                    value="fennel"
                                />
                                <label htmlFor="fennel">Fennel</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="lettuce"
                                    name="lettuce"
                                    value="lettuce"
                                />
                                <label htmlFor="lettuce">Lettuce</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="escarole"
                                    name="escarole"
                                    value="escarole"
                                />
                                <label htmlFor="escarole">Escarole</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>
                        Vegatables
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Check all that apply)
                        </span>
                    </p>
                    <div className="lists-small">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="carrots"
                                    name="carrots"
                                    value="carrots"
                                />
                                <label htmlFor="carrots">Carrots</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="zucchini"
                                    name="zucchini"
                                    value="zucchini"
                                />
                                <label htmlFor="zucchini">Zucchini</label>
                            </div>
                            <div className="zoink item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="otherVegetables"
                                    name="otherVegetables"
                                    value="otherVegetables"
                                />
                                <label htmlFor="otherVegetables">Other</label>
                                <input
                                    type="text"
                                    id="othersourcetext"
                                    name="othersourcetext"
                                    placeholder=""
                                />
                            </div>
                        </div>
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="broccoli"
                                    name="broccoli"
                                    value="broccoli"
                                />
                                <label htmlFor="broccoli">Broccoli</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="squash"
                                    name="squash"
                                    value="squash"
                                />
                                <label htmlFor="honeydew">Squash</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="sweetPotatoes"
                                    name="sweetPotatoes"
                                    value="sweetPotatoes"
                                />
                                <label htmlFor="mango">Sweet Potatoes</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="greenBeans"
                                    name="greenBeans"
                                    value="greenBeans"
                                />
                                <label htmlFor="greenBeans">Green Beans</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '72px' }} className="section">
                    <p>
                        Fruits
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Check all that apply)
                        </span>
                    </p>
                    <div className="lists-small">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="cantaloupe"
                                    name="cantaloupe"
                                    value="cantaloupe"
                                />
                                <label htmlFor="cantaloupe">Cantaloupe</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="papaya"
                                    name="papaya"
                                    value="papaya"
                                />
                                <label htmlFor="papaya">Papaya</label>
                            </div>
                            <div className="zoink item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="overVegetables"
                                    name="overVegetables"
                                    value="overVegetables"
                                />
                                <label htmlFor="overVegetables">Other</label>
                                <input
                                    type="text"
                                    id="othersourcetext"
                                    name="othersourcetext"
                                    placeholder=""
                                />
                            </div>
                        </div>
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="honeydew"
                                    name="honeydew"
                                    value="honeydew"
                                />
                                <label htmlFor="honeydew">Honeydew</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="mango"
                                    name="mango"
                                    value="mango"
                                />
                                <label htmlFor="mango">Mango</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '72px' }} className="section">
                    <p>Do you give any supplements and if so what kind?</p>
                    <input id="supplements" type="text" name="supplements" />
                </div>
                <div className="section">
                    <p>How much food does your bird eat a day?</p>
                    <input id="foodAmounts" type="text" name="foodAmounts" />
                </div>
                <div className="section">
                    <p>Has your bird had any recent diet changes?</p>
                    <input id="dietChanges" type="text" name="dietChanges" />
                </div>
                <div className="section">
                    <p>
                        Have there been any recent changes in your bird environment or in your home?
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Please describe)
                        </span>
                    </p>
                    <textarea id="environmentChange" />
                </div>
                <div className="section">
                    <p>Do you feed high salt foods to your bird?</p>
                    <input id="saltFeeding" type="text" name="saltFeeding" />
                </div>
                <div className="section">
                    <p>
                        Do you feed your bird any of the following foods?
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            {' '}
                            (Check all that apply)
                        </span>
                    </p>
                    <div className="lists-small">
                        <div className=" first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="chocolate"
                                    name="chocolate"
                                    value="chocolate"
                                />
                                <label htmlFor="chocolate">Chocolate</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="avocado"
                                    name="avocado"
                                    value="avocado"
                                />
                                <label htmlFor="avocado">Avocado</label>
                            </div>
                        </div>
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="milk"
                                    name="milk"
                                    value="milk"
                                />
                                <label htmlFor="milk">Milk</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="caffeine"
                                    name="caffeine"
                                    value="caffeine"
                                />
                                <label htmlFor="caffeine">Caffeine</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>Does anyone smoke in your house?</p>
                    <input id="smokeInHouSE" type="text" name="smokeInHouSE" />
                </div>
                <div className="section">
                    <p>Do you use any scent products in your home and if so what kind?</p>
                    <input id="scentProductsInHouse" type="text" name="scentProductsInHouse" />
                </div>
                {submitState === 'submitted' ? (
                    <>
                        <h3>
                            Appointment request sent! Somebody from our office will be in touch
                            shortly.
                        </h3>
                    </>
                ) : (
                    <Button disabled={submitState === 'submitting'} color="orange" type="submit">
                        {submitState === 'submitting'
                            ? 'Submitting appointment...'
                            : 'Submit Your Appointment'}
                    </Button>
                )}
            </form>
        </StyledForm>
    );
};
