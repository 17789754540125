import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledBlurb = styled.div<Props>`
    overflow-x: hidden;

    h2 {
        font-size: 40px;
        letter-spacing: -0.05em;
        color: ${colors.orange};
    }
    h3 {
        color: ${colors.blue};
        font-size: 1rem;
        line-height: 30px;
        margin: 0em 0em 1.5em;
    }
    .text {
        padding: 2em 0.75em;
        font-size: 16px;
    }
    p {
        line-height: 30px;
        margin: 0.5em 0em;
    }
    span {
        font-size: 22px;
        color: ${colors.blue};
        font-weight: 600;
    }
    img {
        width: 438px;
        height: auto;
        left: ${({ picture }) => (picture === 'left' ? '-5em' : '1em')};
        margin-bottom: 5em;
    }
    @media ${device.tablet} {
        padding: 2.75em 0em;
        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-height: 350px;
            padding: 0em;
        }
        img {
            left: 0em;
        }
    }

    @media ${device.laptop} {
        margin: 0em auto;
        display: flex;
        flex-direction: ${({ picture }) => (picture === 'left' ? 'row' : 'row-reverse')};
        justify-content: space-between;
        .text {
            width: 35vw;
        }
    }
`;

interface Props {
    picture: string;
}
export const Blurb: React.FC<Props> = ({ children, picture }) => {
    return (
        <>
            <StyledBlurb className="container" picture={picture}>
                {children}
            </StyledBlurb>
        </>
    );
};
