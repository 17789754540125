import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledTitle = styled.div`
    h1 {
        font-family: 'Poppins';
        font-size: 36px;
        margin: 0em 0.5em 1em;
        color: ${colors.tealLight};
    }
    span {
        color: ${colors.orange};
    }
    @media ${device.tablet} {
        padding-left: 4em;
    }
    @media ${device.desktop} {
        padding-left: 15em;
        h1 {
            font-family: 'Poppins';
            font-size: 38px;
        }
    }
    @media ${device.desktopL} {
        h1 {
            font-family: 'Poppins';
            font-size: 42px;
        }
    }
`;

export const Title: React.FC = ({ children }) => {
    return <StyledTitle>{children}</StyledTitle>;
};
