import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledFooter = styled.footer`
    background: ${colors.tealLight};
    color: white;
    padding: 1em;
    position: relative;
    a {
        color: ${colors.white};
    }
    .flowers {
        width: 117px;
        height: auto;
        position: absolute;
        right: 2em;
        top: -5em;
    }
    .lists {
        display: flex;
        justify-content: left;
        .second-list {
            padding-left: 9em;
        }
    }

    ul {
        padding-left: 0em;
    }
    .left-column {
        img {
            width: auto;
            height: 25px;
        }
        .section {
            padding: 1em 0em;
            padding-right: 0.5em;
        }
    }
    .social {
        display: flex;

        .google {
            margin-left: 5em;
        }
        img {
            width: auto;

            height: 25px;
        }
    }
    h3 {
        font-size: 36px;
        margin: 0.75em 0em 0em;
        text-align: left;
    }
    h4 {
        margin: 1em 0em;
    }
    ul {
        margin-left: 0em;
        list-style: none;
        li {
            margin: 1em 0em;
        }
    }
    @media ${device.laptop} {
        .left-column {
            .section {
                margin-bottom: 1em;
                div {
                    margin-right: 0.5em;
                }
                padding: 0.5em 0em 1em;
                padding-right: 0.5em;
            }
        }
        padding: 1em 8em;
        .flowers {
            left: 2em;
            top: -11em;
            width: 180px;
            height: auto;
        }
        ul {
            padding-left: 2.2em;
        }
        display: flex;
        padding-top: 3em;
        justify-content: space-around;
        .social {
        }
        h3 {
        }
        ul {
            margin-left: -2em;
        }
        h3 {
            text-align: left;
        }
    }
`;

export const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <StaticImage
                className="flowers"
                placeholder="none"
                src="../../images/flowers.png"
                alt="flowers in a pot"
            />
            <div className="left-column">
                <h3>Find Us</h3>
                <h4>Country Club Animal Clinic</h4>
                <div className="section">
                    <a
                        style={{ color: `${colors.white}` }}
                        rel="noreferrer"
                        href="https://www.google.com/maps/place/Country+Club+Animal+Clinic/@31.8576926,-106.5880203,17z/data=!3m2!4b1!5s0x86ddf91c39a84161:0xfc6b4247f5450b1d!4m5!3m4!1s0x86ddf91c39da8e7f:0xbb95bb033c28473!8m2!3d31.8576926!4d-106.5858316"
                        target="_blank"
                    >
                        {' '}
                        <StaticImage
                            placeholder="none"
                            src="../../images/svgs/footer-map.svg"
                            alt="map marker"
                        />
                        5470 Doniphan Dr # A2, El Paso, TX 79932
                    </a>
                </div>
                <div className="section">
                    <a
                        style={{ color: `${colors.white}` }}
                        href="mailto:countryclubanimalclinicep@gmail.com"
                    >
                        <StaticImage
                            placeholder="none"
                            src="../../images/svgs/footer-email.svg"
                            alt="email symbol"
                        />
                        countryclubanimalclinicep@gmail.com
                    </a>
                </div>
                <div className="section">
                    <a style={{ color: `${colors.white}` }} href="tel:9158330645">
                        <StaticImage
                            placeholder="none"
                            src="../../images/svgs/footer-mobile.svg"
                            alt="mobile icon"
                        />
                        +1 (915) 833-0645
                    </a>
                </div>
                <div className="social">
                    <a
                        href="https://www.facebook.com/countryclubanimalclinicep/"
                        rel="noreferrer"
                        target="__blank"
                    >
                        <StaticImage
                            placeholder="none"
                            src="../../images/svgs/facebook.svg"
                            alt="facebook logo"
                        />
                    </a>
                    <a
                        href="https://www.google.com/search?q=couhtry+club+animal+clinc+el+pasdo+google+reibvew&sxsrf=AOaemvIKiRnquzwViJO80cScnWjDqCvmKg%3A1641921703532&source=hp&ei=p7zdYZ_UHcWZ_QaqspWAAg&iflsig=ALs-wAMAAAAAYd3Kt0Bccpk7EzROmS_UL9lSL1OsAeWi&ved=0ahUKEwjfw4r_mqr1AhXFTN8KHSpZBSAQ4dUDCAk&uact=5&oq=couhtry+club+animal+clinc+el+pasdo+google+reibvew&gs_lcp=Cgdnd3Mtd2l6EAMyBwghEAoQoAEyBwghEAoQoAEyBwghEAoQoAE6BAgjECc6BQgAEJECOgoILhDHARCvARBDOgsILhCABBDHARDRAzoECAAQQzoICAAQgAQQsQM6EQguEIAEELEDEIMBEMcBENEDOgoIABCxAxCDARBDOg4ILhCABBCxAxDHARCjAjoLCC4QgAQQsQMQgwE6BAguEEM6BwgAEMkDEEM6BwgjELECECc6CgguEMcBEK8BEAo6BwgAELEDEAo6CggAELEDEIMBEAo6BQgAELEDOgoILhDHARDRAxAKOgoIABCxAxDJAxAKOgUIABCSAzoHCCMQsAIQJzoECAAQDToKCC4QxwEQrwEQDToHCAAQyQMQDToNCC4QsQMQxwEQrwEQDToHCAAQsQMQDToKCAAQsQMQyQMQDToGCAAQFhAeOgYIABANEB46BQghEKsCUABY7CRgnyVoAHAAeAWAAf0CiAGrPZIBCDAuNDAuNC4ymAEAoAEB&sclient=gws-wiz#lrd=0x86ddf91c39da8e7f:0xbb95bb033c28473,1,,,"
                        rel="noreferrer"
                        target="__blank"
                    >
                        <StaticImage
                            className="google"
                            placeholder="none"
                            src="../../images/svgs/google.svg"
                            alt="google logo"
                        />
                    </a>
                </div>
            </div>
            <div className="right-column">
                <h3>Navigation</h3>
                <div className="lists">
                    <ul>
                        {/*<li>About Us</li>*/}
                        <li style={{ fontWeight: '400', color: 'white' }}>
                            {' '}
                            <Link to="veterinary-services/">Services </Link>
                        </li>

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            <Link to="/about-us/">About Us </Link>
                        </li>

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            {' '}
                            <Link to="/contact-us/">Contact Us </Link>{' '}
                        </li>

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            <Link to="/new-clients/">New Clients</Link>
                        </li>

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            {' '}
                            <Link to="/specials/">Specials </Link>
                        </li>
                    </ul>
                    <ul className="second-list">
                        {/*<li>About Us</li>*/}

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            {' '}
                            <Link to="/grooming/">Dog Grooming</Link>
                        </li>

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            {' '}
                            <Link to="/veterinary-services/doggy-daycare/">Doggy Daycare </Link>
                        </li>

                        <li style={{ fontWeight: '400', color: 'white' }}>
                            {' '}
                            <Link to="/applications/">Employment </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </StyledFooter>
    );
};
