import React from 'react';
import { Link } from 'gatsby';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';

const StyledHomeHero = styled.section`
    display: flex;
    flex-direction: column-reverse;
    .bottom-button {
    }
    .buttons {
        display: flex;
        flex-direction: column;
        width: 340px;
        align-items: center;
        justify-content: space-between;
        margin: 16px auto 16px;
        button {
            width: 340px;
            margin-bottom: 16px;
        }
    }
    .buttons-bottom {
        display: flex;
        margin: 0px auto 16px;
        align-items: center;
        width: 340px;
        justify-content: space-between;
        button {
            width: 160px;
        }
    }
    .left-column {
        padding: 1em 1em 2em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    h1 {
        margin-bottom: 0.25em;
    }
    p {
        color: ${colors.grey};
        margin-bottom: 1em;
    }
    .right-column {
        height: 85vh;
        padding: 8em 1em 2em;
        position: relative;
        background: ${colors.tealLight};
        .mobile-bubble,
        .marker-bubble {
            display: flex;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(4px);
            padding: 1em 1.5em;
            align-items: center;
        }
        .dog {
            min-width: 377px;
            height: auto;
        }
        .marker-bubble {
            margin-top: 15em;
            width: 350px;
            border-radius: 32px 32px 32px 0px;
        }
        .mobile-bubble {
            width: 230px;
            margin-top: 1em;
            border-radius: 32px 32px 0px 32px;
        }
        a {
            margin-left: 1em;
            font-weight: 600;
        }
        .dog {
            position: absolute;
            width: 350px;
            height: auto;
            bottom: 0em;
            right: -4em;
        }
    }
    @media ${device.tablet} {
        flex-direction: row;
        .buttons {
            position: relative;
            right: 00px;
        }
        .buttons,
        .buttons-bottom {
            width: 318px;
            button {
                max-width: 318px;
                margin-right: 0em;
            }
        }
        .buttons-bottom {
            button {
                width: 155px;
            }
        }

        .left-column {
            padding-left: 3em;
            display: flex;
            justify-content: center;
            width: 50%;
            padding-top: 4em;
            padding-right: 4em;
            margin: 0 auto;

            p {
                padding-left: 0em;
            }
        }
        .right-column {
            .mobile-bubble {
                margin-top: 2em;
            }
            .marker-bubble {
                width: 286px;
                margin-left: 3em;
                margin-top: 5em;
            }
            .dog {
                min-width: 420px;
                height: auto;

                margin-right: 2em;
            }
            width: 50vw;
        }
    }
    @media ${device.laptop} {
        flex-direction: row;
        padding-top: 120px;
        position: relative;
        .buttons-bottom {
            button {
                width: 205px;
            }
        }
        .buttons {
            button {
                width: 440px;
            }
        }
        .buttons,
        .buttons-bottom {
            position: relative;
            right: 8px;
            width: 440px;
            button {
                max-width: 100%;
                margin-right: 0em;
            }
        }
        .right-column {
            .dog {
                min-width: 520px;
                height: auto;

                margin-right: 4em;
            }
            width: 50vw;
        }
        .left-column {
            padding-right: 9em;
            display: flex;

            p {
                padding-right: 3.8em;
            }
        }
        .right-column {
            height: 100vh;
            min-height: 500px;
            max-height: 800px;
            width: 45.5vw;
            .marker-bubble,
            .mobile-bubble {
                padding: 1.3em 2em;
                margin-left: 5em;
            }
            .mobile-bubble {
                width: 246px;
                margin-top: 5em;
            }
            .marker-bubble {
                margin-top: 18em;
                width: 400px;
                margin-left: 10em;
            }
        }
    }
    @media ${device.laptop} {
        flex-direction: row;
    }
    @media ${device.laptopL} {
        padding-top: 20px;
    }

    @media ${device.desktop} {
        .buttons,
        .buttons-bottom {
            position: relative;
            right: 100px;
            top: 50px;
        }
        .left-column {
            h2 {
                max-width: 10px;
            }
        }
    }
    @media ${device.desktopL} {
    }
`;

export const HomeHero: React.FC = () => {
    return (
        <StyledHomeHero>
            <div className="left-column">
                <h1>Veterinary Clinic in El Paso Texas</h1>
                <p>
                    Country Club Veterinarian Clinic in El Paso Texas offering wide range of
                    services for different animals including dogs and cats since 1995.
                </p>
                <div className="buttons">
                    <a
                        href="https://my.vitusvet.com/Appointments/schedule/e4b4c14a-e45b-4e24-986e-e9c27f19991a"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button color="blue">Request an Appointment</Button>
                    </a>
                    <a
                        href="https://my.vitusvet.com/Prescription/Refill/e4b4c14a-e45b-4e24-986e-e9c27f19991a"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button color="orange">Request a Refill</Button>
                    </a>
                </div>
                <div className="buttons-bottom">
                    <a href="https://vitusvet.com/pet-owners/" target="_blank" rel="noreferrer">
                        <Button className="bottom-button">Download App</Button>
                    </a>
                    <a
                        target="__blank"
                        rel="noreferrer"
                        href="https://countryclubanimalclinic2.securevetsource.com/site/view/102582_HomeDelivery.pml?retUrl=www.countryclubanimalclinic.net"
                    >
                        <Button className="bottom-button">Online Pharmacy</Button>
                    </a>
                </div>
            </div>
            <div className="right-column">
                <StaticImage
                    placeholder="none"
                    className="dog"
                    src="../images/dog.png"
                    alt="dog lifting its paw"
                />
                <div className="mobile-bubble">
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/phone.svg"
                        alt="mobile phone icon"
                    />
                    <a rel="noreferrer" href="tel:9158330645">
                        (915) 833-0645
                    </a>
                </div>
                <div className="marker-bubble">
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/map-marker.svg"
                        alt="map marker icon"
                    />
                    <a
                        rel="noreferrer"
                        href="https://www.google.com/maps/place/Country+Club+Animal+Clinic/@31.8576926,-106.5880203,17z/data=!3m2!4b1!5s0x86ddf91c39a84161:0xfc6b4247f5450b1d!4m5!3m4!1s0x86ddf91c39da8e7f:0xbb95bb033c28473!8m2!3d31.8576926!4d-106.5858316"
                        target="_blank"
                    >
                        5470 Doniphan Dr # A2 <br />
                        El Paso, Tx 79932
                    </a>
                </div>
            </div>
        </StyledHomeHero>
    );
};
