import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';

const StyledBreadCrumbs = styled.div<BreadCrumbProps>`
    padding: 8em 1em 3em;
    display: ${({ home }) => (home ? 'none' : 'block')};
    h1 {
        font-family: 'Poppins';
        font-size: 40px;
        color: ${colors.tealLight};
    }
    span {
        color: ${colors.orange};
    }
    ul {
        display: flex;
        list-style: none;
        justify-content: flex-start;

        .second-item {
            padding-left: 1em;
            margin-left: 1em;
            border-left: 2px solid black;
            font-weight: bold;
        }
    }
    @media ${device.tablet} {
        padding: 10em 0em 2em 5.2em;
    }
    @media ${device.desktop} {
        padding: 10em 0em 2em 16.5em;
    }
`;

interface Props {
    home?: boolean;
    name: string;
    prevName?: string;
    path?: string;
}

interface BreadCrumbProps {
    home?: boolean;
}

export const BreadCrumbs: React.FC<Props> = ({ home, path, prevName, name }) => {
    return (
        <StyledBreadCrumbs home={home}>
            {home ? (
                <div></div>
            ) : (
                <ul>
                    <Link to="/">
                        <li>Home</li>
                    </Link>
                    {prevName && (
                        <Link to={`/${path}`}>
                            <li style={{ fontWeight: '500' }} className="second-item">
                                {prevName}
                            </li>
                        </Link>
                    )}
                    <li className="second-item">{name}</li>
                </ul>
            )}
        </StyledBreadCrumbs>
    );
};
