import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../layout/GlobalStyles';
import { Button } from '../layout/StyledComponents';

const StyledForm = styled.div`
    padding: 1em 1em 0em;
    fieldset {
        border: none;
        display: flex;
        padding: 0;
    }
    .how-did {
        width: 340px;
    }
    .last-section {
        margin-top: 64px;
        padding-top: 32px;
        border-top: 2px solid #d0dcdd;
    }
    .section {
        p {
            margin-bottom: 6px;
        }
    }
    .desktop-flex {
        display: block;
    }

    .mid {
        width: 197px;
    }
    .checker {
        margin: 0.35em 0em;
        width: 20px;
        color: ${colors.black};
        height: 20px;
        border: none;
    }

    form {
        color: ${colors.black};
        display: flex;
        flex-direction: column;
        button {
            margin: 2em auto 4em;
            width: 90vw;
        }
    }

    .small {
        width: 107px;
    }
    h3 {
        padding-bottom: 1em;
        border-bottom: 1.5px solid #dee4e4;
        margin: 2.5em 0em 1em;
    }
    input {
        color: ${colors.black};
        text-indent: 0.35em;
        background: #ffffff;
        border: 1.5px solid #dee4e4;
        box-sizing: border-box;
        padding: 0.75em;
        border-radius: 5px;
    }

    select {
        background: linear-gradient(45deg, transparent 50%, ${colors.black} 50%),
            linear-gradient(135deg, ${colors.black} 50%, white 50%),
            linear-gradient(to right, white, white);
        background-position: calc(100% - 16px) calc(1em + 2px), calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        text-indent: 0.25em;
        padding: 0.75em;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
    }
    textarea {
        padding: 10px 0 0 10px;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
        box-sizing: border-box;
    }
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    label {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    p,
    label {
        font-weight: 500;
    }
    h3 {
        font-size: 24px;
        color: ${colors.tealLight};
    }
    .section {
        margin: 0.75em 0em;
    }
    .item {
        align-items: center;
        display: flex;
        margin-right: 1em;
        label {
            padding-left: 0.3em;
        }
        input:nth-of-type(2) {
            margin-left: 1em;
        }
    }
    .row {
        display: flex;
    }
    textarea {
        min-height: 150px;
    }
    .section {
        display: flex;
        flex-direction: column;
        input,
        select,
        textarea {
            max-width: 500px;
        }
    }
    @media ${device.tablet} {
        .pet {
            min-width: 350px;
            display: flex;
            justify-content: space-between;
        }
        .full {
            min-width: 550px;
        }
        .small-bubbles {
            display: flex;
            min-width: 70px;
            max-width: 170px;
            justify-content: space-between;
        }
        .bubbles {
            display: flex;
            min-width: 450px;
            justify-content: space-between;
        }
    }

    @media ${device.laptop} {
        .game-input {
            min-width: 410px;
        }
        .nu-game-input {
            min-width: 470px;
        }
        .push-right {
            margin-left: 62px;
            label {
                margin-right: 32px;
            }
            input {
                width: 290px;
            }
        }
        .household {
            .inp {
                width: 300px;
                margin-top: 15px;
            }
        }
        .opt {
            label {
            }
            input {
                margin-top: 35px;
            }
        }
        .how-did {
            min-width: 1135px;
        }

        .bubbles {
            min-width: 550px;
        }
        .full {
            min-width: 850px;
        }
        .payments {
            display: flex;
        }
        .lists {
            justify-content: space-between;
            width: 1000px;
            display: flex;
        }
        button {
            max-width: 415px;
        }
        .third {
            width: 360px;
        }
        .second {
            min-width: 540px;
        }
        .small {
            width: 147px;
        }
        .city {
            margin-right: 4em;
        }
        .mid {
            min-width: 156px;
        }
        .small-desktop-flex {
            width: 1000px;
            display: flex;
            justify-content: space-between;
        }
        .desktop-flex {
            display: flex;
            justify-content: space-between;
        }
    }
`;

const Dailyrates = styled.div`
    margin-top: 32px;
    .initial-input {
        max-width: 90px;
    }
    .initial-sec {
        justify-self: end;
    }
    .initial-section {
        display: block;
        align-items: center;
        justify-content: space-between;
    }
    > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 370px;
        margin: 12px 0px;
        .line {
            background: lightgray;
            width: 25%;
            margin: 0px 16px;
            height: 2px;
        }
    }
    @media ${device.laptop} {
        > div {
            position: relative;
            align-items: center;
            display: flex;
            justify-content: start;
            width: 100%;
            .price {
                position: absolute;
                right: 300px;
                justify-self: end;
            }
            .line {
                width: 220px;
                position: absolute;
                left: 275px;
                justify-self: start;
            }
        }
        .initial-sec {
            justify-self: end;
            label {
                margin-right: 16px;
            }
        }

        .initial-section {
            .line {
                width: 270px;
                position: absolute;
                left: 340px;
            }
            .second-price {
                position: absolute;
                right: 300px;
            }
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

export const BoardingIntakeForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        
        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledForm className="container">
            <form onSubmit={onSubmit}>
                <h3>Please enter your pet’s information</h3>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Owner's Name <span style={{ color: '#D63434' }}>*</span>{' '}
                        </p>
                        <input
                            className="second"
                            required
                            id="ownersName"
                            name="ownersName"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Pet's Name</p>
                        <input className="second" id="petsName" name="petsName" type="text" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Address</p>
                        <input
                            className="how-did"
                            id="how-did-you-hear-about-us"
                            name="how-did-you-hear-about-us"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Date of Admission{' '}
                            <span style={{ fontSize: '.7em', color: '#686F6F' }}>(mm/dd/yyyy)</span>
                        </p>
                        <input
                            className="second"
                            id="admissionDate"
                            name="admissionDate"
                            type="date"
                        />
                    </div>
                    <div className="section">
                        <p>Time</p>
                        <input
                            className="second"
                            id="timeAdmission"
                            name="timeAdmission"
                            type="time"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Discharge Date{' '}
                            <span style={{ fontSize: '.7em', color: '#686F6F' }}>(mm/dd/yyyy)</span>
                        </p>
                        <input
                            className="second"
                            id="dischargeDate"
                            name="dischargeDate"
                            type="date"
                        />
                    </div>
                    <div className="section">
                        <p>Time</p>
                        <input
                            className="second"
                            id="timeDischarge"
                            name="timeDischarge"
                            type="time"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Home Phone</p>
                        <input className="second" id="ownersName" name="ownersName" type="tel" />
                    </div>
                    <div className="section">
                        <p>Business Phone</p>
                        <input className="second" id="petsName" name="petsName" type="tel" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Pet’s Breed & Color</p>
                        <input
                            className="second"
                            id="petBreedColor"
                            name="petBreedColor"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Item’s Left with Pet</p>
                        <input
                            className="second"
                            id="itemsWithPet"
                            name="itemsWithPet"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Food Brought In</p>
                        <input
                            className="second"
                            id="foodBroughtIn"
                            name="foodBroughtIn"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Any Food Allergies</p>
                        <input
                            className="second"
                            id="foodAllergies"
                            name="foodAllergies"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>To the best of your knowledge, is your pet ok in heat?</p>
                        <div className="small-bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="yes"
                                        id="okInHeatYes"
                                        name="okInHeat"
                                    />
                                    <label htmlFor="okInHeatYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        type="radio"
                                        value="no"
                                        id="okInHeatNo"
                                        name="okInHeat"
                                    />
                                    <label htmlFor="okInHeatNo">No</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p>Is your dog on flea/tick prevention?</p>
                        <div className="small-bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="yes"
                                        type="radio"
                                        id="fleaTickPreventionYes"
                                        name="fleaTickPrevention"
                                    />
                                    <label htmlFor="fleaTickPreventionYes">Yes</label>
                                </div>
                                <div className="item">
                                    <input
                                        value="no"
                                        type="radio"
                                        id="fleaTickPreventionNo"
                                        name="fleaTickPrevention"
                                    />
                                    <label htmlFor="fleaTickPreventionNo">No</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <div className="desktop-flex">
                    <div className="section">
                        <p>If no, which do you prefer</p>
                        <div className="small-bubbles">
                            <fieldset>
                                <div className="item">
                                    <input
                                        value="chewable"
                                        type="radio"
                                        id="chewablePrefferedTickPrevention"
                                        name="topicalPreffered"
                                    />
                                    <label htmlFor="chewablePrefferedTickPrevention">
                                        Chewable
                                    </label>
                                </div>
                                <div className="item">
                                    <input
                                        value="topical"
                                        type="radio"
                                        id="topicalPrefferedTickPrevention"
                                        name="topicalPreffered"
                                    />
                                    <label htmlFor="topicalPrefferedTickPrevention">Topical</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>{' '}
                <p>
                    For your pet’s protection, all vaccines must be current, including: Rabies, DHPP
                    and Bordetella. Puppies must have finished their series prior to boarding. Your
                    pet must be on a flea & tick preventative, otherwise it will be provided at the
                    owner’s expense. Country Club Animal Clinic is not responsible for any
                    belongings left with your pet. If your dog is in heat, they will be kept in
                    their suite, other than being taken out to use the bathroom. You allow CCAC to
                    take photos and videos of your pet and post them on social media. When you take
                    your pet home your pet may be unusually excited or anxious and possibly have
                    some diarrhea. Your pet may also experience some short term behavior changes and
                    loss of appetite. This is all normal. We do recommend that you schedule a bath;
                    brush and nail trim before picking up your pet.
                </p>
                <p style={{ marginTop: '32px' }}>
                    <b>Days & Hours for Pickup & Drop-Off:</b> Monday – Friday 8:00am – 6:00pm;
                    Saturday & Sunday 9:00am-11:00am or 2:00pm-4:00pm. There is a $1.00 per minute
                    charge for any pet not picked up by closing time at 6:00pm, Mon-Fri and 4:00pm
                    on Saturday & Sunday.  Any dog not picked up within 15 minutes of closing time
                    will have to be picked up the next day and will incur an additional 1 day
                    boarding fee at the current boarding rate plus a charge for dog food, if
                    provided by CCAC.
                </p>
                <Dailyrates>
                    <h4>Daily Rates:</h4>
                    <div>
                        <p>1 dog, 1 night</p>
                        <div className="line"></div>
                        <p className="price">$45.00/night, for the first 3 nights</p>
                    </div>
                    <div>
                        <p>1 dog, 1 night, (after 3 nights)</p>
                        <div className="line"></div>
                        <p className="price">$30.00/night, after 3 nights</p>
                    </div>
                    <div>
                        <p>2 dogs, 1 night</p>
                        <div className="line"></div>
                        <p className="price">$65.00/night for the first 3 nights</p>
                    </div>
                    <div>
                        <p>2 dogs, 1 night (after 3 nights)</p>
                        <div className="line"></div>
                        <p className="price">$50.00/night, after 3 nights</p>
                    </div>
                    <h4 style={{ marginTop: '32px' }}>
                        If no, which do you prefer{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (discount included for boarding with us)
                        </span>
                    </h4>
                    <div className="initial-section">
                        <p>Bath, Brush and Nail Trim</p> <div className="line"></div>
                        <p className="second-price">$28.00 - $40.00</p>
                        <div className="initial-sec">
                            <label htmlFor="bathNailTrimInitial">Owner's Initial:</label>
                            <input
                                className="initial-input"
                                type="text"
                                id="bathNailTrimInitial"
                                name="bathNailTrimInitial"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className="initial-section">
                        <p>Nail Trim</p> <div className="line"></div>
                        <p className="second-price">$15.00 - $20.00</p>
                        <div className="initial-sec">
                            <label htmlFor="nailTrimInitial">Owner's Initial:</label>
                            <input
                                className="initial-input"
                                type="text"
                                id="nailTrimInitial"
                                name="nailTrimInitial"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className="initial-section">
                        <p>Webcam access with treats included</p> <div className="line"></div>
                        <p className="second-price">$10.00</p>
                        <div className="initial-sec">
                            <label htmlFor="webcamAccessWithTreatsInitial">Owner's Initial:</label>
                            <input
                                className="initial-input"
                                type="text"
                                id="webcamAccessWithTreatsInitial"
                                name="webcamAccessWithTreatsInitial"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <p style={{ fontSize: '1em', color: '#686F6F' }}>
                        *You will have access to our Pet Chatz camera system to talk to your pet and
                        offer treats.
                    </p>
                    <div className="initial-section">
                        <p>Webcam access with treats included</p> <div className="line"></div>
                        <p className="second-price">$10.00/day/dog</p>
                        <div className="initial-sec">
                            <label htmlFor="nailTrimInitial">Owner's Initial:</label>
                            <input
                                className="initial-input"
                                type="text"
                                id="nailTrimInitial"
                                name="nailTrimInitial"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className="initial-section">
                        <p>Dog Food Provided by Resort</p> <div className="line"></div>
                        <p style={{ lineHeight: '22px' }} className="second-price">
                            $37.52 - $48.76 <br />
                            for 6-7# bag
                        </p>
                        <div className="initial-sec">
                            <label htmlFor="dogFoodProvidedByResortInitial">Owner's Initial:</label>
                            <input
                                className="initial-input"
                                type="text"
                                id="dogFoodProvidedByResortInitial"
                                name="dogFoodProvidedByResortInitial"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <p style={{ marginTop: '16px', fontSize: '1em', color: '#686F6F' }}>
                        <b>IF</b> your dog runs out of food while boarding with us we will provide
                        them Royal Canin GI Low Fat or Hill’s i/d low fat; or Royal Canin HP for
                        dogs with allergies.
                    </p>
                    <p style={{ fontSize: '1em', color: '#686F6F' }}>
                        <b>IF</b> your dog is not eating we will top their food with Royal Canin GI
                        Low Fat or Hill’s i/D Low Fat canned food to stimulate their appetite, which
                        is $3.75/can. Owners will be responsible for the cost offood provided.
                    </p>
                </Dailyrates>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Name of Medication</p>
                        <input
                            className="second"
                            id="medicationName"
                            name="medicationName"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Dose</p>
                        <input
                            className="second"
                            id="medicationDose"
                            name="medicationDose"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <input
                            className="second"
                            id="medicationName"
                            name="medicationName"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <input
                            className="second"
                            id="medicationDose"
                            name="medicationDose"
                            type="text"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <input
                            className="second"
                            id="medicationName"
                            name="medicationName"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <input
                            className="second"
                            id="medicationDose"
                            name="medicationDose"
                            type="text"
                        />
                    </div>
                </div>
                <p
                    style={{
                        marginTop: '54px',
                        borderTop: '2px solid lightgray',
                        paddingTop: '16px',
                    }}
                >
                    This information is accurate to the best of my knowledge. I understand that
                    Country Club Spa &amp; Resort will make decisions that affect the care and
                    safety of my dog, other dogs, and the staff based on this information, and that
                    it is my responsibility to update the information as I am aware of changes.
                </p>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Name of Your Veterinarian:</p>
                        <input
                            className="second"
                            id="veterinarianName"
                            name="veterinarianName"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Phone Number</p>
                        <input
                            className="second"
                            id="veterinarianNumber"
                            name="veterinarianNumber"
                            type="tel"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Emergency Contact</p>
                        <input
                            className="second"
                            id="emergencyContact"
                            name="emergencyContact"
                            type="text"
                        />
                    </div>
                    <div className="section">
                        <p>Phone number</p>
                        <input
                            className="second"
                            id="emergencyPhone"
                            name="emergencyPhone"
                            type="tel"
                        />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Owner’s Signature <span style={{ color: '#D63434' }}>*</span>{' '}
                        </p>
                        <input
                            className="second"
                            id="ownersSignature"
                            name="ownersSignature"
                            type="text"
                            required
                        />
                    </div>
                    <div className="section">
                        <p>
                            Date <span style={{ color: '#D63434' }}>*</span>{' '}
                        </p>
                        <input
                            required
                            className="second"
                            id="dateSignature"
                            name="dateSignature"
                            type="date"
                        />
                    </div>
                </div>
                {submitState === 'submitted' ? (
                    <>
                        <h3>
                            Appointment request sent! Somebody from our office will be in touch
                            shortly.
                        </h3>
                    </>
                ) : (
                    <Button disabled={submitState === 'submitting'} color="orange" type="submit">
                        {submitState === 'submitting'
                            ? 'Submitting appointment...'
                            : 'Submit Your Appointment'}
                    </Button>
                )}
            </form>
        </StyledForm>
    );
};
