import React from 'react';
import { device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledPayments = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5em 0em 5.5em;
    h2 {
        text-align: center;
    }
    .payments {
        margin-top: 2.5em;
        img {
            width: 95%;
            height: auto;
            margin: 1.5em 0em;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media ${device.tablet} {
        padding: 2em 0em 14em;
        .payments {
            flex-direction: row;
            justify-content: space-between;
            width: 80vw;

            img {
                width: 85%;
            }
        }
        h2 {
            margin: 0.5em 0em;
        }
    }
`;

export const Payments: React.FC = () => {
    return (
        <StyledPayments>
            <h2>We Accept Payments With</h2>
            <div className="payments">
                <StaticImage src="../images/visa.jpg" alt="visa logo" />
                <StaticImage src="../images/mastercard.jpg" alt="mastercard logo" />
                <StaticImage src="../images/american-express.jpg" alt="american express logo" />
                <StaticImage src="../images/discover.jpg" alt="discover logo" />
                <StaticImage src="../images/care-credit.jpg" alt="care credit logo" />
                <StaticImage src="../images/cash.jpg" alt="cash symbol" />
            </div>
        </StyledPayments>
    );
};
