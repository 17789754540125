/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './StyledComponents';
import { Link } from 'gatsby';
import React, { useState, useEffect, useCallback } from 'react';
import { colors, device } from './GlobalStyles';

const navMenu = [
    {
        name: 'About Us',
        path: 'about-us/',
        menu: [
            { name: 'Our Doctors', path: 'about-us/our-doctors/' },
            { name: 'Our Team', path: 'about-us/our-veterinary-team/' },
            { name: 'Virtual Tour', path: 'about-us/virtual-tour/' },
            { name: 'Pet of the Month', path: 'about-us/pet-of-the-month/' },
        ],
    },
    {
        name: 'Services',
        path: 'veterinary-services/',
        menu: [
            { name: 'Surgery', path: 'veterinary-services/surgery/' },
            { name: 'Vaccinations', path: 'veterinary-services/vaccinations/' },
            { name: 'Spay & Neuter', path: 'veterinary-services/spayneuter/' },
            { name: 'Blood & Eye Pressure', path: 'veterinary-services/blood-eye-pressure-test/' },
            { name: 'Ultrasound', path: 'veterinary-services/ultrasound/' },
            { name: 'Allergies', path: 'veterinary-services/allergies/' },
            { name: 'Digital Imaging', path: 'veterinary-services/digital-thermal-imaging/' },
            { name: 'EKG', path: 'veterinary-services/ekg/' },
            { name: 'Examinations', path: 'veterinary-services/examinations/' },
            { name: 'Dentistry', path: 'veterinary-services/dentistry/' },
            { name: 'In-House Laboratory', path: 'veterinary-services/in-house-laboratory/' },
            { name: 'Biopsy', path: 'veterinary-services/biopsy-tumor-removal/' },
            { name: 'Cryosurgery', path: 'veterinary-services/cryosurgery/' },
            {
                name: 'Soft Tissue & Orthopedic Surgery',
                path: 'veterinary-services/soft-tissue-orthopedic-surgery/',
            },
        ],
    },
    {
        name: 'New Clients',
        path: 'new-clients/',
        menu: [
            { name: 'Specials', path: 'specials/' },
            { name: 'Appointments', path: 'new-clients/appointments/' },
            { name: 'Pet Insurance', path: 'pet-insurance/' },
            {
                name: 'Online Forms',
                menu: [
                    {
                        name: 'Small Mammal Questionnaire',
                        path: 'new-clients/small-mammal-questionnaire',
                    },
                    { name: 'Reptile Questionnaire', path: 'new-clients/reptile-questionnaire' },
                    { name: 'Avian Questionnaire', path: 'new-clients/avian-questionnaire' },
                    {
                        name: 'CCAC Spa & Resort Pet Personality Questionnaire',
                        path: 'new-clients/spa-resort-personality-questionnaire',
                    },
                    {
                        name: 'CCAC Spa & Resort Boarding Intake Questionnaire',
                        path: 'new-clients/boarding-intake-questionnaire',
                    },
                ],
            },
        ],
    },
    {
        name: 'Doggy Daycare',
        path: 'doggy-daycare/',
        menu: [
            { name: 'Day Care', path: 'veterinary-services/doggy-daycare/' },
            { name: 'Boarding', path: 'veterinary-services/doggy-daycare/boarding/' },
            {
                name: 'CCAC Spa & Resort Pet Personality Questionnaire',
                path: 'new-clients/spa-resort-personality-questionnaire',
            },
            {
                name: 'CCAC Spa & Resort Boarding Intake Questionnaire',
                path: 'new-clients/boarding-intake-questionnaire',
            },
        ],
    },
    {
        name: 'Grooming',
        path: 'grooming/',
    },
    {
        name: 'Blogs',
        path: 'blogs-articles/',
    },
];

const NavContainer = styled.div<Props>`
    position: relative;
    z-index: 100;
    background: ${colors.white};
    .nav-menu ::-webkit-scrollbar {
        display: none;
    }
    .nav-menu {
        overflow-y: scroll;
        position: fixed;
        background: ${colors.tealLight};
        height: 100vh;
        width: 100vw;
        z-index: 100;
        color: ${colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({ active }) => (active ? 'translateX(00%)' : 'translateX(100%)')};
        .top-panel {
            position: fixed;
            top: 2em;
            align-items: center;
            padding: 0em 1em;
            display: flex;
            justify-content: space-between;
            width: 100vw;
            .ex-mark {
                top: 0.5em;
                position: absolute;
                right: 1em;
            }
            .back-mark {
                width: 30px;
            }
            p {
                margin: 0 auto;
                color: ${colors.white};
                font-weight: 600;
                position: relative;
                right: 1em;
                font-size: 22px;
            }
        }
        button {
            margin-top: 1.5em;
            width: 217px;
        }
        ul {
            height: ${({ subMenu }) => (subMenu === 'Services' ? '202vh' : '80vh')};
            position: absolute;
            left: 80px;
            top: 100px;
        }

        .nav-item {
            display: flex;
            align-items: center;
            li {
                color: ${colors.white};
                margin: 0.75em 0.75em 0.75em 0em;
                list-style: none;
                font-weight: 600;
                font-size: 24px;
            }
        }
        .desktop-list {
            display: none;
        }
    }
    @media ${device.tablet} {
    }
    @media ${device.laptop} {
    }
    @media ${device.laptopL} {
        .nav-menu {
            display: none;
        }
        .second-list {
            display: flex;
        }
    }
`;
const StyledNav = styled.nav<Props>`
    display: flex;
    padding: ${({ mobileScroll }) => (mobileScroll ? '0.25em 1em' : '0.65em 1em')};
    align-items: center;
    position: fixed;
    width: 100vw;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    background: ${colors.white};
    justify-content: space-between;
    .burger {
        div {
            width: 34px;
            height: 3px;
            margin: 0.5em 0em;
            background: ${({ home }) => (home ? `${colors.orange}` : `${colors.orange}`)};
        }
    }
    .mobile-nav {
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: space-between;
    }
    .desktop-nav {
        display: none;
    }
    .first-list,
    .second-list {
        display: flex;
        list-style: none;
        align-items: center;
        img {
            width: 8px;
            height: auto;
        }
    }

    .logo {
        width: 87px;
        height: auto;
    }
    .forms-list {
        display: none;
    }
    @media ${device.tablet} {
        padding: 1.5em 1em;
        .forms-list {
            min-width: 333px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: ${colors.white};
            padding: 0.5em 1em;
            border: 1px solid #dee4e4;
            box-sizing: border-box;
            box-shadow: 0px 21px 38px rgba(62, 110, 113, 0.12);
            border-radius: 10px;
            display: block;
            position: absolute;
            top: 12.62em;
            left: 15.3em;
            background: white;
            padding: 1em;
            z-index: 200;
            li {
                padding: 1em;
            }
        }

        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background: ${colors.white};
        .first-list,
        .second-list {
            div {
                position: relative;
            }

            .desktop-list {
                position: absolute;
                top: 2em;
                left: -2em;
                min-width: 263px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background: ${colors.white};
                padding: 0.5em 1em;
                border: 1px solid #dee4e4;
                box-sizing: border-box;
                box-shadow: 0px 21px 38px rgba(62, 110, 113, 0.12);
                border-radius: 10px;
                li {
                    margin: 1em 0em;
                }
            }
        }
        .wide {
            height: 450px;

            display: flex;
            flex-direction: row;
            width: 540px;
            flex-wrap: wrap;
        }
        padding: 1.5em 4em 1.5em;
        .logo {
            width: 100px;
            height: auto;
        }
    }
    @media ${device.laptopL} {
        .mobile-nav {
            display: none;
        }
        .first-list,
        .second-list {
            div {
                margin: 0em 0.5em;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            li {
                margin: 0em 0.3em;
            }
        }
        .groom {
            color: ${({ home }) => (home ? 'white' : 'black')};
        }
        .second-list {
            color: ${colors.black};
        }
        .desktop-nav {
            display: flex;
            align-items: center;
            width: 100vw;
            justify-content: space-between;
        }
        .logo {
            width: 110px;
            height: auto;
        }
    }
    .nav-menu {
        transform: ${({ active }) => (active ? 'translateX(100%)' : 'translateX(00%)')};
    }
`;

interface Props {
    home?: boolean;
    active: boolean;
    subMenu?: string;
}

const debounce = (func, ms) => {
    let timer;
    return (...args) => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, ms);
        }
    };
};

export const Nav: React.FC<Props> = ({ home }) => {
    const [active, setActive] = useState<boolean>(false);
    const [subMenu, setSubMenu] = useState<Item[]>();
    const [menuName, setMenuName] = useState<string>();
    const [formsMenu, setFormsMenu] = useState(false);
    const isSSR = typeof window === 'undefined';
    const [position, setPosition] = useState(isSSR ? 0 : window.scrollY);
    const [mobileScroll, setMobileScroll] = useState(false);
    const debounceMs = 200;

    const onScroll = useCallback(
        debounce(() => setPosition(window.scrollY), 300),
        [debounceMs, setPosition]
    );

    useEffect(() => {
        if (isSSR) return;
        window.addEventListener('scroll', onScroll);
        if (position > 100) {
            setMobileScroll(true);
        } else {
            setMobileScroll(false);
        }
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [position]);

    type Menu = {
        name: string;
        path: string;
    };

    type Item = {
        name: string;
        path: string;
        menu?: Menu[];
    };

    const menuClick = (item: Item) => {
        if (item.menu) {
            setMenuName(item.name);
            setSubMenu(item.menu);
        } else {
            return;
        }
    };

    const toggleActive = () => {
        setActive(!active);
    };

    return (
        <NavContainer subMenu={subMenu} active={active}>
            <StyledNav mobileScroll={mobileScroll} active={active} home={home}>
                <div className="mobile-nav">
                    <Link to="/">
                        <StaticImage
                            placeholder="none"
                            className="logo"
                            src="../../images/logo.png"
                            alt="country club logo"
                        />
                    </Link>
                    <div onClick={() => toggleActive()} className="burger">
                        <div className="line-one"></div>
                        <div className="line-two"></div>
                        <div className="line-three"></div>
                    </div>
                </div>
                <div className="desktop-nav">
                    <Link to="/">
                        <StaticImage
                            placeholder="none"
                            className="logo"
                            src="../../images/logo.png"
                            alt="country club logo"
                        />
                    </Link>
                    <ul
                        onMouseEnter={() => {
                            setActive(true);
                        }}
                        className="first-list"
                    >
                        <div
                            onMouseEnter={() => {
                                setMenuName('About Us');
                                setSubMenu(navMenu[0].menu);
                                setActive(true);
                            }}
                        >
                            <Link to="/about-us">
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                    }}
                                >
                                    About Us
                                </li>
                            </Link>
                            <StaticImage
                                placeholder="none"
                                src="../../images/svgs/arrow-down-black.svg"
                                alt="black arrow down"
                            />
                            {active && menuName === 'About Us' && (
                                <div
                                    onMouseEnter={() => {
                                        setActive(true);
                                    }}
                                    onMouseLeave={() => {
                                        setActive(false);
                                    }}
                                    className="desktop-list"
                                >
                                    {subMenu &&
                                        subMenu.map(secondItem => (
                                            <Link key={secondItem.name} to={`/${secondItem.path}`}>
                                                <div className="nav-item second-item">
                                                    <li>{secondItem.name}</li>
                                                    {secondItem.menu && (
                                                        <StaticImage
                                                            placeholder="none"
                                                            src="../../images/svgs/white-arrow.svg"
                                                            alt="white arrow pointing left"
                                                        />
                                                    )}
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            )}
                        </div>
                        <div
                            onMouseEnter={() => {
                                setFormsMenu(false);
                                setMenuName('Services');
                                setSubMenu(navMenu[1].menu);
                                setActive(true);
                            }}
                        >
                            <Link to="/veterinary-services/">
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                    }}
                                >
                                    Services
                                </li>
                            </Link>
                            <StaticImage
                                placeholder="none"
                                src="../../images/svgs/arrow-down-black.svg"
                                alt="black arrow down"
                            />

                            {active && menuName === 'Services' && (
                                <div
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setFormsMenu(false);
                                    }}
                                    onMouseLeave={() => {
                                        setActive(false);
                                    }}
                                    className="wide desktop-list"
                                >
                                    {subMenu &&
                                        subMenu.map(secondItem => (
                                            <Link key={secondItem.name} to={`/${secondItem.path}`}>
                                                <div className="nav-item second-item">
                                                    <li>{secondItem.name}</li>
                                                    {secondItem.menu && (
                                                        <StaticImage
                                                            placeholder="none"
                                                            src="../../images/svgs/white-arrow.svg"
                                                            alt="white arrow pointing left"
                                                        />
                                                    )}
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            )}
                        </div>

                        <div
                            onMouseEnter={() => {
                                setFormsMenu(false);
                                setMenuName('New Clients');
                                setSubMenu(navMenu[2].menu);
                                setActive(true);
                            }}
                        >
                            <Link to="/new-clients/">
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setFormsMenu(false);
                                    }}
                                >
                                    New Clients
                                </li>
                            </Link>
                            <StaticImage
                                placeholder="none"
                                src="../../images/svgs/arrow-down-black.svg"
                                alt="black arrow down"
                            />
                            {active && formsMenu && (
                                <ul
                                    onMouseLeave={() => {
                                        setActive(false);
                                    }}
                                    className="forms-list"
                                >
                                    {navMenu[2].menu[3].menu?.map(item => (
                                        <Link key={item.name} to={`/${item.path}`}>
                                            <li
                                                className="desktop-special"
                                                style={{
                                                    listStyleType: 'none',
                                                }}
                                            >
                                                {item.name}
                                            </li>
                                        </Link>
                                    ))}
                                </ul>
                            )}
                            {active && menuName === 'New Clients' && (
                                <div
                                    onMouseLeave={() => {
                                        if (!formsMenu) {
                                            setActive(false);
                                        }
                                    }}
                                    onMouseEnter={() => {
                                        setActive(true);
                                    }}
                                    className="desktop-list"
                                >
                                    {subMenu &&
                                        subMenu.map(secondItem => (
                                            <>
                                                {secondItem.name === 'Online Forms' ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '220px',
                                                        }}
                                                        className="nav-item second-item"
                                                    >
                                                        <li
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onMouseEnter={() => {
                                                                setActive(true);
                                                                setFormsMenu(true);
                                                            }}
                                                        >
                                                            {secondItem.name}
                                                            <StaticImage
                                                                placeholder="none"
                                                                src="../../images/svgs/arrow-down-black.svg"
                                                                alt="black arrow down"
                                                            />
                                                        </li>

                                                        {secondItem.menu && (
                                                            <StaticImage
                                                                placeholder="none"
                                                                src="../../images/svgs/white-arrow.svg"
                                                                alt="white arrow pointing left"
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Link
                                                        key={secondItem.name}
                                                        to={`/${secondItem.path}`}
                                                    >
                                                        <div className="nav-item second-item">
                                                            <li>{secondItem.name}</li>
                                                            {secondItem.menu && (
                                                                <StaticImage
                                                                    placeholder="none"
                                                                    src="../../images/svgs/white-arrow.svg"
                                                                    alt="white arrow pointing left"
                                                                />
                                                            )}
                                                        </div>
                                                    </Link>
                                                )}
                                            </>
                                        ))}
                                </div>
                            )}
                        </div>
                    </ul>

                    <ul className="second-list">
                        <div
                            onMouseEnter={() => {
                                setMenuName('Doggy Daycare');
                                setSubMenu(navMenu[3].menu);
                                setActive(true);
                                setFormsMenu(false);
                            }}
                        >
                            <li
                                onMouseEnter={() => {
                                    setActive(true);
                                }}
                            >
                                Doggy Daycare
                            </li>
                            {home ? (
                                <>
                                    <StaticImage
                                        placeholder="none"
                                        src="../../images/svgs/arrow-down-white.svg"
                                        alt="white arrow down"
                                    />
                                </>
                            ) : (
                                <>
                                    <StaticImage
                                        placeholder="none"
                                        src="../../images/svgs/arrow-down-black.svg"
                                        alt="black arrow down"
                                    />
                                </>
                            )}

                            {active && menuName === 'Doggy Daycare' && (
                                <div
                                    onMouseEnter={() => {
                                        setActive(true);
                                    }}
                                    onMouseLeave={() => {
                                        setActive(false);
                                    }}
                                    className="desktop-list"
                                >
                                    {subMenu &&
                                        subMenu.map(secondItem => (
                                            <Link key={secondItem.name} to={`/${secondItem.path}`}>
                                                <div className="nav-item second-item">
                                                    <li>{secondItem.name}</li>
                                                    {secondItem.menu && (
                                                        <StaticImage
                                                            placeholder="none"
                                                            src="../../images/svgs/white-arrow.svg"
                                                            alt="white arrow pointing left"
                                                        />
                                                    )}
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            )}
                        </div>

                        <div onMouseEnter={() => setActive(false)}>
                            <Link to="/grooming/">
                                <li style={{ color: `${colors.black}` }} className="groom">
                                    Grooming
                                </li>
                            </Link>
                        </div>
                        <div>
                            <Link to="/blogs-articles/">
                                <li
                                    style={{ color: `${colors.black}`, marginLeft: '1em' }}
                                    className="groom"
                                >
                                    Blog
                                </li>
                            </Link>
                        </div>
                    </ul>
                    <Link to="/contact-us/">
                        <Button color="blue">Contact Us</Button>
                    </Link>
                </div>
            </StyledNav>
            <div className="nav-menu">
                <div className="top-panel">
                    {subMenu ? (
                        <>
                            {' '}
                            <StaticImage
                                onClick={() => setSubMenu()}
                                className="back-mark"
                                placeholder="none"
                                src="../../images/svgs/back-arrow.svg"
                                alt="white ex mark"
                            />
                            {menuName === 'Services' && (
                                <p>
                                    {' '}
                                    <Link style={{ color: 'white' }} to="/veterinary-services/">
                                        {menuName}{' '}
                                    </Link>
                                </p>
                            )}
                            {menuName === 'About Us' && (
                                <p>
                                    {' '}
                                    <Link style={{ color: 'white' }} to="/about-us/">
                                        {menuName}{' '}
                                    </Link>
                                </p>
                            )}
                            {menuName === 'New Clients' && (
                                <p>
                                    {' '}
                                    <Link style={{ color: 'white' }} to="/new-clients/">
                                        {menuName}{' '}
                                    </Link>
                                </p>
                            )}
                            {menuName === 'Doggy Daycare' && (
                                <p>
                                    {' '}
                                    <Link
                                        style={{ color: 'white' }}
                                        to="/veterinary-services/doggy-daycare/"
                                    >
                                        {menuName}{' '}
                                    </Link>
                                </p>
                            )}
                        </>
                    ) : null}
                    <StaticImage
                        onClick={() => setActive(false)}
                        className="ex-mark"
                        placeholder="none"
                        src="../../images/svgs/x.svg"
                        alt="white ex mark"
                    />
                </div>
                <ul>
                    {!subMenu &&
                        navMenu.map(firstItem => {
                            return (
                                <div
                                    key={firstItem.name}
                                    onClick={() => menuClick(firstItem)}
                                    className="nav-item first-item"
                                >
                                    {!firstItem.menu ? (
                                        <Link to={`/${firstItem.path}`}>
                                            {' '}
                                            <li>{firstItem.name}</li>
                                        </Link>
                                    ) : (
                                        <li>{firstItem.name}</li>
                                    )}
                                    {firstItem.menu && (
                                        <StaticImage
                                            placeholder="none"
                                            src="../../images/svgs/white-arrow.svg"
                                            alt="white arrow pointing left"
                                        />
                                    )}
                                </div>
                            );
                        })}
                    {subMenu &&
                        subMenu.map(secondItem => (
                            <div key={secondItem.name} className="nav-item second-item">
                                {secondItem.name === 'Online Forms' ? (
                                    <li
                                        onClick={() => {
                                            setSubMenu(secondItem.menu);
                                        }}
                                    >
                                        {secondItem.name}
                                    </li>
                                ) : (
                                    <Link to={`/${secondItem.path}`}>
                                        <li>{secondItem.name}</li>
                                    </Link>
                                )}
                                {secondItem.menu && (
                                    <StaticImage
                                        placeholder="none"
                                        src="../../images/svgs/white-arrow.svg"
                                        alt="white arrow pointing left"
                                    />
                                )}
                            </div>
                        ))}

                    {!subMenu && (
                        <Button onClick={() => setActive(false)} color="blue">
                            Contact Us
                        </Button>
                    )}
                </ul>
            </div>
        </NavContainer>
    );
};
