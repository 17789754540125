import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PictureGrid } from '../../components/PictureGrid';
import { Title } from '../../components/Title';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureText } from '../../components/PictureText';
import { Seo } from '../../components/seo';

const OurDoctors: Page = () => (
    <DefaultLayout path="about-us" prevName="About Us" name="our Doctors">
        <Seo
            title="Our Doctors - Club Animal Clinic"
            description="Learn more about Dr. Nancy D. Harvery"
        />
        <Title>
            <h1>Our Doctors</h1>
        </Title>
        <PictureText>
            <div className="image-container">
                <StaticImage
                    className="picture"
                    placeholder="blurred"
                    src="../../images/doctor.png"
                    alt="doctor on green outfit"
                />
            </div>
            <div className="text">
                <h2>Nancy D. Harvey, D.V.M.</h2>
                <span>Doctor in Veterinary Medicine</span>
                <p>
                    Country Club Animal Clinic is owned and operated by Nancy D. Harvey, D.V.M. Dr.
                    Harvey graduated from the College of Veterinary Medicine at Texas A & M
                    University with Honors in 1981. During her college years she worked as a
                    veterinary assistant in various small and large animal clinics.
                </p>
                <p>
                    Dr. Harvey has practiced in small animal practices for over 30 years, including
                    the El Paso Animal Emergency Center for 12 years and the El Paso Zoo for several
                    years. She has volunteered her veterinary services to various animal shelters
                    and wildlife rescue groups. She has held every office in the El Paso Veterinary
                    Medical Association and has been on various animal group boards.
                </p>
            </div>
        </PictureText>
        <PictureGrid>
            <div className="panel-flex">
                <div className="text">
                    <p>
                        At Country Club Animal Clinic, we see dogs, cats, pet birds and various
                        other fowl, ferrets, lizards, snakes, turtles and tortoises, amphibians,
                        rabbits, guinea pigs, chinchillas, hamsters, gerbils, rats, mice, hedgehogs,
                        sugar gliders, and teacup pigs.
                    </p>
                </div>
                <div className="image-container">
                    <StaticImage
                        className="image image-1"
                        placeholder="none"
                        src="../../images/our-doctors/rabbit.jpeg"
                        alt="little rabbit"
                    />
                    <StaticImage
                        className="image image-2"
                        placeholder="none"
                        src="../../images/our-doctors/squirrel.jpeg"
                        alt="little squirrel"
                    />
                    <StaticImage
                        className="image image-3"
                        placeholder="none"
                        src="../../images/our-doctors/rooster.jpeg"
                        alt="red rooster"
                    />

                    <StaticImage
                        className="image image-4"
                        placeholder="none"
                        src="../../images/our-doctors/wombat.jpeg"
                        alt="bug-eyed wombat"
                    />
                    <StaticImage
                        className="image image-5"
                        placeholder="none"
                        src="../../images/our-doctors/chinchilla.jpeg"
                        alt="chinchilla"
                    />
                    <StaticImage
                        className="image image-6"
                        placeholder="none"
                        src="../../images/our-doctors/turtle.jpeg"
                        alt="radiant turtle"
                    />
                    <StaticImage
                        className="image image-7"
                        placeholder="none"
                        src="../../images/our-doctors/pig.jpeg"
                        alt="pink pig"
                    />
                    <StaticImage
                        className="image image-8"
                        placeholder="none"
                        src="../../images/our-doctors/bird.jpeg"
                        alt="orange bird"
                    />
                    <StaticImage
                        className="image image-9"
                        placeholder="none"
                        src="../../images/our-doctors/lizard.jpeg"
                        alt="blue toned lizard"
                    />
                    <StaticImage
                        className="image image-10"
                        placeholder="none"
                        src="../../images/our-doctors/monkey.jpeg"
                        alt="monkey looking at reflection"
                    />
                </div>
            </div>
        </PictureGrid>
    </DefaultLayout>
);

export default OurDoctors;
