import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { AppointmentForm } from '../../components/forms/AppointmentForm';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureMain } from '../../components/PictureMain';
import { TextMain } from '../../components/TextMain';
import { Title } from '../../components/Title';
import { Seo } from '../../components/seo';

const Appointments: Page = () => (
    <DefaultLayout path="new-clients/" prevName="New Clients" name="Appointments">
        <Seo
            title="Appointments Country Club Animal Clinic"
            description="Appointments Country Club Animal Clinic"
        />
        <Title>
            <h1>
                <span className="orange">Appointments</span> At Country Club Animal Clinic
            </h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../../images/dog-blanket.jpeg"
                alt="dog on blanket"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../../images/dog-blanket.jpeg"
                alt="dog on blanket"
            />
        </PictureMain>
        <TextMain>
            <p>
                Thank you for giving us the opportunity to care for your pet. We’ll be happy to
                answer any of your questions you have about your pet’s health. To insure the best
                care possible, please take the time to fill in this form completely. Thank you!
            </p>
        </TextMain>
        <AppointmentForm />
    </DefaultLayout>
);

export default Appointments;
