import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Title } from '../components/Title';
import { PictureMain } from '../components/PictureMain';
import { StaticImage } from 'gatsby-plugin-image';
import { TextMain } from '../components/TextMain';
import { Shampoos } from '../components/Shampoos';
import { Faq } from '../components/FAQ';
import { Seo } from '../components/seo';
import styled from '@emotion/styled';

const render = () => {
    return (
        <span>
            Call us at
            <a style={{ fontWeight: '600' }} rel="noreferrer" href="tel:9158330645">
                <span className="orange x">(915) 833-0645</span>
            </a>{' '}
            to schedule an appointment.
        </span>
    );
};

const StyledGrooming = styled.div`
    .groom-pic {
        width: 400px;
        height: auto;
        margin: 0 auto;
    }
`;

const questions = [
    {
        question: 'How to get my pet started with grooming services?',
        answer: render(),
    },
    {
        question: 'How much does a dog grooming cost?',
        answer: `Grooming costs are dependent on the size of the dog and the dog's condition. Call us
        at 915-833-0645 and we will be happy to provide more information.`,
    },
    {
        question: 'How long does a dog grooming service take?',
        answer: `Each grooming session is different however, the average grooming session lasts 2-3
        hours`,
    },
    {
        question: 'What vaccinations are required for grooming services?',
        answer: `Dogs need rabies, distemper, parvo, bordetella, microchipped and city license.
        Cats need rabies, RVRCP, microchip and city license.`,
    },
    {
        question: 'Do you trim dog’s nails?',
        answer: `Yes, we offer nail trimming and grinding of your pet's nails.`,
    },
    {
        question: 'Do you trim cat’s nails?',
        answer: `Yes, we offer nail trimming for cats.`,
    },
];

const Grooming: Page = () => (
    <DefaultLayout name="Grooming">
        <Seo
            title="Pet Grooming El Paso, TX | Dog Grooming | Cat Grooming"
            description="Offering affordable pet grooming service in EL Paso Texas."
        />
        <Title>
            <h1>
                Pet <span>Grooming</span> in El Paso Texas
            </h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../images/cat-scratch.jpeg"
                alt="cat lying down"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../images/cat-scratch.jpeg"
                alt="cat lying down"
            />
        </PictureMain>
        <TextMain>
            <p>
                Here at Country Club Animal Clinic, we are very proud to offer grooming services for
                your pet. Well groomed pets look and feel their best! They have fewer health issues,
                especially with their skin and ears!
            </p>
            <p>
                We have trained and professional groomers whom are great at what they do! We work
                with a variety of breeds and their cuts, but we also do special requests.{' '}
            </p>
            <p>
                Grooming is done by appointment only so call us today to schedule an appointment or
                submit an online request. All dogs must be current on their vaccines including:
                rabies, distemper, parvo and bordetella. Cats must be current on their vaccines,
                including: rabies and FVRCP. Additionally, dogs and cats must be microchipped and
                have a city license, according to the City of El Paso local ordinances.
            </p>
        </TextMain>

        <Shampoos />
        <Faq questions={questions} />
    </DefaultLayout>
);

export default Grooming;
