/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledFAQ = styled.div`
    padding: 1em;
    background: ${colors.orangeLight};
    h2 {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 1px;
        color: ${colors.tealDark};
    }
    .blue {
        color: ${colors.blue};
        font-weight: bold;
    }
    .orange {
        font-weight: bold;
        color: ${colors.orange};
    }
    span {
        font-weight: 500;
    }
    .image {
        border: none;
        width: 29px;
        height: auto;
        position: absolute;
        right: 1em;
        top: 1.5em;
    }
    .item {
        cursor: pointer;
        position: relative;
        background: #fff9ee;
        border: 1px solid #ffeece;
        box-sizing: border-box;
        box-shadow: -8px -8px 64px #ffffff, 0px 8px 64px rgba(221, 174, 87, 0.32),
            inset 0px 16px 64px rgba(255, 255, 255, 0.2);
        border-radius: 32px;
        margin: 1em 0.2em;
        padding: 2em 1em;
    }
    p {
        margin-top: 1em;
        line-height: 26px;
        font-size: 16px;
        font-weight: 400;
        display: block;
    }
    h3 {
        width: 75%;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.75px;
        color: ${colors.black};
    }
    .hidden {
        display: none;
    }
    @media ${device.tablet} {
        padding: 2em 0em;
        h2 {
            margin: 1.5em 0em;
        }
    }
    @media ${device.laptopL} {
        padding: 4em 0em;
        h2 {
            margin: 0em 0em 1.5em;
        }
    }
`;

type Question = {
    question: string;
    answer: string;
};

interface Props {
    questions: Question;
}

export const Faq: React.FC<Props> = ({ questions }) => {
    const [index, setIndex] = useState(null);
    const [opened, setOpened] = useState(false);

    const close = () => {
        setIndex(null);
        setOpened(true);
    };

    return (
        <StyledFAQ>
            <div className="container">
                <h2>Frequently Asked Questions</h2>
                {questions.map((question, i) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                        key={question.question}
                        className="item"
                        onClick={() => {
                            setIndex(i);
                            if (index === i) {
                                close();
                            }
                        }}
                    >
                        {index === i ? (
                            <StaticImage
                                placeholder="none"
                                className="image"
                                src="../images/svgs/minus-sign.svg"
                                alt="minus sign"
                            />
                        ) : (
                            <StaticImage
                                placeholder="none"
                                className="image"
                                src="../images/svgs/plus-sign.svg"
                                alt="plus sign"
                            />
                        )}
                        <h3>
                            <span className="orange">Q:</span> {question.question}
                        </h3>
                        <p className={`${index === i ? '' : 'hidden'}`}>
                            <span className="blue">A:</span> {question.answer}
                        </p>
                    </div>
                ))}
            </div>
        </StyledFAQ>
    );
};
