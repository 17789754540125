import React from 'react';
import { device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const reviews = [
    {
        name: 'Margaret Kirk',
        review: 'Dr. Harvey has been my veterinarian for 25 years.  She is an amazing diagnostician and has always had the best interests of my critters at heart.',
    },
    {
        name: 'Taylor Edwinson',
        review: 'I’ve gone to country club for years. Dr. Harvey and her staff are all so kind and sweet to not only the animals, but the people as well.',
    },
    {
        name: 'Cecilia D Chavira',
        review: 'Dr Harvey has been attending to our loving dog for the last 9 years with great care! She is on top of everything he needs for his welfare! We are very grateful to her & her staff! 🙏💕',
    },
    {
        name: 'Lynn Provenzano',
        review: 'Love Dr. Harvey and the clinic. My dog loves the doggie day care. Love the new boarding area. Had my Australian Shepherd resue groomed. He was a handsome boy after.',
    },
    {
        name: 'Lucy Marks',
        review: 'All our animals have been going to Dr. Harvey for years and we would never go anywhere else. Dr. Harvey is very knowledgeable and compassionate, we absolutely love Country Club Animal Clinic.',
    },
    {
        name: 'Lucas Allen',
        review: 'Been taking my dogs here for the last year. Prices are affordable. Their service is excellent they always double check their work and my dogs are happy to go!',
    },
];

const StyledReviews = styled.div`
    background: #fff9ee;
    padding: 2em 0em 2em;
    span {
        font-weight: 600;
        margin-right: 0.5em;
    }
    h2 {
        text-align: center;
        padding: 0.5em 0em 0em;
    }
    .rating {
        display: flex;
        justify-content: space-around;
        width: 53%;
        height: 30px;
        margin: 1.5em auto;
        align-items: center;
        img {
            width: 15px;
            height: auto;
        }
    }
    .black-line {
        height: 20px;
        width: 2px;
        margin: 0em 1em;
        background: black;
    }
    .cards {
        display: flex;
        overflow-x: scroll;
        margin: 1em 0em;
        padding: 1em 0em;
    }
    .stars {
        margin: 1em 0em 1.5em;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0em 2em;
        margin: 0.5em;
        height: 374px;
        min-width: 330px;
        background: rgba(250, 250, 250, 0.8);
        box-shadow: -8px -8px 50px #ffffff, 8px 8px 32px -5px rgba(166, 102, 26, 0.2);
        border-radius: 32px;
    }
    .buttons {
        display: flex;
        width: 55vw;
        margin: 2em auto;
        justify-content: space-between;
        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: #b0470b;
            border: none;
            height: 72px;
            width: 72px;
            border-radius: 100px;
            backdrop-filter: blur(50px);
        }
    }
    @media ${device.tablet} {
        .rating {
            width: 17%;
        }
        .buttons {
            display: flex;
            width: 15vw;
            margin-top: 1em;
        }

        .cards-container {
            overflow-x: scroll;
        }
        .cards {
            padding: 1em 0em 2em;
            width: 120vw;
            .card {
            }
        }
    }
    @media ${device.laptopL} {
        .cards {
            padding-left: 1.5em;
        }
        .card {
            min-width: 450px;
            min-height: 270px;
        }
    }
`;

export const Reviews: React.FC = () => {
    // const myRef: any = useRef(null);
    // const scrollLeft = (scrollOffset: number) => {
    //     myRef.current.scrollLeft += -10;
    //     console.log(myRef.current.scrollLeft);
    // };
    // const scrollRight = (scrollOffset: number) => {
    //     myRef.current.scrollLeft += 10;
    //     myRef.current.scrollTo({ x: 0, y: 0, animated: false });
    // };

    return (
        <StyledReviews>
            <h2>Client Reviews</h2>

            <div className="rating">
                <span>4.4</span>

                <div style={{ display: 'flex' }}>
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/stars.svg"
                        alt="golden star"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/stars.svg"
                        alt="golden star"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/stars.svg"
                        alt="golden star"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/stars.svg"
                        alt="golden star"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/stars.svg"
                        alt="golden star"
                    />
                </div>
                <div className="black-line"></div>
                <p>
                    <span>280+</span>Reviews
                </p>
            </div>
            <p style={{ textAlign: 'center' }}>Scroll through below:</p>
            <div className="cards-container">
                <div className="cards">
                    {reviews.map(review => (
                        <>
                            <div className="card">
                                <h3>{review.name}</h3>
                                <div className="stars">
                                    <StaticImage
                                        placeholder="none"
                                        src="../images/svgs/stars.svg"
                                        alt="golden star"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        src="../images/svgs/stars.svg"
                                        alt="golden star"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        src="../images/svgs/stars.svg"
                                        alt="golden star"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        src="../images/svgs/stars.svg"
                                        alt="golden star"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        src="../images/svgs/stars.svg"
                                        alt="golden star"
                                    />
                                </div>
                                <p>{review.review}</p>
                            </div>
                        </>
                    ))}
                </div>
            </div>
            {/* <div className="buttons">
                <button onClick={() => scrollLeft()}>
                    <StaticImage src="../images/svgs/arrow-left.svg" alt="arrow pointing left" />
                </button>
                <button onClick={() => scrollRight()}>
                    <StaticImage src="../images/svgs/arrow-right.svg" alt="arrow pointing right" />
                </button>
            </div> */}
        </StyledReviews>
    );
};
