import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Title } from '../../components/Title';
import { PictureMain } from '../../components/PictureMain';
import { Blurb } from '../../components/Blurb';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../../components/seo';

const OurTeam: Page = () => {
    return (
        <DefaultLayout path="about-us" prevName="About Us" name="Our Team">
            <Seo
                title="Country Club Animal Clinic Team in El Paso Texas"
                description="Get to know your local EL Paso country club animal clinic veterinary team"
            />
            <Title>
                <h1>Our Team</h1>
            </Title>
            <PictureMain>
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../../images/building.jpeg"
                    alt="outside of a building blurred out"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/building.jpeg"
                    alt="outside of a building"
                />
            </PictureMain>
            <div className="margin">
                <Blurb picture="left">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            quality={100}
                            src="../../images/team/angel.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../../images/team/angel.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Angel Ramirez</h2>
                        <h3>Practice Manager</h3>
                        <p>
                            Hello, my name is Angel. I majored in biology at EPCC and continued my
                            education at UTEP as a Biochemistry major. I later got certified as a
                            veterinary assistant at PIMA medical institute. I have been in the
                            veterinary field for over 2 years and am passionate about animals and
                            their well being. Most of my pets are rescues. I have all pets ranging
                            from dogs to horses.
                        </p>
                    </div>
                </Blurb>

                <Blurb picture="right">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            src="../../images/team/adrian.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../../images/team/adrian.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Adrian Rosales</h2>
                        <h3>Licensed Veterinary Technician</h3>
                        <p>
                            I’m Licensed Veterinary Technician that loves working with all kinds of
                            animals. As a Lead Technician, I am the one in charge of radiology and
                            anesthesia. I am also the one that makes sure your pet’s teeth look
                            great and healthy by doing the COHATs (Comprehensive Oral Health
                            Assessment and Treatment).
                        </p>
                    </div>
                </Blurb>

                <Blurb picture="left">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            quality={100}
                            src="../../images/team/avila.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../../images/team/avila.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Angel Avila</h2>
                        <h3>Veterinary Technician</h3>
                        <p>
                            Hi, my name is Angel Avila! I have always wanted to pursue a career in
                            the veterinary field for as long as I could remember.  While pursuing a
                            bachelors’ in Environmental science at UTEP I attended PIMA and received
                            my certification as a Veterinary Assistant. After completing my
                            externship at Pima Animal Care Center in Tucson, Arizona. As of July
                            2021, I have now been a part of the Country Club Animal Clinic team for
                            a year and I am dedicated to the care and well being of your beloved
                            pets.
                        </p>
                    </div>
                </Blurb>

                <Blurb picture="right">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            src="../../images/team/luciana.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../../images/team/luciana.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Luciana Fernandez</h2>
                        <h3>Veterinary Technician</h3>
                        <p>
                            Hello! My name is Luciana.  I was born and raised in El Paso, TX. I
                            graduated from Pima Medical Institute in 2019 as a certified veterinary
                            assistant! I couldn’t be happier to work with animals, educating
                            clients, and helping our furry friends in need. This occupation has
                            always been a dream of mine, little me would be proud! On my free time,
                            I love teaching my daughter that animals deserve as much love and
                            compassion as we do.
                        </p>
                    </div>
                </Blurb>

                <Blurb picture="left">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            src="../../images/team/victoria.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../../images/team/victoria.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Victoria Martinez</h2>
                        <h3>Client Services Manager/Lead Receptionist</h3>
                        <p>
                            Victoria is a native El Pasoan. She is attending the University of Texas
                            at El Paso, pursuing a degree in environmental sciences. She has had a
                            love for animals for as long as she can remember. Most of the time you
                            will see her in front of the clinic or hear her scheduling your
                            appointments and don’t be surprised if she is helping out your
                            technician or doctor in the room. She has also attended PIMA Technical
                            School where she got certified as a veterinary assistant.
                        </p>
                    </div>
                </Blurb>

                <Blurb picture="right">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            src="../../images/team/dolly.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../../images/team/dolly.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Dolly</h2>
                        <h3>Support Dog</h3>
                        <p>
                            Dolly is our support dog. She is happy, energetic and will encourage you
                            to perk up, even when you’re not feeling too great. Not only is she the
                            best pet, but she is also great at supporting and comforting owners when
                            we, unfortunately, have to give them bad news. Due to Dolly’s
                            intelligence we have to be more creative in hiding our lunch or locking
                            doors because she can open any door or steal any lunch from most places.
                            We love having her around the clinic and everyone that meets her feels
                            the same.
                        </p>
                    </div>
                </Blurb>
            </div>
        </DefaultLayout>
    );
};

export default OurTeam;
