import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';

interface Props {
    color?: string;
}
export const Button = styled.button<Props>`
    background: ${({ color }) => {
        if (color === 'orange') {
            return colors.orange;
        } else if (color === 'blue') {
            return colors.blue;
        } else {
            return colors.white;
        }
    }};
    color: ${({ color }) => {
        if (color === 'orange' || color == 'blue') {
            return colors.white;
        } else {
            return '#393D3E';
        }
    }};
    border: ${({ color }) => {
        if (color !== 'orange' && color !== 'blue') {
            return '1px solid #AFB9BA';
        } else {
            return 'none';
        }
    }};
    font-size: 0.9em;
    border-radius: 10px;
    padding: 1em;
`;
