import { ThemeProvider } from '@emotion/react';
import { GlobalStyles, colors } from '../components/layout/GlobalStyles';
import { Nav } from '../components/layout/Nav';
import { MDXProvider } from '@mdx-js/react';
import { Footer } from '../components/layout/Footer';
import { BreadCrumbs } from '../components/BreadCrumbs';
import { ContactUs } from '../components/ContactUs';
import { Hours } from '../components/Hours';
import { Payments } from '../components/Payments';
import { PictureMain } from '../components/PictureMain';
import { TextMain } from '../components/TextMain';
import { Blurb } from '../components/Blurb';
import { Faq } from '../components/FAQ';
import { Carousel } from '../components/Carousel';

interface Props {
    name: string;
    home?: boolean;
    prevName?: string;
    path?: string;
    contactPage?: boolean;
}

const shortcodes = { Carousel, PictureMain, Faq, TextMain, Blurb };

export const DefaultLayout: React.FC<Props> = ({
    children,
    path,
    contactPage,
    home,
    prevName,
    name,
}) => {
    return (
        <div id="layout">
            <ThemeProvider theme={colors}>
                <MDXProvider components={shortcodes}>
                    <GlobalStyles />
                    <Nav home={home} />
                    <BreadCrumbs path={path} home={home} prevName={prevName} name={name} />
                    {children}
                    {home ? (
                        <div>
                            <ContactUs />
                            <Hours home />
                            <Payments />
                        </div>
                    ) : (
                        <div>
                            {!contactPage && <ContactUs />}
                            <Hours />
                        </div>
                    )}
                    <Footer />
                </MDXProvider>
            </ThemeProvider>
        </div>
    );
};
