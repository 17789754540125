import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../layout/GlobalStyles';
import { Button } from '../layout/StyledComponents';

const StyledForm = styled.div`
    padding: 1em 1em 0em;
    .desktop-flex {
        display: block;
    }
    .mid {
        width: 197px;
    }
    .checker {
        margin: 0.35em 0em;
        width: 20px;
        color: ${colors.black};
        height: 20px;
        border: none;
    }

    form {
        color: ${colors.black};
        display: flex;
        flex-direction: column;
        button {
            margin: 2em auto 4em;
            width: 90vw;
        }
    }

    .small {
        width: 107px;
    }
    h3 {
        padding-bottom: 1em;
        border-bottom: 1.5px solid #dee4e4;
        margin: 2.5em 0em 1em;
    }
    input {
        color: ${colors.black};
        text-indent: 0.35em;
        background: #ffffff;
        border: 1.5px solid #dee4e4;
        box-sizing: border-box;
        padding: 0.75em;
        border-radius: 5px;
    }

    select {
        background: linear-gradient(45deg, transparent 50%, ${colors.black} 50%),
            linear-gradient(135deg, ${colors.black} 50%, white 50%),
            linear-gradient(to right, white, white);
        background-position: calc(100% - 16px) calc(1em + 2px), calc(100% - 11px) calc(1em + 2px),
            100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        text-indent: 0.25em;
        padding: 0.75em;
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
    }
    textarea {
        border-radius: 5px;
        outline: 1.5px solid #dee4e4;
        border: none;
        box-sizing: border-box;
    }
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    p,
    label {
        font-weight: 500;
    }
    h3 {
        font-size: 24px;
        color: ${colors.tealLight};
    }
    .section {
        margin: 0.75em 0em;
    }
    .item {
        align-items: center;
        display: flex;
        margin-right: 1em;
        label {
            padding-left: 0.3em;
        }
        input:nth-of-type(2) {
            margin-left: 1em;
        }
    }
    .row {
        display: flex;
    }
    textarea {
        min-height: 150px;
    }
    .section {
        display: flex;
        flex-direction: column;
        input,
        select,
        textarea {
            max-width: 500px;
        }
    }

    @media ${device.laptop} {
        .payments {
            display: flex;
        }
        .lists {
            justify-content: space-between;
            width: 1000px;
            display: flex;
        }
        button {
            max-width: 415px;
        }
        .third {
            width: 360px;
        }
        .second {
            min-width: 540px;
        }
        .small {
            width: 147px;
        }
        .city {
            margin-right: 4em;
        }
        .mid {
            min-width: 156px;
        }
        .desktop-flex {
            display: flex;
            justify-content: space-between;
        }
    }
`;

export const AppointmentForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledForm className="container">
            <form onSubmit={onSubmit}>
                <h3>Registration</h3>
                <div className="section">
                    <p>
                        Owner’s Name <span style={{ color: '#D63434' }}>*</span>{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Must be over 18 yrs old)
                        </span>
                    </p>
                    <input id="owner" name="owner" type="text" required />
                </div>
                <div className="section">
                    <p>
                        Email <span style={{ color: '#D63434' }}>*</span>
                    </p>
                    <input id="email" name="email" type="email" required />
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>
                            Address <span style={{ color: '#D63434' }}>*</span>
                        </p>
                        <input style={{ width: '360px' }} id="address" name="address" type="text" />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="section">
                            <p>
                                City <span style={{ color: '#D63434' }}>*</span>
                            </p>
                            <input className="city  mid" id="city" name="city" type="text" />
                        </div>
                        <div className="section">
                            <p>
                                state<span style={{ color: '#D63434' }}>*</span>
                            </p>
                            <select className="small" id="state" name="state" required>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DC">DC</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="ND">ND</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>
                        </div>
                    </div>
                    <div className="section">
                        <p>Zip Code</p>
                        <input className="mid" id="zipCode" name="zipCode" type="text" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Cell Phone</p>
                        <input className="third" id="cellPhone" name="cellPhone" type="tel" />
                    </div>
                    <div className="section">
                        <p>Home Phone</p>
                        <input className="third" id="homePhone" name="homePhone" type="tel" />
                    </div>
                    <div className="section">
                        <p>Work Phone</p>
                        <input className="third" id="workPhone" name="workPhone" type="tel" />
                    </div>
                </div>
                <div className="desktop-flex">
                    <div className="section">
                        <p>Spouse Name</p>
                        <input className="second" id="spouse" name="spouse" type="text" />
                    </div>
                    <div className="section">
                        <p>Spouse Cell Phone</p>
                        <input className="second" id="spouseCell" name="spouseCell" type="tel" />
                    </div>
                </div>
                <div className="section">
                    <p>Employer’s Name</p>
                    <input id="employer" name="employer" type="text" />
                </div>
                <div className="section">
                    <p>
                        In case of <span className="orange">EMERGENCY</span>, please call this
                        person
                    </p>
                    <input id="emergencyContact" name="emergencyContact" type="tel" />
                </div>
                <div className="section">
                    <p>
                        Number of Pets
                        <span style={{ color: '#D63434' }}>* </span>
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Dogs, Cats, Other)
                        </span>
                    </p>{' '}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div className="section">
                        <p>Dogs</p>{' '}
                        <select
                            style={{ marginRight: '1em' }}
                            className="small"
                            name="numberOfDogs"
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4+">4+</option>
                        </select>
                    </div>
                    <div className="section">
                        <p>Cats</p>{' '}
                        <select className="small" name="numberOfCats">
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4+">4+</option>
                        </select>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div className="section">
                        <p>Other</p>{' '}
                        <select
                            style={{ marginRight: '1em' }}
                            className="small"
                            name="numberOfOtherAnimals"
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4+</option>
                        </select>
                    </div>
                    <div className="section">
                        <p>Specify Type</p>
                        <input
                            className="mid"
                            name="otherAnimalType"
                            id="otherAnimalType"
                            type="text"
                        />
                    </div>
                </div>
                <h3>Pet Health History</h3>
                <div
                    style={{
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="section">
                        <p>
                            Name Of Pet <span style={{ color: '#D63434' }}>*</span>
                        </p>
                        <input id="petname" name="petName" type="text" />
                        <div
                            style={{
                                marginTop: '1em',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="item">
                                <input type="radio" id="pet1" name="petType" value="dog" />
                                <label htmlFor="pet1">dog</label>
                            </div>
                            <div className="item">
                                <input type="radio" id="pet2" name="petType" value="cat" />
                                <label htmlFor="pet2">cat</label>
                            </div>
                        </div>{' '}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="item">
                                <input type="radio" id="pet2" name="petType" value="other" />
                                <label htmlFor="pet3">other</label>
                            </div>{' '}
                            <div className="item">
                                <input placeholder="" type="text" name="petTypeOther" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>Breed</p>
                    <input name="petBreed" id="type" type="text" />
                </div>
                <div className="section">
                    <p>Color</p>
                    <input className="small" id="type" type="text" name="petColor" />
                </div>
                <div className="section">
                    <p>
                        Birthdate{' '}
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>(mm/dd/yyyy)</span>
                    </p>
                    <input className="small" type="date" name="petDob" />
                </div>
                <div className="section">
                    <p>
                        Sex <span style={{ color: '#D63434' }}>*</span>
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div className="item">
                            <input type="radio" id="male" name="sex" value="male" />
                            <label htmlFor="male">Male</label>
                        </div>
                        <div className="item">
                            <input type="radio" id="neuter" name="sex" value="neutered" />
                            <label htmlFor="neuter">Neutered</label>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div className="item">
                            <input id="female" value="female" type="radio" name="sex" />
                            <label htmlFor="female">Female</label>
                        </div>
                        <div className="item">
                            <input type="radio" id="male" name="sex" value="spayed" />
                            <label htmlFor="neuter">Spayed</label>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>
                        Vaccination History <span style={{ color: '#D63434' }}>* </span>
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>
                            (Type and Date of last vaccinations)
                        </span>
                    </p>
                </div>
                <div className="section">
                    <p>Type</p>
                    <input id="type" type="text" name="vaccinationType" />
                </div>

                <div className="section">
                    <p>
                        Date
                        <span style={{ fontSize: '.7em', color: '#686F6F' }}>(mm/dd/yyyy)</span>
                    </p>
                    <input className="small" type="vaccinationDate" />
                </div>
                <div className="section">
                    <p>
                        Please check any symptoms or problems that you have noticed about your pet{' '}
                        <span style={{ color: '#D63434' }}>* </span>
                    </p>
                    <div className="lists">
                        <div className="first-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="behavior"
                                    name="symptoms"
                                    value="behavior problems"
                                />
                                <label htmlFor="behavior">Behavior Problems</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="gums"
                                    name="symptoms"
                                    value="bleeding gums"
                                />
                                <label htmlFor="gums">Bleeding Gums</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="coughing"
                                    name="symptoms"
                                    value="coughing"
                                />
                                <label htmlFor="coughing">Coughing</label>
                            </div>

                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="diarrhea"
                                    name="symptoms"
                                    value="diarrhea"
                                />
                                <label htmlFor="diarrhea">Diarrhea</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="gagging"
                                    name="symptoms"
                                    value="gagging"
                                />
                                <label htmlFor="gagging">Gagging</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="appetite"
                                    name="symptoms"
                                    value="appetite"
                                />
                                <label htmlFor="lack of appetite">Lack of Appetite</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="scooting"
                                    name="symptoms"
                                    value="scooting"
                                />
                                <label htmlFor="scooting">Scooting</label>
                            </div>
                        </div>{' '}
                        <div className="second-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="balance"
                                    name="symptoms"
                                    value="loss of balance"
                                />
                                <label htmlFor="balance">Loss of Balance</label>
                            </div>

                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="shaking head"
                                    name="symptoms"
                                    value="shaking head"
                                />
                                <label htmlFor="shaking head">Shaking Head</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="depression"
                                    name="symptoms"
                                    value="depression"
                                />
                                <label htmlFor="depression">Seems Depressed</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="vomiting"
                                    name="symptoms"
                                    value="vomiting"
                                />
                                <label htmlFor="vomiting">Vomiting</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="thirst/urination"
                                    name="symptoms"
                                    value="thirst/urination"
                                />
                                <label htmlFor="thirst/urination">
                                    Thirst and/or Urination Increased
                                </label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="bloodshot"
                                    name="symptoms"
                                    value="bloodshot eyes"
                                />
                                <label htmlFor="bloodshot">Eye Bulging or Bloodshot</label>
                            </div>
                        </div>
                        <div className="third-list">
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="sneezing"
                                    name="symptoms"
                                    value="sneezing"
                                />
                                <label htmlFor="sneezing">Sneezing</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="weakness"
                                    name="symptoms"
                                    value="weakness"
                                />
                                <label htmlFor="weakness">Weakness</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="breathing"
                                    name="symptoms"
                                    value="breathing problems"
                                />
                                <label htmlFor="breathing">Breathing Problems</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="limping"
                                    name="symptoms"
                                    value="limping"
                                />
                                <label htmlFor="limping">Limping</label>
                            </div>
                            <div className="item">
                                <input
                                    className="checker"
                                    type="checkbox"
                                    id="other"
                                    name="symptoms"
                                    value="other"
                                />
                                <label htmlFor="other">other</label>
                                <input name="otherSymptoms" type="text" placeholder="none" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p>Pet’s Current Medications</p>
                    <input id="currentMedications" name="meds" type="text" placeholder="none" />
                </div>
                <div className="section">
                    <p>
                        Describe Your Pet’s Diet <span style={{ color: '#D63434' }}>*</span>
                    </p>
                    <textarea id="diet" name="petsDiet" required />
                </div>
                <h3>Authorization</h3>
                <p>
                    I assume responsibity for all charges incurred in the care of this animal. I
                    also understand that these charges will be paid at the time of service and that
                    a deposit will be required depending on treatment.
                </p>
                <div className="section">
                    <p>
                        Owner or Responsible Party <span style={{ color: '#D63434' }}>*</span>
                    </p>
                    <input id="diet" name="ownerSignature" />
                </div>
                <div className="section">
                    <p>
                        Payment Method <span style={{ color: '#D63434' }}>*</span>
                    </p>
                    <div className="payments">
                        <div className="item">
                            <input type="radio" id="visa" name="paymentMethod" value="Visa" />
                            <label htmlFor="visa">Visa</label>
                        </div>
                        <div className="item">
                            <input
                                type="radio"
                                id="mastercard"
                                name="paymentMethod"
                                value="Mastercard"
                            />
                            <label htmlFor="mastercard">Mastercard</label>
                        </div>
                        <div className="item">
                            <input type="radio" id="cash" name="paymentMethod" value="cash" />
                            <label htmlFor="cash">Cash</label>
                        </div>
                        <div className="item">
                            <input type="radio" id="otherpay" name="paymentMethod" value="other" />
                            <label htmlFor="otherpay">Other</label>
                            <input
                                id="otherpayinput"
                                placeholder="none"
                                type="text"
                                name="otherPayment"
                            />
                        </div>
                    </div>
                </div>
                {submitState === 'submitted' ? (
                    <>
                        <h3>
                            Appointment request sent! Somebody from our office will be in touch
                            shortly.
                        </h3>
                    </>
                ) : (
                    <Button disabled={submitState === 'submitting'} color="orange" type="submit">
                        {submitState === 'submitting'
                            ? 'Submitting appointment...'
                            : 'Submit Your Appointment'}
                    </Button>
                )}
            </form>
        </StyledForm>
    );
};
