import { css, Global } from '@emotion/react';
import React from 'react';
import calender from '../../images/svgs/calender.svg';

export const colors = {
    grey: '#686F6F',
    tealLight: '#217A7A',
    tealDark: '#0B2F31',
    orange: '#FE8311',
    orangeLight: '#FFF9EE',
    black: '#393D3E',
    white: '#FFFFFF',
    primary: '#1492C8',
    blue: '#2CBBC5',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '768px',
    laptop: '1020px',
    laptopL: '1280px',
    desktop: '1540px',
    desktopL: '1820px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles: React.FC = () => {
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                }
                a {
                    text-decoration: none;
                    color: black;
                }
                body {
                    background: ${colors.white};
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;

                    -webkit-font-smoothing: antialiased;
                }

                .spec {
                    padding: 12em 0em;
                }

                * {
                    font-family: 'Poppins', sans-serif;
                    margin: 0;
                    transition: 0.2s all;
                }
                .margin {
                    margin: 2em 0em;
                }
                .container {
                    max-width: 1200px;
                    margin: 0 auto;
                }
                .container-header {
                    background: teal;
                    max-width: 1200px;
                }
                h1 {
                    font-size: 64px;
                    line-height: 66px;
                }
                p {
                    color: ${colors.black};
                }
                h1 {
                    font-family: 'Baloo Thambi 2', cursive;
                }
                h2 {
                    font-size: 36px;
                    line-height: 54px;
                }
                h3 {
                    font-size: 34px;
                }
                p {
                    line-height: 33px;
                    font-size: 1em;
                }
                a {
                    text-decoration: none;
                }
                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                }
                ul {
                    padding-left: 0;
                }
                button,
                input,
                optgroup,
                select,
                textarea {
                    margin: 0;
                }
                button {
                    cursor: pointer;
                }
                button,
                input {
                }
                ::-webkit-input-placeholder {
                    color: inherit;
                    opacity: 0.54;
                }
                ::-webkit-file-upload-button {
                    -webkit-appearance: button;
                    font: inherit;
                }

                input[type='text']:focus {
                    outline: 1.5px solid #2cbbc5; /* oranges! yey */
                    /* border: none; */
                }
                input[type='email']:focus {
                    outline: 1.5px solid #2cbbc5; /* oranges! yey */
                    /* border: none; */
                }
                input[type='tel']:focus {
                    outline: 1.5px solid #2cbbc5; /* oranges! yey */
                    /* border: none; */
                }
                input[type='date']:focus {
                    outline: 1.5px solid #2cbbc5; /* oranges! yey */
                    /* border: none; */
                }
                input[type='date']::-webkit-calendar-picker-indicator {
                    position: absolute;
                    left: -0.75em;
                    fill: red;
                    top: 2em;
                    height: 30px;
                    background: url(${calender}) no-repeat;
                    -webkit-appearance: none;
                }

                textarea:focus {
                    outline: 2px solid #2cbbc5; /* oranges! yey */
                    border: none;
                }
                .image-container {
                    position: relative;
                }
                textarea:focus {
                    box-shadow: 0px 0.5px 1px 1px #2cbbc5 inset, 0px 1px 1px 1px #2cbbc5;
                    outline: none;
                }
                .underlayer {
                    position: absolute;
                    top: 0em;
                    filter: blur(20px);
                    width: 74%;
                    left: 2em;
                    top: 2.4em;
                }

                @media ${device.mobileL} {
                    h2 {
                        font-size: 40px;
                        line-height: 60px;
                    }
                }
                @media ${device.tablet} {
                    .margin {
                        margin: 5em 0em;
                    }
                    .special-case {
                        padding: 0.25em 0em;
                        text-align: center;
                    }
                    .container {
                        max-width: 720px;
                        margin: 0 auto;
                    }
                    .container-header {
                        max-width: 720px;
                    }
                    h1 {
                        font-size: 64px;
                        line-height: 66px;
                        letter-spacing: 1px;
                    }
                    h2 {
                        font-size: 40px;
                    }
                    h1,
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 50px;
                        letter-spacing: -0.05em;
                    }
                    h3 {
                        font-size: 40px;
                    }
                }
                @media ${device.laptop} {
                    .container {
                        position: relative;
                        max-width: 920px;
                    }
                    .container {
                        max-width: 920px;
                    }
                }

                @media ${device.laptopL} {
                    .margin {
                        margin: 7em 0em;
                    }
                    .special-case {
                        padding: 0.5em 0em;
                        text-align: center;
                    }
                    .orange {
                        color: ${colors.orange};
                    }

                    .container {
                        max-width: 1165px;
                        margin: 0 auto;
                    }
                    h1 {
                        font-size: 64px;
                        line-height: 66px;
                        letter-spacing: 1px;
                    }
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 60px;
                        letter-spacing: -0.05em;
                    }
                }
                @media ${device.desktop} {
                    h2 {
                        font-size: 54px;
                    }
                }
                @media ${device.desktopL} {
                    p {
                        font-size: 1.2em;
                    }
                    h1 {
                        font-size: 64px;
                        line-height: 66px;
                    }
                    h2 {
                        font-size: 58px;
                    }
                    h2 {
                        font-style: normal;
                        font-weight: 600;

                        line-height: 66px;
                        letter-spacing: -0.04em;
                    }
                    h3 {
                        font-size: 48px;
                    }

                    .container {
                        max-width: 1265px;
                        margin: 0 auto;
                    }
                    .container {
                        max-width: 1265px;
                    }
                }
            `}
        />
    );
};
