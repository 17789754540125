import * as React from 'react';
import { useState } from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Title } from '../components/Title';
import { PictureMain } from '../components/PictureMain';
import { TextMain } from '../components/TextMain';
import { Blurb } from '../components/Blurb';
import { StaticImage } from 'gatsby-plugin-image';
import { Carousel } from '../components/Carousel';
import { Seo } from '../components/seo';

const AboutUs: Page = () => {
    const [index, setIndex] = useState(0);

    const onSelect = (i: React.SetStateAction<number>) => {
        setIndex(i);
    };

    const onRightArrowPush = () => {
        if (index <= 8) {
            setIndex(index => index + 1);
        } else {
            return;
        }
    };

    const onLeftArrowPush = () => {
        if (index >= 1) {
            setIndex(index => index - 1);
        } else {
            return;
        }
    };
    return (
        <DefaultLayout name="About Us">
            <Seo
                title="About Country Club Animal Clinic in El Paso Texas"
                description="Learn about our spa & resort like pet Clinic in El Paso Texas"
            />
            <Title>
                <h1>
                    <span>About Country Club Animal Clinic</span> in El Paso Texas
                </h1>
            </Title>
            <PictureMain>
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../images/wide-building-shot.jpeg"
                    alt="far away building shot blurred"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../images/wide-building-shot.jpeg"
                    alt="far away building shot"
                />
            </PictureMain>
            <TextMain>
                <h5 style={{ margin: '0 auto' }}>About Us</h5>
                <p>
                    Country Club Animal Clinic was established in 1995. The Country Club name was
                    chosen because Dr. Harvey started the practice out of her home on Country Club
                    Road in April of 1995, making house calls out of the back of a Honda hatchback.
                    After starting as a house-call practice, primarily seeing birds and other
                    exotics, within two months the practice quickly grew into a full-fledged general
                    practice with dogs and cats. With the help of Matt and Debbie Koch, a permanent
                    site was found at 5470 Doniphan Dr. The doors opened on Memorial Day of 1995.
                </p>
                <p>
                    Sine we opened, we have always taken pride in providing great customer service,
                    excellent care to our patients in a clean environment at fair prices. We also
                    strive to provide a safe and friendly environment for our employees to work,
                    while providing modern equipment, as well as opportunities to learn as much as
                    possible regarding veterinary medicine.
                </p>
            </TextMain>
            <PictureMain>
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../images/near-building-shot.jpeg"
                    alt="close proximity shot blurred"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../images/near-building-shot.jpeg"
                    alt="close proximity building shot"
                />
            </PictureMain>
            <div className="x margin">
                <Blurb picture="right">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            quality={100}
                            src="../images/cat-blanket.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../images/cat-blanket.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <h2>Bark Park</h2>
                        <p>
                            Here your fur babies will be able to come and work on their
                            socialization skills with other dogs and people. Whether you are
                            boarding in our luxurious suites or just enjoying a daycare day, your
                            dog will have space to run, play and meet new friends.
                        </p>
                    </div>
                </Blurb>

                <Blurb picture="left">
                    <div className="image-container">
                        <StaticImage
                            placeholder="none"
                            className="underlayer"
                            src="../images/dog-jacket.png"
                            alt="cat lying down"
                        />
                        <StaticImage
                            quality={100}
                            placeholder="none"
                            src="../images/dog-jacket.png"
                            alt="cat lying down"
                        />
                    </div>
                    <div className="text">
                        <p>
                            As animal lovers, we understand it can be hard to leave your precious
                            pets, no matter how fun your vacation will be. We are here to take
                            wonderful care of your pets while you are away and we want you to have a
                            great time knowing your pets will as well.
                        </p>
                        <p>
                            We know many people have concerns when it comes to boarding their pets.
                            For this reason, we will be happy to let you tour our facility. Our goal
                            is to set your mind at ease after a visit. We will allow plenty of time
                            for you to ask questions or voice concerns. We will also take this time
                            to discuss our boarding policies so you are comfortable with your
                            decision.
                        </p>
                        <p>
                            Feel free to contact us today with any of your questions, concerns, or
                            to come in for a tour.
                        </p>
                    </div>
                </Blurb>
            </div>
            <Carousel>
                <div className="selection-display">
                    {index >= 1 ? (
                        <StaticImage
                            onClick={() => onLeftArrowPush()}
                            className="arrow"
                            alt="light grey arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../images/svgs/left-dark-arrow.svg"
                        />
                    ) : (
                        <StaticImage
                            onClick={() => onLeftArrowPush()}
                            className="no-cursor arrow"
                            alt="light grey arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../images/svgs/left-light-arrow.svg"
                        />
                    )}
                    <div className="image-container">
                        {index === 0 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us1.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us1.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 1 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us2.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us2.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 2 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us3.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us3.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 3 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us4.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us4.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 4 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us5.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us5.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 5 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us6.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us6.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 6 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us7.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us7.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 7 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us8.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us8.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 8 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us9.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us9.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 9 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    className="underlayer"
                                    quality={100}
                                    src="../images/about-us/about-us10.jpeg"
                                    alt="front of veterinary building"
                                />
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/about-us/about-us10.jpeg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}
                    </div>
                    {index >= 9 ? (
                        <StaticImage
                            onClick={() => onRightArrowPush()}
                            alt="dark grey arrow"
                            className="arrow no-cursor"
                            placeholder="blurred"
                            quality={100}
                            src="../images/svgs/right-arrow-light.svg"
                        />
                    ) : (
                        <StaticImage
                            onClick={() => onRightArrowPush()}
                            alt="dark grey arrow"
                            className="arrow"
                            placeholder="blurred"
                            quality={100}
                            src="../images/svgs/right-dark-arrow.svg"
                        />
                    )}
                </div>
                <div className="pictures-container">
                    <div className="scroll">
                        <StaticImage
                            onClick={() => onSelect(0)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us1.jpeg"
                            alt="front of veterinary building"
                        />
                        <StaticImage
                            onClick={() => onSelect(1)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us2.jpeg"
                            alt="inside of building"
                        />
                        <StaticImage
                            onClick={() => onSelect(2)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us3.jpeg"
                            alt="around corner from main room"
                        />
                        <StaticImage
                            onClick={() => onSelect(3)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us4.jpeg"
                            alt="pink and blue animal patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(4)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us5.jpeg"
                            alt="medical room"
                        />
                        <StaticImage
                            onClick={() => onSelect(5)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us6.jpeg"
                            alt="patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(6)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us7.jpeg"
                            alt="cages"
                        />
                        <StaticImage
                            onClick={() => onSelect(7)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us8.jpeg"
                            alt="medical research room"
                        />
                        <StaticImage
                            onClick={() => onSelect(8)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us9.jpeg"
                            alt="medical bed"
                        />
                        <StaticImage
                            onClick={() => onSelect(9)}
                            placeholder="none"
                            quality={100}
                            src="../images/about-us/about-us10.jpeg"
                            alt="medical room"
                        />
                    </div>
                </div>
            </Carousel>
        </DefaultLayout>
    );
};

export default AboutUs;
