import React from 'react';
import { device, colors } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledPictureGrid = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2em 0.5em;
    .image-container {
        display: grid;
        grid-gap: 10px;
        width: 95vw;
        margin: 0 auto;
        grid-template-columns: repeat(8, 1fr);
    }

    p {
        margin: 0em 0.5em 2.5em;
    }
    .image {
        border-radius: 14px;
    }
    .image-1 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 8;
        width: 156px;
        height: 199px;
    }
    .image-2 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
        width: 70px;
        height: 87px;
    }
    .image-3 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .image-4 {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 7;
    }
    .image-5 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 7;
    }
    .image-6 {
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 7;
    }
    .image-7 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 7;
        grid-row-end: 9;
    }
    .image-8 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 7;
        grid-row-end: 8;
        height: 121px;
    }
    .image-9 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 8;
        grid-row-end: 9;
    }
    .image-10 {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 7;
        grid-row-end: 9;
    }
    h3 {
        margin-bottom: 1em;
        color: ${colors.tealDark};
        text-align: center;
    }
    span {
        color: ${colors.orange};
    }
    @media ${device.tablet} {
        padding: 4em 0em 8em;
        .panel-flex {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        .text {
            padding: 0em 4em;
            align-self: center;
            margin-bottom: 7em;
        }
        .image-container {
            display: grid;
            grid-gap: 10px;
            width: 45vw;
            height: 600px;
            margin: 0 auto;
            grid-template-columns: repeat(8, 1fr);
        }
        .image-1 {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
            width: 282px;
            height: 300px;
        }
        .image-2 {
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 3;
            width: 147px;
            height: 147px;
        }
        .image-3 {
            width: 147px;
            height: 147px;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        .image-4 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 5;
            width: 140px;
        }
        .image-5 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 7;
            width: 142px;
            min-height: 181px;
        }
        .image-7,
        .image-8,
        .image-9,
        .image-10 {
            max-height: 101px;
        }
        .image-7 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 7;
            grid-row-end: 9;
        }
        .image-8 {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 7;
            grid-row-end: 9;

            height: 121px;
        }
        .image-9 {
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 7;
            grid-row-end: 9;
        }
        .image-10 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 5;
            grid-row-end: 9;
            min-height: 220px;
        }
    }
    /* @media ${device.laptopL} {
        max-width: 1100px;
        margin: 0 auto;
    } */
`;

export const PictureGrid: React.FC = ({ children }) => {
    return <StyledPictureGrid className="container">{children}</StyledPictureGrid>;
};
