import React from 'react';
import { device, colors } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin-top: 1em;
    }

    .image-container {
        position: relative;
        width: 330px;
        height: 100%;
        border-radius: 10px;
        background: ${colors.tealLight};
    }
    .picture {
        position: relative;
        bottom: 1em;
        right: 1em;
        width: 330px;
        background: #374e49;
        border-radius: 10px;
    }
    .text {
        span {
            padding-bottom: 2em;
            color: ${colors.blue};
        }
        padding: 0em 1em;
    }
    h2 {
        margin-top: 1em;
        color: ${colors.orange};
    }
    @media ${device.tablet} {
        flex-direction: row-reverse;
        padding: 2em 0em 4em;
        .image-container {
            position: relative;
            width: 430px;
        }
        .text {
            margin-bottom: 4em;
            padding-right: 10em;
            h2 {
                margin-top: 0em;
            }
        }
        .picture {
            width: 430px;
        }
    }
`;

export const PictureText: React.FC = ({ children }) => {
    return <StyledSection className="container">{children}</StyledSection>;
};
