import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { ReptileMammalForm } from '../../components/forms/ReptileMammalForm';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureMain } from '../../components/PictureMain';
import { Seo } from '../../components/seo';
import { Title } from '../../components/Title';

const ReptileQuestionnaire: Page = () => (
    <DefaultLayout path="new-clients/" prevName="New Clients" name="Reptile Questionnaire">
        <Seo
            title="Reptile Questionnaire Country Club Animal Clinic"
            description="Reptile Questionnaire Country Club Animal Clinic"
        />
        <Title>
            <h1>
                <span className="orange">Reptile Questionnaire </span>At Country Club Animal Clinic
            </h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../../images/reptile.jpeg"
                alt="green hummingbird"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../../images/reptile.jpeg"
                alt="green hummingbird"
            />
        </PictureMain>
        <ReptileMammalForm reptile />
    </DefaultLayout>
);

export default ReptileQuestionnaire;
