import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PetPersonalityForm } from '../../components/forms/PetPersonalityForm';
import { StaticImage } from 'gatsby-plugin-image';
import { PictureMain } from '../../components/PictureMain';
import { Seo } from '../../components/seo';
import { Title } from '../../components/Title';

const ResortPersonalityQuestionnaire: Page = () => (
    <DefaultLayout path="new-clients/" prevName="New Clients" name="Spa & Resort Questionnaire">
        <Seo
            title="CCAC Spa & Resort Pet Personality Questionnaire  Country Club Animal Clinic"
            description="CCAC Spa & Resort Pet Personality Questionnaire Country Club Animal Clinic"
        />
        <Title>
            <h1>
                <span className="orange">CCAC Spa & Resort Pet Personality Profile </span>At Country
                Club Animal Clinic
            </h1>
        </Title>
        <PictureMain>
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                placeholder="none"
                className="underlayer"
                src="../../images/dog-personality.jpg"
                alt="happy dog"
            />
            <StaticImage
                imgStyle={{ borderRadius: '20px' }}
                quality={100}
                placeholder="none"
                src="../../images/dog-personality.jpg"
                alt="happy dog"
            />
        </PictureMain>
        <PetPersonalityForm />
    </DefaultLayout>
);

export default ResortPersonalityQuestionnaire;
